import { ACTIVE, INACTIVE } from 'constants/status';

export const getValueLabelList = (list, keyPair = 'valueLabel') => {
  if (keyPair === 'label') {
    return list.map((it) => {
      return {
        label: it.Label,
        value: it.Label,
      };
    });
  } else if (keyPair === 'value') {
    return list.map((it) => {
      return {
        label: it.Value,
        value: it.Value,
      };
    });
  } else {
    return list.map((it) => {
      return {
        label: it.Label,
        value: it.Value,
      };
    });
  }
};

export const getSelectOptionsList = (list, label, value) => {
  const optionsList =
    list?.length > 0 &&
    list?.map((it) => {
      return {
        label: it[label],
        value: it[value],
      };
    });
  return optionsList;
};

export const getActiveInactiveLabelList = (list) => {
  return list.map((it) => {
    if (it?.Label === 'No') {
      return {
        label: INACTIVE,
        value: it?.Value,
      };
    } else if (it?.Label === 'Yes') {
      return {
        label: ACTIVE,
        value: it?.Value,
      };
    }
  });
};

export const getShortDescriptionValueLabelList = (list) => {
  const optionsList =
    list?.length > 0 &&
    list?.map((it) => {
      return {
        label: it['LookupEntityLabel'],
        value: it['LookupEntityValue'],
        description: it['ShortDescription']
      };
    });
  return optionsList;
};
