import {
  SEARCH_CUSTOMERS_PRODUCTS_LIST_LOADING,
  SEARCH_CUSTOMERS_PRODUCTS_LIST_SUCCESS,
  SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE,
  SET_SELECTED_CUSTOMER_AND_PRODUCT,
} from '../actions/customersAndProducts';

const initialState = {
  loading: false,
  customerProductList: [],
  selectedCustomerAndProduct: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_CUSTOMERS_PRODUCTS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CUSTOMERS_PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerProductList: payload?.Data,
      };
    }

    case SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_SELECTED_CUSTOMER_AND_PRODUCT: {
      return {
        ...state,
        selectedCustomerAndProduct: payload,
      };
    }

    default:
      return state;
  }
}
