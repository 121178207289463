export const getUserDomainSearchBody = (orgId) => {
  return {
    intent: '',
    fields: ['UserDomainRuleType', 'DomainName', 'AppId', 'OrgId', 'OrgName', 'InsertTimestamp'],
    Criterion: [
      {
        field: 'OrgId',
        operator: '=',
        value: orgId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
