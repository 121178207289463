import { CLIENT_LOGO_FILE_SIZE_LIMIT_KB } from 'pages/ClientProfile/CompanyInformation/constants';

export const ERROR_MESSAGE = 'Something Went Wrong.';
export const SAVE_SUCCESSFULLY_MESSAGE = 'Saved Successfully.';
export const DELETE_SUCCESSFULLY_MESSAGE = 'Deleted Successfully';
export const UPDATED_SUCCESSFULLY_MESSAGE = 'Updated Successfully.';
export const UPLOADED_SUCCESSFULLY_MESSAGE = 'Uploaded Successfully.';
export const DOWNLOADED_SUCCESSFULLY_MESSAGE = "Downloaded Successfully.";
export const STATUS_UPDATED_SUCCESSFULLY_MESSAGE = 'Status Updated Successfully.';
export const LAUNCHED_SUCCESSFULLY_MESSAGE = 'Launched Successfully.';
export const QUESTION_SAVED_SUCCESSFULLY_MESSAGE = 'Question Saved Successfully.';
export const QUESTION_UPDATED_SUCCESSFULLY_MESSAGE = 'Question Updated Successfully.';
export const QUESTION_DELETED_SUCCESSFULLY_MESSAGE = 'Question Deleted Successfully.';
export const FILE_UPLOADED_SUCCESSFULLY_MESSAGE = 'File Uploaded Successfully.';
export const QUESTIONS_REORDERED_SUCCESSFULLY_MESSAGE = 'Questions Reordered Successfully.';
export const DELETED_USER_SUCCESSFULLY_MESSAGE = 'User Deleted Successfully.';
export const FILE_UPLOAD_SIZE_ERROR_MESSAGE = `File size should not be greater than ${CLIENT_LOGO_FILE_SIZE_LIMIT_KB}KB`;
export const RESEND_INVITE_SUCCESSFULLY = 'Invite Sent Successfully.';
export const USER_ASSOCIATED_SUCCESSFULLY = 'User Associated Successfully.';
export const USER_UNASSOCIATED_SUCCESSFULLY = 'User Association Removed Successfully.';
export const USER_DELETED_FROM_AUTH0_SUCCESSFULLY = 'User Deleted From Auth0 Successfully.';
export const USER_ASSOCIATION_REMOVE_WARNING_MESSAGE = 'Are you sure you want to remove this association?';
export const BLOCKED_UNBLOCKED_USER_SUCCESSFULLY_MESSAGE = 'User Blocked status changed Successfully.';
export const USER_DOMAIN_RULE_DELETE_WARNING_MESSAGE = 'Are you sure you want to delete this rule?';
export const QUESTION_ASSOCIATED_SUCCESSFULLY_MESSAGE = 'Question Associated Successfully.';
export const UPDATED_AUDIT_REVIEW_SUCCESSFULLY_MESSAGE = 'Audit Review Status Updated Successfully.';
export const AUDITOR_REVIEW_CONFIRMED_SUCCESSFULLY_MESSAGE = 'Confirmed Successfully.';
export const AUDITOR_REVEIW_DECLINED_SUCCESSFULLY_MESSAGE = 'Declined Successfully.';
//UserRole alert messages
export const USER_ROLE_ADDED_SUCCESSFULLY = 'User role assigned successfully';
export const USER_ROLE_REMOVED_SUCCESSFULLY = 'User role removed successfully';
export const USER_ROLE_REMOVE_WARNING_MESSAGE = 'This action will remove permissions related to the selected role. Do you wish to continue?';

export const composeUserDeleteMsg = (firstName, lastName) => {
  return `Are you sure you want to delete the user '${firstName} ${lastName}'? This will delete the user from Auth0 and will also remove all the related associations.`;
};

export const composeUserBlockUnblockMsg = (firstName, lastName, isBlocked) => {
  return `Are you sure you want to ${isBlocked ? 'block' : 'unblock'} the user '${firstName} ${lastName}'? This will ${isBlocked ? 'block' : 'unblock'
    } the user from Auth0.`;
};

export const NO_OF_FILES_TO_UPLOAD_LIMIT_MESSAGE = 'Exceeded the limit for uploading files here. ';
export const REMOVE_SELECTED_FILE_FROM_ASSESSMENT_REQUEST = 'This action will remove the selected file from the assessment request. Are you sure?';
// Assessment Request
export const ASSESSMENT_REQUEST_UPDATED_SUCCESSFULLY = 'Assessment Request Updated Successfully.';
export const ASSESSMENT_REQUEST_CANCELLED_SUCCESSFULLY = 'Assessment Request Cancelled Successfully.';
export const ASSESSMENT_REQUEST_SUBMITTED_SUCCESSFULLY = 'Assessment Request Submitted Successfully.';
//Vendor Profile
export const CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE = 'Added Customer Successfully.';
export const CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE = 'Deleted Customer Successfully.';
export const PRODUCT_ADDED_SUCCESSFULLY_MESSAGE = 'Added Product Successfully.';
export const USER_DOMAIN_ID_CREATED_SUCCESSFULLY = 'User Rule ID created Successfully.';
export const USER_DOMAIN_ID_SAVED_SUCCESSFULLY = 'User Rule ID saved Successfully.';
export const USER_DOMAIN_ID_DELETED_SUCCESSFULLY = 'User Rule ID deleted Successfully.';
export const PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE = 'Removed Product Successfully.';
export const CONFIRM_FORCE_DELETE_MESSAGE = 'Are you sure you want to delete all associated data? Please confirm.';
export const CUSTOMER_ALREADY_EXISTS_MESSAGE = 'This customer has been already added.';
export const PRODUCT_COMPONENT_ADDED_SUCCESSFULLY = 'Component Added Successfully.';
export const PRODUCT_COMPONENT_UPDATED_SUCCESSFULLY = 'Component Updated Successfully.';
export const PRODUCT_COMPONENT_DELETED_SUCCESSFULLY = 'Component Deleted Successfully.';
export const CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY = 'CORLCleared Assessment Added Successfully.';
export const CORL_CLEARED_ASSESSMENT_REQUEST_ADDED_SUCCESSFULLY = 'CORLCleared Assessment Request Added Successfully.';

export const REMOVE_SELECTED_FILE_FROM_EVIDENCE_REQUEST = 'This action will remove the selected file from the evidence request. Are you sure?';

// This is for Evidence Management
export const DOWNLOAD_PERIOD_EXPIRED_MESSAGE = "Download Period Expired."

export const ASSOCIATION_SUCCESSFUL_MESSAGE = 'Association Successful.';
export const UNSAVED_CHANGES_POPUP_MESSAGE = "This will close the page without saving the changes. Are you sure?"

export const INADEQUACY_REASONS_ADDED_SUCCESSFULLY = "Inadequacy Reason Added Successfully."
export const INADEQUACY_REASONS_UPDATED_SUCCESSFULLY = "Inadequacy Reason Updated Successfully."

export const EVIDENCE_REVIEW_UPDATED_SUCCESSFULLY = "Evidence Review Updated Successfully."

export const IPI_ADDED_SUCCESSFULLY = "IPI List Updated Successfully";
export const IPI_UPDATED_SUCCESSFULLY = "IPI UPDATED Successfully";
export const IPIS_REORDERED_SUCCESSFULLY_MESSAGE = `IPI's Reordered Successfully.`;

export const NOTES_ADDED_SUCCESSFULLY = "Note Added Successfully";
export const QUESTIONNAIRE_ASSOCIATED_SUCCESSFULLY = "Questionnaire Associated Successfully";

// This is for email related

export const TEST_EMAIL_GENERATE_MESSAGE = 'Test Email Sent Successfully';
export const EMAIL_TEMPLATE_ADDED_SUCCESSFULLY = 'Email Template Added Successfully';
export const EMAIL_TEMPLATE_UPDATED = 'Email Template Updated';