import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import { logOutStyles, logoutIconStyle } from './logoutbuttonStyle';
import { Button } from '@mui/material';
import CustomErrorHandlerModal from '../CustomErrorHandlerModal/CustomErrorHandlerModal';
import { LOGOUT_MESSAGE } from 'constants/constants';

const LogoutButton = ({ handleUserNameClose }) => {
  const { logout } = useAuth0();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const customOptionSelected = () => {
    onClickLogout();
  };

  const logoutButtonClicked = () => {
    setOpenConfirmation(true);
  };

  const closePopup = () => {
    handleUserNameClose();
    setOpenConfirmation(false);
  };

  const onClickLogout = async () => {
    // Call on Logout click
    let isDone = await preLogout();
    if (isDone) isDone = await onLogout();
    if (isDone) postLogout();
    else return;
  };

  const preLogout = () => {
    /**
     * Logic before auth0 logout will come here
     */
    return true;
  };
  const onLogout = () => {
    logout({
      returnTo: window.location.origin + '/logout',
    });
    return true;
  };
  const postLogout = () => {
    /**
     * Logic for post auth0 logout will come here
     */
    return true;
  };
  return (
    <div>
      <Button className="btn" disableRipple disableFocusRipple disableElevation onClick={logoutButtonClicked} sx={logOutStyles}>
        <FontAwesomeIcon style={logoutIconStyle()} icon={faPowerOff} />
        Log Out
      </Button>
      <CustomErrorHandlerModal question={LOGOUT_MESSAGE} optionSelected={customOptionSelected} open={openConfirmation} close={closePopup} />
    </div>
  );
};

export default LogoutButton;
