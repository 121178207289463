import { TRUE } from 'constants/constants';
import { isNil } from 'lodash';
import { ASSESSMENT_TYPE_CORL_CLEARED, PRODUCT_CATALOG_WIZARD_QUESTION_TYPE, PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE } from 'pages/VendorProfile/Products/constants';

export const getProductGroupListBody = (vendorOrgId = null) => {
  const criterion = [];
  if (!isNil(vendorOrgId)) {
    criterion.push({
      field: 'VendorOrgId',
      operator: '=',
      value: vendorOrgId,
    });
  }
  return {
    intent: '',
    fields: ['ProductGroupName', 'VendorOrgId', 'Active'],
    criterion: criterion,
    sort: [
      {
        field: 'ProductGroupName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getProductListBody = (vendorOrgId = null) => {
  const criterion = [];
  if (!isNil(vendorOrgId)) {
    criterion.push({
      field: 'VendorOrgId',
      operator: '=',
      value: vendorOrgId,
    });
  }
  return {
    intent: '',
    fields: ['ProductName', 'ProductType', 'ProductVendorRole', 'ProductId', 'ProductGroup', 'ProductNameForVendor', 'VendorOrgId', "IsClearedProcessStarted", "ExternalTicketId", "CCDynVRASId", "CORLClearedStatus", "ProductCategory"],
    criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getSelectedProductBody = (vendorOrgId = null, productId = null) => {
  const criterion = [];
  if (!isNil(vendorOrgId) && !isNil(productId)) {
    criterion.push(
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
      {
        field: 'ProductId',
        operator: '=',
        value: productId,
      }
    );
  }
  return {
    intent: '',
    fields: ['ProductName', 'ProductType', 'ProductVendorRole', 'ProductId', 'ProductGroup', 'ProductNameForVendor', 'CCDynVRASId', 'CORLClearedStatus', 'ProductVendorAssessment', 'IsClearedProcessStarted', 'VendorOrgId', 'ProductCategory'],
    criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getProductComponentBody = (productVendorId = null) => {
  const criterion = [];
  if (!isNil(productVendorId)) {
    criterion.push({
      field: 'ProductVendorId',
      operator: '=',
      value: productVendorId,
    });
  }
  return {
    Intent: '',
    Fields: [
      'ProductVendorId',
      'ProductComponentName',
      'ProductComponentType',
      'ProductComponentDataLocation',
      'ProductMedicalDevice',
      'DataLeavesClientSystem',
      'ProductName',
      'VendorOrgName',
      'PIIDataAccess',
      'PHIDataAccess',
      'PCIDataAccess',
      'EmployeeProprietaryDataAccess',
      'CreatedByWizard'
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getCorlClearedAssessmentRequestBody = (data) => {
  return {
    VendorOrgId: data?.VendorOrgId,
    VendorOrgName: data?.VendorOrgName,
    VendorUrl: data?.VendorUrl,
    VendorContactFirstName: data?.VendorContactFirstName,
    VendorContactLastName: data?.VendorContactLastName,
    VendorContactEmail: data?.VendorContactEmail,
    ProductVendorId: data?.ProductVendorId,
    ProductName: data?.ProductName,
    IsVendorCleared: true,
  };
};

export const getCorlClearedAssessmentBody = (data) => {
  return {
    RecipientOrgId: data?.VendorOrgId,
    ProductVendorId: data?.ProductVendorId,
    VendorOrgName: data?.VendorOrgName,
    ProductName: data?.ProductName,
    AssessmentTypeId: ASSESSMENT_TYPE_CORL_CLEARED,
  };
};

export const getProductComponentRequirementsSearchBody = (componentId) => {
  return {
    Intent: '',
    Fields: [
      'ComponentId',
      'RequirementId',
      'RequirementDescription',
      'ExpectedEvidenceDescription',
      'ProductVendorId',
      'ProductComponentName',
      'ProductComponentTypeId',
      'QuestionnaireTemplateId'
    ],
    Criterion: [
      {
        "Field": "ComponentId",
        "Operator": "=",
        "Value": componentId ?? 0
      }
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getProductComponentRequirementDetailsBody = (componentRequirementId) => {
  return {
    Intent: '',
    Fields: [
      'RequirementName',
      'ComponentRequirementId',
      'ComponentId',
      'RequirementDescription',
      'ExpectedEvidenceDescription',
      "ComponentRequirementEvidenceList"
    ],
    Criterion: [
      {
        "Field": "ComponentRequirementId",
        "Operator": "=",
        "Value": componentRequirementId ?? 0
      }
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getVendorScopeInfoSearchBody = (productVendorId) => {
  return {
    "Intent": "",
    "Fields": [
      "ProductVendorId",
      "ProductComponentName",
      "ProductComponentType",
      "ProductComponentDataLocation",
      "ProductMedicalDevice",
      "DataLeavesClientSystem",
      "PIIDataAccess",
      "PHIDataAccess",
      "PCIDataAccess",
      "EmployeeProprietaryDataAccess",
      "VendorOrgId",
      "VendorOrgName",
      "DomainName",
      "ProductName",
      "ProductDescription",
      "ProductComponentOverview",
      "CCDynVRASId"
    ],
    "Criterion": [
      {
        "Field": "ProductVendorId",
        "Operator": "=",
        "Value": productVendorId
      }
    ],
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getVendorProductRequirementListSearchBody = (assessmentId, assessmentQuestionnaireRequirementId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      field: 'AssessmentId',
      operator: '=',
      value: assessmentId,
    });
  }
  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      field: 'AssessmentQuestionnaireRequirementId',
      operator: '=',
      value: assessmentQuestionnaireRequirementId,
    });
  }
  return {
    "Intent": "",
    "Fields": [
      "AssessmentQuestionnaireId",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "ProductVendorId",
      "SystemAdequacy",
      "AuditorAdequacy",
      "QuestionnaireTemplateRequirementId",
      "IsAuditorReviewRequired",
      "IsAuditorConfirmed",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getVendorProductRequirementDetailsSearchBody = (assessmentQuestionnaireRequirementId) => {

  let criterion = [];

  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      field: 'AssessmentQuestionnaireRequirementId',
      operator: '=',
      value: assessmentQuestionnaireRequirementId,
    });
  }

  return {
    "Intent": "",
    "Fields": [
      "AssessmentQuestionnaireId",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "ProductVendorId",
      "AssessmentQuestionnaireQuestionResponseList",
      "ResponseAttachmentList",
      "InadequacyReasonList",
      "AssessmentQuestionnaireRequirementId",
      "QuestionnaireTemplateRequirementId",
      "RecommendationStatus",
      "AssessmentInsertTimestamp",
      "InadequecyReasonIPIList",
      "InadequecyReasonIPIAuditReviewRequiredList",
      "NotesToVendor",
      "ResultsOfTesting",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getRequirementInadequacyReasonSearchBody = (requirementId, productVendorId) => {
  return {
    "Intent": "",
    "Fields": [
      "Name",
      "Description",
      "RequirementId"
    ],
    "Criterion": [
      {
        "Field": "RequirementId",
        "Operator": "=",
        "Value": requirementId
      }
    ],
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getAssessmentSummarySearchBody = (assessmentId) => {
  return {
    "intent": "",
    "fields": [
      "AssessmentId",
      "ProductComponentId",
      "ImpactLevel",
      "Likelihood",
      "RiskRating",
      "PIIDataAccessId",
      "PHIDataAccessId",
      "PCIDataAccessId",
      "EmployeeProprietaryDataAccessId",
      "RiskRatingSummary",
      "RiskRecommendation",
      "AssessmentQuestionnaireId",
      "OffshoreDataAccessId",
      "OffshoreDataStorageId",
    ],
    "criterion": [
      {
        "Field": "AssessmentId",
        "Operator": "=",
        "Value": assessmentId
      }
    ],
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
}

export const searchVendorProductPreferencesBody = (vendorOrgId, questionTypeId = null) => {
  return {
    intent: '',
    fields: [
      'QuestionText',
      'DisplayOrder',
      'QuestionResponseType',
      'ParentQuestionId',
      'ProductPreferenceQuestionResponseId',
      'QuestionResponseText',
      'ResponseOptionList',
      'ProductPreferenceId',
      'ImageFileName',
      'Description',
      "Status",
      "EncouragementText",
      "LookupEntityId",
      "IsRequired",
    ],
    criterion: [
      {
        Field: 'VendorOrgId',
        Operator: '=',
        Value: vendorOrgId,
      },
      {
        Field: 'ParentQuestionId',
        Operator: '=',
        Value: '0',
      },
      {
        Field: 'QuestionTypeId',
        Operator: '=',
        Value: questionTypeId,
      },
      {
        Field: 'Active',
        Operator: '=',
        Value: TRUE,
      },
    ],
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductPreferencesQuestionImageUrlBody = (vendorOrgId, questionTypeId = PRODUCT_CATALOG_WIZARD_QUESTION_TYPE) => {
  return {
    intent: '',
    fields: [],
    criterion: [
      {
        Field: 'VendorOrgId',
        Operator: '=',
        Value: vendorOrgId,
      },
      {
        Field: 'QuestionTypeId',
        Operator: '=',
        Value: questionTypeId,
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProductBody = (productName = "", vendorOrgId) => {
  return {
    intent: '',
    fields: [
      'ProductName',
      'VendorOrgId',
    ],
    criterion: [
      {
        Field: 'ProductName',
        Operator: '=',
        Value: productName,
      },
      {
        Field: 'VendorOrgId',
        Operator: '=',
        Value: vendorOrgId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const updateAssessmentStatusBody = (assessmentId, statusId, OldAssessmentStatus) => {
  return {
    AssessmentId: assessmentId,
    NewAssessmentStatus: statusId,
    OldAssessmentStatus: OldAssessmentStatus
  };
};