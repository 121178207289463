import { isNil, isUndefined } from 'lodash';

export const getPercentage = (num, den) => {
  if (!isNil(num) && !isNil(den) && num !== 0 && den !== 0) {
    const numerator = Number(num);
    const denominator = Number(den);
    return (numerator / denominator) * 100;
  }
  return 0;
};
