import { isEmpty, isNil, isUndefined } from 'lodash';
import { convertToLocalDateTime } from './dateAndTimeUtils';
import { format, parse, intlFormatDistance, formatDistance } from 'date-fns';
import { getPercentage } from './numberUtils';
import { DATE_FORMAT } from 'constants/constants';

export const getRiskRatingOverviewBody = (id) => {
  return {
    intent: '',
    fields: [
      "AssessmentId",
      "ProductComponentId",
      "ImpactLevel",
      "Likelihood",
      "RiskRating",
      "PIIDataAccessId",
      "PHIDataAccessId",
      "PCIDataAccessId",
      "EmployeeProprietaryDataAccessId",
      "RiskRatingSummary",
      "RiskRecommendation",
      "LastUpdated",
      "AssessmentQuestionnaireId",
      "OffshoreDataAccessId",
      "OffshoreDataStorageId",
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewBody = (id) => {
  return {
    intent: "",
    fields: [
      "ProductVendorId",
      "VendorId",
      "VendorName",
      "DomainName",
      "ProductUrl",
      "ProductName",
      "ProductDescription",
      'DisplayExecutiveSummaryLink',
      "AssessmentCorrelationId"
    ],
    criterion: [
      {
        "Field": "AssessmentId",
        "Operator": "=",
        "Value": id
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceReviewAccordionBody = (assessmentQuestionnaireRequirementId) => {
  
  let criterion = [];

  if(!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireRequirementId',
      Operator: '=',
      Value: assessmentQuestionnaireRequirementId,
    })
  }

  return {
    intent: '',
    fields: [
      "AssessmentQuestionnaireId",
      "AssessmentQuestionnaireRequirementId",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "ProductVendorId",
      "AssessmentQuestionnaireQuestionResponseList",
      "ResponseAttachmentList",
      "InadequacyReasonList",
      "ResultsOfTesting",
      "NotesToVendor",
      "DefaultTier"
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceReviewBody = (id) => {
  return {
    intent: '',
    fields: [
      "AssessmentQuestionnaireId",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "ProductVendorId",
      "DefaultTier",
      "SeverityId",
      "Severity",
      "TierName",
      "AuditorAdequacy",
      "AssessmentQuestionnaireQuestionResponseList",
      "AssessmentQuestionnaireRequirementId",
      "ResponseAttachmentList",
      "InadequacyReasonList",
      "ResultsOfTesting",
      "NotesToVendor",
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingControlSummaryBody = (id) => {
  return {
    intent: '',
    fields: [
      "AssessmentId",
      'Tier1Data',
      'Tier2Data',
      'Tier3Data',
      'Tier4Data'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskFindingSummaryBody = (id, clientOrgId) => {
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "SeverityId",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "TierName",
      "RemediationDueDate",
      "CountOfResolvedRiskFindings"
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },{
        Field: 'ClientOrgId',
        Operator: '=',
        Value: clientOrgId,
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentByIdBody = (id) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'LastUpdated',
      'AssessmentStatus',
      'AssessmentStage',
      'AssessmentTypeId',
      'MasterStepName',
      'AssessmentStartDate',
      'DynVendorAccountId',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'PortalId',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getModifiedList = (actualList, completedAssessmentsCount = 0) => {
  let list = actualList;
  if (!isEmpty(actualList) && actualList?.length > 0) {
    list = actualList?.map((it, index) => {
      let envList = [];
      if (!isNil(it?.EnvironmentList) && !isUndefined(it?.EnvironmentList)) {
        envList = it?.EnvironmentList?.map((env) => {
          const envName = env?.EnvironmentName?.replace(env?.VrasEnvironmentName, '');
          return {
            ...env,
            VrasEnvironmentName: isNil(env?.VrasEnvironmentName) ? env?.EnvironmentName?.split('-')[0] : env?.VrasEnvironmentName,
            EnvironmentName: envName?.split('-').length > 1 ? `${envName?.split('-')[1]}` : env?.EnvironmentName,
          };
        });
      }
      return {
        ...it,
        DisplayVendorPercentage: String(it?.DisplayVendorPercentage) === '1',
        DisplayResultAndEvidence: String(it?.DisplayResultAndEvidenceLinks) === '1',
        SeqNo:
          !isNil(completedAssessmentsCount) && Number(completedAssessmentsCount) > 0
            ? `${index + 1}/${completedAssessmentsCount}`
            : `${index + 1}/${actualList?.length}`,
        VendorProgressPercent:
          String(it?.DisplayVendorPercentage) === '1' && !isNil(it?.PortalId) ? getPercentage(it?.CountOfResponses, it?.CountOfQuestions) : 0,
        LastActionDate: convertToLocalDateTime(it?.LastUpdated, DATE_FORMAT),
        LastActionTime: convertToLocalDateTime(it?.LastUpdated, 'h:mm a'),
        LastStatusActivity:
          !isEmpty(it) && !isEmpty(it?.LastUpdated)
            ? intlFormatDistance(new Date(it?.LastUpdated), new Date())?.includes('quarter')
              ? formatDistance(new Date(it?.LastUpdated), new Date(), { addSuffix: true })
              : intlFormatDistance(new Date(it?.LastUpdated), new Date())
            : null,
        UpdateTimeStamp: format(parse(it?.LastUpdated, 'MM/dd/yyyy HH:mm:ss', new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
        EnvironmentList: envList,
      };
    });
  }
  return list;
};