import {
  GET_INCIDENT_RECORD_TYPE_NAME_LIST_LOADING,
  GET_INCIDENT_RECORD_TYPE_NAME_LIST_SUCCESS,
  GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE,
  POST_INCIDENT_RECORD_LOADING,
  POST_INCIDENT_RECORD_SUCCESS,
  POST_INCIDENT_RECORD_FAILURE,
  GET_INCIDENT_RECORD_LOADING,
  GET_INCIDENT_RECORD_SUCCESS,
  GET_INCIDENT_RECORD_FAILURE,
  POST_INCIDENT_RECORD_STATUS_LOADING,
  POST_INCIDENT_RECORD_STATUS_SUCCESS,
  POST_INCIDENT_RECORD_STATUS_FAILURE,
  GET_INCIDENT_STATUS_TYPE_NAME_LIST_LOADING,
  GET_INCIDENT_STATUS_TYPE_NAME_LIST_SUCCESS,
  GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE,
  GET_INCIDENT_SEVERITY_LIST_LOADING,
  GET_INCIDENT_SEVERITY_LIST_SUCCESS,
  GET_INCIDENT_SEVERITY_LIST_FAILURE,
  SET_INCIDENT_RECORD,
} from '../actions/incidentBuilder';
import { format, parseISO } from 'date-fns';
import { convertToLocalDate } from 'utils/dateAndTimeUtils';

const initialState = {
  loading: false,
  incidentTypeList: [],
  incidentStatusList: [],
  incidentSeverityList: [],
  incidentRecord: {},
  incidentCommentLoading: null,
  incidentComment: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INCIDENT_RECORD_LOADING:
    case GET_INCIDENT_STATUS_TYPE_NAME_LIST_LOADING:
    case GET_INCIDENT_RECORD_TYPE_NAME_LIST_LOADING:
    case GET_INCIDENT_SEVERITY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_INCIDENT_RECORD_TYPE_NAME_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentTypeList: payload?.Data,
      };
    }

    case GET_INCIDENT_STATUS_TYPE_NAME_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentStatusList: payload?.Data,
      };
    }

    case GET_INCIDENT_SEVERITY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentSeverityList: payload?.Data,
      };
    }

    case GET_INCIDENT_RECORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentRecord: {
          ...payload?.Data,
          DateFirstReported: convertToLocalDate(payload?.Data?.DateFirstReported, 'yyyy-MM-dd'),
        },
      };
    }

    case GET_INCIDENT_RECORD_FAILURE: {
      return {
        ...state,
        loading: false,
        incidentRecord: payload,
      };
    }

    case GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incidentTypeList: payload,
      };
    }

    case GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incidentStatusList: payload,
      };
    }

    case GET_INCIDENT_SEVERITY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incidentSeverityList: payload,
      };
    }

    case POST_INCIDENT_RECORD_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_INCIDENT_RECORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentRecord: {
          ...payload?.Data,
          DateFirstReported: format(parseISO(payload?.Data?.DateFirstReported), 'yyyy-MM-dd'),
        },
      };
    }

    case POST_INCIDENT_RECORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_INCIDENT_RECORD_STATUS_LOADING: {
      return {
        ...state,
        incidentCommentLoading: true,
      };
    }

    case POST_INCIDENT_RECORD_STATUS_SUCCESS: {
      return {
        ...state,
        incidentCommentLoading: false,
      };
    }

    case POST_INCIDENT_RECORD_STATUS_FAILURE: {
      return {
        ...state,
        incidentCommentLoading: false,
      };
    }

    case SET_INCIDENT_RECORD: {
      return {
        ...state,
        incidentRecord: payload,
      };
    }

    default:
      return state;
  }
}
