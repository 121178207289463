import { EMAIL_TEMPLATE_TYPE_ENTITY_ID } from "pages/ClientEmailTemplates/constants";

export const getEmailTemplatesSearchBody = (orgId = null, templateTypeId = null) => {
  const intent = '';
  const fields = [
    'EmailConfigId',
    'TemplateName',
    'OrgId',
    'OrgName',
    'Key',
    'ConfigValue',
    'UserName',
    'LastUpdated',
    'DefaultTemplate',
    'IsTemplateCustomizable',
  ];

  const criterion = [
    {
      field: 'OrgId',
      operator: '=',
      value: orgId,
    },
    {
      field: 'TemplateTypeId',
      operator: '=',
      value: templateTypeId,
    },
  ];

  return {
    intent,
    fields,
    criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getEmailTemplateTypSearchBody = () => {
  const fields = [
    'LookupEntityId',
    'LookupEntityName',
    'LookupEntityValue',
    'LookupEntitySystemLabel',
    'LookupEntityLabel',
    'ShortDescription',
    'DisplayOrder',
  ];

  const criterion = [
    {
      field: 'LookupEntityId',
      operator: '=',
      value: EMAIL_TEMPLATE_TYPE_ENTITY_ID,
    }
  ]

  return {
    intent: '',
    fields,
    criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
