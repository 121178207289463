import {
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_ASSESSMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_LIST_FAILURE,
  SEARCH_COMPANION_ASSESSMENT_LIST_LOADING,
  SEARCH_COMPANION_ASSESSMENT_LIST_SUCCESS,
  SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE,
  SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING,
  SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
  SET_VENDOR_LIST_PAGE,
  UPDATE_VENDOR_LIST,
  SEARCH_CLIENT_LIST_LOADING,
  SEARCH_CLIENT_LIST_SUCCESS,
  SEARCH_CLIENT_LIST_FAILURE,
  SET_CLIENT_LIST_PAGE,
  UPDATE_CLIENT_LIST,
  SET_PRODUCT_LIST_PAGE,
  UPDATE_PRODUCT_LIST,
  SEARCH_ASSESSMENT_CONFIG_LOADING,
  SEARCH_ASSESSMENT_CONFIG_SUCCESS,
  SEARCH_ASSESSMENT_CONFIG_FAILURE,
  SEARCH_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
  SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE,
  SET_ASSESSMENT_PAGE_NO,
  SET_ASSESSMENT_ROW_PER_PAGE,
  SET_ASSESSMENT_FILTERS,
  SET_SELECTED_ASSESSMENT_DETAILS_CARD,
  SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
  RESET_QUESTIONAIRE_SUMMARY,
  GET_QUESTIONNAIRE_INFO_FAILURE,
  GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE,
  GET_ASSESSMENT_NOTES_FAILURE,
  GET_ASSESSMENT_NOTES_LOADING,
  GET_QUESTIONNAIRE_INFO_LOADING,
  GET_COMPANION_QUESTIONNAIRE_INFO_LOADING,
  GET_ASSESSMENT_NOTES_SUCCESS,
  GET_QUESTIONNAIRE_INFO_SUCCESS,
  GET_COMPANION_QUESTIONNAIRE_INFO_SUCCESS,
  SEARCH_SELECTED_ASSESSMENT_LOADING,
  SEARCH_SELECTED_ASSESSMENT_FAILURE,
  SEARCH_SELECTED_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_STATUS_HISTORY_LOADING,
  GET_ASSESSMENT_STATUS_HISTORY_SUCCESS,
  GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
  VENDOR_PROVIDED_SCOPE_INFO_LOADING,
  VENDOR_PROVIDED_SCOPE_INFO_SUCCESS,
  VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS,
  ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
  POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING,
  POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS,
  POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING,
  RESET_EVIDENCE_REVIEW_FLAGS,
  REMEDIATION_GUIDANCE_SEARCH_FAILURE,
  REMEDIATION_GUIDANCE_SEARCH_SUCCESS,
  REMEDIATION_GUIDANCE_SEARCH_LOADING,
  GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_LOADING,
  GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_SUCCESS,
  GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE,
  SET_ASSESSMENT_EDITED_OPTION_SUCCESS,
  SET_ASSESSMENT_EDITED_OPTION_LOADING,
  SET_ASSESSMENT_EDITED_OPTION_FAILURE,
  SEARCH_SELECTED_PORTAL_ASSESSMENT_LOADING,
  SEARCH_SELECTED_PORTAL_ASSESSMENT_SUCCESS,
  SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE,
  POST_ADDITIONAL_NOTE_LOADING,
  POST_ADDITIONAL_NOTE_SUCCESS,
  POST_ADDITIONAL_NOTE_FAILURE,
  ADDITIONAL_NOTES_SEARCH_LOADING,
  ADDITIONAL_NOTES_SEARCH_SUCCESS,
  ADDITIONAL_NOTES_SEARCH_FAILURE,
  RESET_ADDITIONAL_NOTES,
  POST_CONFIRM_AUDITOR_MILESTONE_LOADING,
  POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS,
  POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
  GET_QUESTIONNAIRE_LIST_DATA_LOADING,
  GET_QUESTIONNAIRE_LIST_DATA_SUCCESS,
  GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
  ADD_QUESTIONNAIRE_TO_ASSESSMENT_LOADING,
  ADD_QUESTIONNAIRE_TO_ASSESSMENT_SUCCESS,
  ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
  SEARCH_QUESTIONNAIRE_PROGRESS_LOADING,
  SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS,
  SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
  SET_SELECTED_QUESTIONNAIRE
} from 'actions/assessments';
import { AUDITOR_REVEIW_DECLINED_SUCCESSFULLY_MESSAGE, AUDITOR_REVIEW_CONFIRMED_SUCCESSFULLY_MESSAGE, ERROR_MESSAGE, NOTES_ADDED_SUCCESSFULLY, QUESTIONNAIRE_ASSOCIATED_SUCCESSFULLY, SAVE_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { DATE_TIME_FORMAT, DEFAULT_ROWS_PER_PAGE, YES_VALUE } from 'constants/constants';
import { formatDistance, intlFormatDistance } from 'date-fns';
import { isEmpty, uniqBy } from 'lodash';
import { ADEQUATE_VALUE, INADEQUATE_VALUE } from 'pages/Assessment/AssessmentDetails/EvidenceReview/constant';
import { AUDITOR_DECLINE } from 'pages/VendorProfile/Products/ProductDetails/EvidenceReview/constant';
import { toast } from 'react-toastify';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { convertToLocalDateTime, convertUTCDateToLocalDate } from 'utils/dateAndTimeUtils';

const getEvidenceDisplayName = (evidenceList) => {
  let evidenceNameList = [];
  evidenceList?.map((item, index) => {
    evidenceNameList.push(item?.FileName);
  })

  return evidenceNameList.join(" | ");
}

const initialState = {
  loading: false,
  listLoading: false,
  assessmentList: [],
  vendorList: [],
  searchValueVendorList: [],
  vendorListPage: 1,
  clientList: [],
  searchValueClientList: [],
  clientListPage: 1,
  productList: [],
  searchValueProductList: [],
  productListPage: 1,
  assessmentConfigurationStatuses: [],
  assessmentListCount: 0,
  assessmentPageNo: 0,
  assessmentRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  assessmentRequestsFilters: {},
  selectedAssessment: {},
  openAssessmentDetailsSidesheet: false,
  scopingQuestionnaireInfo: [],
  controlQuestionnaireInfo: [],
  allQuestionnaires: [],
  assessmentNotes: [],
  selectedAssessmentDetails: {},
  statusHistory: [],
  vendorProvidedScopeInfo: {},
  assessmentRequirementList: [],
  assessmentRequirementDetailsList: [],
  alternateGuidance: [],
  remediationGuidanceList: [],
  highRiskCount: 0,
  clientAcceptedCount: 0,
  totalPendingResponsesCount: 0,
  totalRemediationCount: 0,
  totalResolved: 0,
  AssessmentEditDropdownOptionsList: [],
  pendingWithClientCount: 0,
  portalAssessmentDetails: {},
  revertNotes: false,
  portalNotes: [],
  allQuestionnairesInformation: [],
  questionnaireLookupList: [],
  assessmentsListLoading: false,
  assessmentRequirementListLoading: false,
  assessmentQuestionnaireProgress: [],
  questionnaireProgress: [],
  selectedQuestionnaire: {},
  requirementList: [],
};

const getModifiedList = (actualList, completedAssessmentsCount = 0) => {
  let list = actualList;
  if (!isEmpty(actualList) && actualList?.length > 0) {
    list = actualList?.map((it, index) => {
      return {
        ...it,
        Id: index,
        LastStatusActivity:
          !isEmpty(it) && !isEmpty(it?.LastUpdated)
            ? intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date())?.includes('quarter')
              ? formatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date(), { addSuffix: true })
              : intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date())
            : null,
      };
    });
  }
  return list;
};

export default function assessments(state = initialState, action) {
  const { type, payload, searchVendorValue, searchClientValue, searchProductValue, RequirementId, EvidenceId } = action;

  switch (type) {
    case SEARCH_VENDOR_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_VENDOR_LIST_SUCCESS: {
      let searchValueVendorList = [];
      let currentVendorList = [];
      if (searchVendorValue) {
        searchValueVendorList = getSelectOptionsList(payload?.Data?.Rows, 'VendorOrgName', 'VendorOrgName');
      } else {
        currentVendorList = getSelectOptionsList(payload?.Data?.Rows, 'VendorOrgName', 'VendorOrgName');
      }

      return {
        ...state,
        listLoading: false,
        vendorList: uniqBy([...state.vendorList, ...currentVendorList], 'value'),
        searchValueVendorList: uniqBy([...state.searchValueVendorList, ...searchValueVendorList], 'value'),
      };
    }

    case SEARCH_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SEARCH_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        assessmentsListLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessmentsListLoading: false,
        assessmentList: getModifiedList(payload?.Data?.Rows),
      };
    }

    case SEARCH_COMPANION_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        companionAssessmentsListLoading: true,
      };
    }

    case SEARCH_COMPANION_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        companionAssessmentsListLoading: false,
        companionAssessmentList: getModifiedList(payload?.Data?.Rows),
      };
    }
    case SEARCH_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        assessmentsListLoading: false,
        assessmentList: payload,
      };
    }
    case SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        companionAssessmentsListLoading: false,
        companionAssessmentList: payload,
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS: {
      let searchValueProductList = [];
      let currentProductList = [];
      if (searchProductValue) {
        searchValueProductList = getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductName');
      } else {
        currentProductList = getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductName');
      }
      return {
        ...state,
        listLoading: false,
        productList: uniqBy([...state.productList, ...currentProductList], 'value'),
        searchValueProductList: uniqBy([...state.searchValueProductList, ...searchValueProductList], 'value'),
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_VENDOR_LIST_PAGE: {
      return {
        ...state,
        vendorListPage: payload,
      };
    }

    case UPDATE_VENDOR_LIST: {
      return {
        ...state,
        searchValueVendorList: uniqBy(payload, 'value'),
      };
    }

    case SEARCH_CLIENT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_CLIENT_LIST_SUCCESS: {
      let searchValueClientList = [];
      let currentClientList = [];
      if (searchClientValue) {
        searchValueClientList = getSelectOptionsList(payload?.Data?.Rows, 'ClientOrgName', 'OrgId');
      } else {
        currentClientList = getSelectOptionsList(payload?.Data?.Rows, 'ClientOrgName', 'OrgId');
      }

      return {
        ...state,
        listLoading: false,
        clientList: uniqBy([...state.clientList, ...currentClientList], 'value'),
        searchValueClientList: uniqBy([...state.searchValueClientList, ...searchValueClientList], 'value'),
      };
    }

    case SEARCH_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_CLIENT_LIST_PAGE: {
      return {
        ...state,
        clientListPage: payload,
      };
    }

    case UPDATE_CLIENT_LIST: {
      return {
        ...state,
        searchValueClientList: uniqBy(payload, 'value'),
      };
    }

    case SET_PRODUCT_LIST_PAGE: {
      return {
        ...state,
        productListPage: payload,
      };
    }

    case UPDATE_PRODUCT_LIST: {
      return {
        ...state,
        searchValueProductList: uniqBy(payload, 'value'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: getSelectOptionsList(payload?.Data?.Rows, 'AssessmentDisplayStatus', 'AssessmentDisplayStatus'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: [],
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentListCount: payload,
      };
    }

    case SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        companionAssessmentListCount: payload?.Data?.Rows?.[0].CountOfAssessment,
      };
    }

    case SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        companionAssessmentListCount: payload,
      };
    }

    case SET_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        assessmentPageNo: payload,
      };
    }

    case SET_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        assessmentRowsPerPage: payload,
      };
    }

    case SET_ASSESSMENT_FILTERS: {
      return {
        ...state,
        assessmentRequestsFilters: payload,
      };
    }
    case SET_SELECTED_ASSESSMENT_DETAILS_CARD: {
      return {
        ...state,
        selectedAssessment: payload,
      };
    }
    case SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET: {
      return {
        ...state,
        openAssessmentDetailsSidesheet: payload,
      };
    }
    case RESET_QUESTIONAIRE_SUMMARY: {
      return {
        ...state,
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        allQuestionnaires: [],
        allQuestionnairesInformation: []
      };
    }
    case GET_ASSESSMENT_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentNotes: [],
      };
    }
    case GET_QUESTIONNAIRE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        allQuestionnaires: [],
        allQuestionnairesInformation: []
      };
    }
    case GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        allQuestionnaires: [],
        allQuestionnairesInformation: []
      };
    }

    case GET_ASSESSMENT_NOTES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUESTIONNAIRE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COMPANION_QUESTIONNAIRE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSESSMENT_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentNotes: payload.map((item) => {
          return {
            ...item,
            CreatedOnConverted: convertToLocalDateTime(item?.CreatedOn, DATE_TIME_FORMAT),
          };
        }),
      };
    }
    case GET_QUESTIONNAIRE_INFO_SUCCESS: {
      let scoping = [],
        control = [],
        allQuestionnaires = [];
      payload.map((item) => {
        if (item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList.length > 0) {
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.forEach((element, index) => {
            if (element?.QuestionnaireTemplateType === 'scoping') {
              scoping.push(element);
            } else if (element?.QuestionnaireTemplateType === 'control') {
              control.push(element);
            }
            allQuestionnaires.push({ ...element, id: index + 1 });
          });
        }
      });
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: scoping,
        controlQuestionnaireInfo: control,
        allQuestionnaires: allQuestionnaires,
        allQuestionnairesInformation: payload,
      };
    }

    case GET_COMPANION_QUESTIONNAIRE_INFO_SUCCESS: {
      let scoping = [],
        control = [],
        allQuestionnaires = [];
      payload.map((item) => {
        if (item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList.length > 0) {
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.forEach((element,index) => {
            if (element?.QuestionnaireTemplateType === 'scoping') {
              scoping.push(element);
            } else if (element?.QuestionnaireTemplateType === 'control') {
              control.push(element);
            }
            allQuestionnaires.push({...element, id: index+1});
          });
        }
      });
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: scoping,
        controlQuestionnaireInfo: control,
        companionAssessmentStatus: payload[0].AssessmentStatusName,
        companionVendorName: payload[0].VendorName,
        companionAssessmentName: payload[0].AssessmentName,
        allQuestionnaires: allQuestionnaires,
        allQuestionnairesInformation: payload,
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_SUCCESS: {
      let data = {};
      if (payload?.Data?.Rows?.length > 0) {
        const tempData = payload?.Data?.Rows[0];
        data = {
          ...tempData,
          ProductComponentTypeName: tempData?.ProductComponentTypeName?.replace(/,/g, " | "),
          ProductComponentDataLocationName: tempData?.ProductComponentDataLocationName?.split(",")
        }
      }
      return {
        ...state,
        loading: false,
        vendorProvidedScopeInfo: data
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorProvidedScopeInfo: {}
      };
    }

    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING: {
      return {
        ...state,
        loading: true,
        assessmentRequirementListLoading: true,
      };
    }

    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentRequirementListLoading: false,
        assessmentRequirementList: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            DisplayControlName: item?.RequirementNumber + " " + item?.RequirementName,
            IsAdequate: item?.AuditorAdequacyId === ADEQUATE_VALUE ? true : item?.AuditorAdequacyId === INADEQUATE_VALUE ? false : null,
            AdequacyId: item?.AuditorAdequacyId,
            AdequacyName: item?.AuditorAdequacyName,
          }
        }),
      };
    }

    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentRequirementListLoading: false,
        assessmentRequirementList: []
      };
    }
    case SEARCH_SELECTED_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SELECTED_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedAssessmentDetails: {},
      };
    }
    case SEARCH_SELECTED_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedAssessmentDetails: payload.map((it, index) => {
          return {
            ...it,
            id: index + 1,
            LastStatusActivity:
              !isEmpty(it) && !isEmpty(it?.LastUpdated)
                ? intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date())?.includes('quarter')
                  ? formatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date(), { addSuffix: true })
                  : intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdated)), new Date())
                : null,
          };
        }),
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        statusHistory: payload.map((it) => {
          return {
            ...it,
            CreatedDateConverted: convertToLocalDateTime(it?.CreatedDate, DATE_TIME_FORMAT),
          };
        })
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        statusHistory: []
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }


    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS: {
      let requirementDetails = payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows[0] : {};
      requirementDetails = {
        ...requirementDetails,
        ResponseAttachmentList: (requirementDetails?.ResponseAttachmentList || [])?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
          };
        }),
        AssessmentQuestionnaireQuestionResponseList: (requirementDetails?.AssessmentQuestionnaireQuestionResponseList || [])?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            EvidenceDisplayName: item?.ResponseAttachmentList?.length > 0 ? getEvidenceDisplayName(item?.ResponseAttachmentList) : "",
            isPositiveResponse: item?.PositiveResponseScore ? true : item?.NegativeResponseScore ? false : null,
          };
        }),
      }
      let filteredRequirements = state?.assessmentRequirementDetailsList?.filter(item => item?.RequirementId !== RequirementId);
      let tempData = [...filteredRequirements, requirementDetails];
      return {
        ...state,
        loading: false,
        assessmentRequirementDetailsList: tempData,
      }
    }

    case ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE: {
      let tempData = state?.assessmentRequirementDetailsList?.filter(item => item?.RequirementId !== RequirementId);
      return {
        ...state,
        loading: false,
        assessmentRequirementDetailsList: tempData,
      }
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      let tempData = state?.assessmentRequirementDetailsList?.map(item => {
        if (item?.RequirementId === RequirementId) {
          let tempRequirement = {
            ...item,
            ResponseAttachmentList: item?.ResponseAttachmentList?.map(it => {
              if (it?.EvidenceId === EvidenceId) {
                return {
                  ...it,
                  DownloadUrl: downloadUrl,
                }
              } else {
                return it;
              }
            }),
          }
          return tempRequirement;
        } else {
          return item;
        }
      });
      return {
        ...state,
        urlLoading: false,
        assessmentRequirementDetailsList: tempData,
      };
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE: {
      const downloadUrl = "";
      let tempData = state?.assessmentRequirementDetailsList?.map(item => {
        if (item?.RequirementId === RequirementId) {
          let tempRequirement = {
            ...item,
            ResponseAttachmentList: item?.ResponseAttachmentList?.map(it => {
              if (it?.EvidenceId === EvidenceId) {
                return {
                  ...it,
                  DownloadUrl: downloadUrl,
                }
              } else {
                return it;
              }
            }),
          }
          return tempRequirement;
        } else {
          return item;
        }
      });
      return {
        ...state,
        urlLoading: false,
        assessmentRequirementDetailsList: tempData,
      };
    }

    case RESET_EVIDENCE_REVIEW_FLAGS: {
      return {
        ...state,
        ...payload
      };
    }

    case REMEDIATION_GUIDANCE_SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case REMEDIATION_GUIDANCE_SEARCH_SUCCESS: {
      const alternateGuidance = payload.filter((item) => {
        return item.RemediationGuidance.length > 0;
      });
      const list = payload.map((item, index) => {
        return { ...item, RiskNumberValue: index + 1, id: index + 1 };
      });
      return {
        ...state,
        loading: false,
        remediationGuidanceList: list?.map((item) => ({
          ...item,
          Description: item?.RiskDescription?.replace(`${item?.ControlNumber} `, '')
        })),
        alternateGuidance: alternateGuidance,
        highRiskCount: payload[0]?.CountOfHighRiskFindings,
        clientAcceptedCount: payload[0]?.CountOfClientAcceptedRemediation,
        totalPendingResponsesCount: payload[0]?.CountOfPendingVendorRiskFindings,
        totalResolvedCount: payload[0]?.CountOfResolvedRiskFindings,
        totalRemediationCount: payload?.length,
        pendingWithClientCount: payload[0]?.CountOfPendingClientRiskFindings,
      };
    }
    case REMEDIATION_GUIDANCE_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        remediationGuidanceList: [],
        alternateGuidance: [],
        highRiskCount: 0,
        clientAcceptedCount: 0,
        totalRemediationCount: 0,
        totalResolved: 0,
        totalPendingResponsesCount: 0,
        pendingWithClientCount: 0,
      };
    }

    case SEARCH_SELECTED_PORTAL_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SELECTED_PORTAL_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        portalAssessmentDetails: payload,
      };
    };
    case SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        portalAssessmentDetails: {},
      };
    };

    case POST_ADDITIONAL_NOTE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_ADDITIONAL_NOTE_SUCCESS: {
      toast.success(NOTES_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        revertNotes: true,
      };
    };
    case POST_ADDITIONAL_NOTE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    };

    case ADDITIONAL_NOTES_SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADDITIONAL_NOTES_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        portalNotes: payload.map((item) => {
          return {
            ...item,
            CreatedOnConverted: !isEmpty(item?.InsertTimestamp) ? convertToLocalDateTime(item?.InsertTimestamp, DATE_TIME_FORMAT) : null,
          };
        })
      };
    };
    case ADDITIONAL_NOTES_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        portalNotes: [],
      };
    };

    case RESET_ADDITIONAL_NOTES: {
      return {
        ...state,
        revertNotes: false,
      };
    }
    case GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        AssessmentEditDropdownOptionsList: payload
      }
    }
    case GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        AssessmentEditDropdownOptionsList: [],
      }
    }
    case SET_ASSESSMENT_EDITED_OPTION_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case SET_ASSESSMENT_EDITED_OPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        AssessmentEditDropdownSelectedValue: payload
      }
    }
    case SET_ASSESSMENT_EDITED_OPTION_FAILURE: {
      return {
        ...state,
        loading: false,
        AssessmentEditDropdownSelectedValue: [],
      }
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS: {
      const { auditorStatus } = action;
      toast.success(auditorStatus === AUDITOR_DECLINE ? AUDITOR_REVEIW_DECLINED_SUCCESSFULLY_MESSAGE : AUDITOR_REVIEW_CONFIRMED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case GET_QUESTIONNAIRE_LIST_DATA_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_QUESTIONNAIRE_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireLookupList: getSelectOptionsList(payload, 'QuestionnaireTemplateName', 'QuestionnaireTemplateId')
      };
    }

    case GET_QUESTIONNAIRE_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireLookupList: [],
      };
    }

    case ADD_QUESTIONNAIRE_TO_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_QUESTIONNAIRE_TO_ASSESSMENT_SUCCESS: {
      toast.success(QUESTIONNAIRE_ASSOCIATED_SUCCESSFULLY)
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentQuestionnaireProgress: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentQuestionnaireProgress: [],
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireProgress: payload,
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireProgress: [],
      };
    }

    case SET_SELECTED_QUESTIONNAIRE: {
      return {
        ...state,
        selectedQuestionnaire: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING: {
      return {
        ...state,
        requirementLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: payload,
        unansweredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers !== item.CountOfParentQuestions) : [],
        answeredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers === item.CountOfParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: [],
        unansweredRequirements: [],
        answeredRequirements: [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS: {

      let list = [];
      payload.forEach((obj) => {
        list.push({
          ...obj,
          RequirementName: obj?.RequirementGroupName,
          CountOfParentQuestionAnswers: obj?.CountOfRequirementGroupParentQuestionAnswers,
          CountOfParentQuestions: obj?.CountOfRequirementGroupParentQuestions,
          isRequirementGroup: YES_VALUE,
        });
      });

      return {
        ...state,
        loading: false,
        requirementList: list,
        unansweredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers !== item.CountOfRequirementGroupParentQuestions) : [],
        answeredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers === item.CountOfRequirementGroupParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        requirementList: [],
      };
    }

    default:
      return state;
  }
}
