import { TRUE } from 'constants/constants';
import { isEmpty, isNil } from 'lodash';

export const getVendorCompanyInfoBody = (vendorOrgId) => {
  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
      'YearFounded',
      'NumberOfEmployeesRange',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressCountry',
      'RegisteredBusinessAddressZip',
      'Phone',
      'PhoneCode',
      'MainPhone',
      'PhoneExtension',
      'AllVendors',
    ],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getSimilarVendorsListSearchBody = (formData = {}) => {
  let namwWebsiteUrl = formData?.VendorName;
  if (!isEmpty(formData?.WebsiteUrl)) {
    namwWebsiteUrl = `+${namwWebsiteUrl} ${formData?.WebsiteUrl}`;
  }
  const criterion = [
    {
      Field: 'Org_DomainName',
      Operator: '=',
      Value: namwWebsiteUrl,
    },
    {
      Field: 'IsVerified',
      Operator: '=',
      Value: TRUE,
    },
    {
      Field: 'Active',
      Operator: '=',
      Value: TRUE,
    },
  ]

  if (!isNil(formData?.NumberOfVendorSearchResults) && !isEmpty(formData?.NumberOfVendorSearchResults)) {
    criterion.push({
      Field: 'NumberOfVendorSearchResults',
      Operator: '=',
      Value: formData?.NumberOfVendorSearchResults,
    })
  }

  return {
    Intent: '',
    Fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
      'FullTextSearch',
      'IsAssociatedWithCustomer',
      'CustomerVendorRowLimit',
      "AssessmentsCount",
      "MostRecentAssessmentStartDate"
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};
