export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '10px 16px',
          borderRadius: '90px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeMedium: {
          height: '39px',
          fontSize: '14px',
          fontFamily: 'Roboto',
          fontWeight: '400',
        },
        containedSecondary: {
          backgroundColor: theme.palette.secondary.light,
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
