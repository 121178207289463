import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import {
  getAssessmentSummarySearchBody,
  getCorlClearedAssessmentBody,
  getCorlClearedAssessmentRequestBody,
  getProductComponentBody,
  getProductComponentRequirementDetailsBody,
  getProductComponentRequirementsSearchBody,
  getProductGroupListBody,
  getProductListBody,
  getRequirementInadequacyReasonSearchBody,
  getSelectedProductBody,
  getVendorProductRequirementDetailsSearchBody,
  getVendorProductRequirementListSearchBody,
  getVendorScopeInfoSearchBody,
  searchProductPreferencesQuestionImageUrlBody,
  searchVendorProductBody,
  searchVendorProductPreferencesBody,
  updateAssessmentStatusBody,
} from '../utils/vendorProductUtils';
import { setErrors } from './errorHandler';
import { DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE, DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX, DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX, PRODUCT_ALREADY_EXISTS, PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE, PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE, PRODUCT_PROFILE_WIZARD_TAB_OPTIONS, VENDORS_PRODUCTS_TAB_OPTIONS } from 'pages/VendorProfile/Products/constants';
import { setCustomErrors } from './customErrorHandler';
import { NO_DATA_FOUND, NO_IMAGE_FOUND } from 'constants/errorMessage';

export const SEARCH_PRODUCT_GROUP_LIST_LOADING = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_LOADING';
export const SEARCH_PRODUCT_GROUP_LIST_SUCCESS = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_SUCCESS';
export const SEARCH_PRODUCT_GROUP_LIST_FAILURE = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_FAILURE';

export const searchProductGroupList = (vendorOrgId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_GROUP_LIST_LOADING });
  let body = getProductGroupListBody(vendorOrgId);
  try {
    const response = await axios.post('vendor/productgroup/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_LOADING';
export const POST_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const POST_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_FAILURE';

export const addProductGrouptoList = (formData, vendorOrgId, callback) => async (dispatch) => {
  dispatch({
    type: POST_PRODUCT_GROUP_TO_LIST_LOADING,
  });
  const body = JSON.stringify({ ...formData, active: true, VendorOrgId: vendorOrgId });

  try {
    const response = await axios.post('vendor/productgroup', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_PRODUCT_GROUP_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductGroupList(vendorOrgId));
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: POST_PRODUCT_GROUP_TO_LIST_FAILURE,
    });
  }
};

export const PUT_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_LOADING';
export const PUT_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const PUT_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_FAILURE';

export const updateProductGroupinList = (formData, vendorOrgId, callback) => async (dispatch) => {
  dispatch({
    type: PUT_PRODUCT_GROUP_TO_LIST_LOADING,
  });
  const body = JSON.stringify({ ...formData, VendorOrgId: vendorOrgId });
  try {
    const response = await axios.put(`vendor/productgroup/${formData?.ProductGroupId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductGroupList(vendorOrgId));
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
    });
  }
};

export const DELETE_PRODUCT_GROUP_FROM_LIST_LOADING = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_LOADING';
export const DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE';

export const deleteProductGroupinList =
  (productGroupId, forceDelete = false, vendorOrgId, callback) =>
    async (dispatch) => {
      dispatch({
        type: DELETE_PRODUCT_GROUP_FROM_LIST_LOADING,
      });
      let url = `/vendor/productgroup/${productGroupId}`;
      if (forceDelete) {
        url = `${url}?forceDelete=true`;
      }

      try {
        const response = await axios.delete(url);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors[0]?.Message?.toLowerCase()?.includes(DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE);
          dispatch({
            type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
            payload: errors,
          });
        } else {
          dispatch({
            type: DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
            payload: response?.data?.Data,
          });
          dispatch(searchProductGroupList(vendorOrgId));
          if (callback) {
            callback();
          }
        }
      } catch {
        dispatch({
          type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
        });
      }
    };

export const SEARCH_PRODUCT_LIST_LOADING = '@@vendorProduct/SEARCH_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_LIST_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_LIST_FAILURE';

export const searchProductList = (vendorOrgId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
  let body = getProductListBody(vendorOrgId);
  try {
    const response = await axios.post('vendor/product/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_LIST_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
        vendorOrgId: vendorOrgId,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/POST_PRODUCT_TO_LIST_LOADING';
export const POST_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/POST_PRODUCT_TO_LIST_SUCCESS';
export const POST_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/POST_PRODUCT_TO_LIST_FAILURE';

export const addProductstoList = (formData, vendorOrgId) => async (dispatch) => {
  dispatch({
    type: POST_PRODUCT_TO_LIST_LOADING,
  });
  const body = JSON.stringify({ ...formData, active: true, VendorOrgId: vendorOrgId });
  try {
    const response = await axios.post('vendor/product', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_PRODUCT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_PRODUCT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductList(vendorOrgId));
    }
  } catch {
    dispatch({
      type: POST_PRODUCT_TO_LIST_FAILURE,
    });
  }
};

export const PUT_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/PUT_PRODUCT_TO_LIST_LOADING';
export const PUT_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/PUT_PRODUCT_TO_LIST_SUCCESS';
export const PUT_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/PUT_PRODUCT_TO_LIST_FAILURE';

export const updateProductsinList = (formData, productId, vendorOrgId) => async (dispatch) => {
  dispatch({
    type: PUT_PRODUCT_TO_LIST_LOADING,
  });

  const body = JSON.stringify({ ...formData, OemOrgId: vendorOrgId, VendorOrgId: vendorOrgId });
  try {
    const response = await axios.put(`vendor/product/${productId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_PRODUCT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_PRODUCT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductList(vendorOrgId));
      dispatch(searchProductGroupList(vendorOrgId));
    }
  } catch {
    dispatch({
      type: PUT_PRODUCT_TO_LIST_FAILURE,
    });
  }
};

export const DELETE_PRODUCT_FROM_LIST_LOADING = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_LOADING';
export const DELETE_PRODUCT_FROM_LIST_SUCCESS = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_FROM_LIST_FAILURE = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_FAILURE';

export const deleteProductsinList =
  (productVendorId, vendorOrgId, forcedState = false) =>
    async (dispatch) => {
      dispatch({
        type: DELETE_PRODUCT_FROM_LIST_LOADING,
      });
      let url = `/vendor/product/${productVendorId}`;
      if (forcedState) {
        url = `${url}?forceDelete=true`;
      }
      try {
        const response = await axios.delete(url);
        if (response?.data?.ErrorCount > 0) {
          dispatch(setProductDeleteErrors(response?.data?.Errors));
          dispatch(setCustomErrors(response?.data?.Errors));
          dispatch({
            type: DELETE_PRODUCT_FROM_LIST_FAILURE,
          });
        } else {
          dispatch({
            type: DELETE_PRODUCT_FROM_LIST_SUCCESS,
            payload: response?.data?.Data,
          });
          dispatch(searchProductList(vendorOrgId));
        }
      } catch {
        dispatch({
          type: DELETE_PRODUCT_FROM_LIST_FAILURE,
        });
      }
    };

export const SET_VENDORS_PRODUCTS_TAB_VALUE = '@products/SET_VENDORS_PRODUCTS_TAB_VALUE';

export const setVendorProductsTabValue =
  (value = VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_PRODUCTS_TAB_VALUE,
        payload: value,
      });
    };

export const SET_PRODUCT_DELETE_ERRORS = '@products/SET_PRODUCT_DELETE_ERRORS';

export const setProductDeleteErrors = (value) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_DELETE_ERRORS,
    payload: value,
  });
};

export const SET_PRODUCTS_GROUP_FORCE_DELETE = '@products/SET_PRODUCTS_GROUP_FORCE_DELETE';

export const setProductsGroupForceDelete = (value) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCTS_GROUP_FORCE_DELETE,
    payload: value,
  });
};

export const SEARCH_SELECTED_PRODUCT_DETAILS_LOADING = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_LOADING';
export const SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS';
export const SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE';

export const searchSelectedProductDetails = (vendorOrgId, productId) => async (dispatch) => {
  dispatch({ type: SEARCH_SELECTED_PRODUCT_DETAILS_LOADING });
  let body = getSelectedProductBody(vendorOrgId, productId);
  try {
    const response = await axios.post('vendor/product/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SEARCH_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_LOADING';
export const SEARCH_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_SUCCESS';
export const SEARCH_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_FAILURE';

export const searchProductComponent = (productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_COMPONENT_LOADING });
  let body = getProductComponentBody(productVendorId);
  try {
    const response = await axios.post('vendor/product/component/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_COMPONENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/ADD_PRODUCT_COMPONENT_LOADING';
export const ADD_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/ADD_PRODUCT_COMPONENT_SUCCESS';
export const ADD_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/ADD_PRODUCT_COMPONENT_FAILURE';

export const addProductComponent = (formData) => async (dispatch) => {
  dispatch({ type: ADD_PRODUCT_COMPONENT_LOADING });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.post('vendor/product/component', formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_PRODUCT_COMPONENT_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_PRODUCT_COMPONENT_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductComponent(formData?.ProductVendorId));
    }
  } catch (err) {
    dispatch({
      type: ADD_PRODUCT_COMPONENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_LOADING';
export const UPDATE_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_SUCCESS';
export const UPDATE_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_FAILURE';

export const updateProductComponent = (formData, productComponentId) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_COMPONENT_LOADING });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`vendor/product/component/${productComponentId}`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_PRODUCT_COMPONENT_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_COMPONENT_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductComponent(formData?.ProductVendorId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_COMPONENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_LOADING';
export const DELETE_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_SUCCESS';
export const DELETE_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_FAILURE';

export const deleteProductComponent = (productVendorId, productComponentId) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_COMPONENT_LOADING });
  try {
    const response = await axios.delete(`vendor/product/component/${productComponentId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_PRODUCT_COMPONENT_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_PRODUCT_COMPONENT_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductComponent(productVendorId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_COMPONENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CORL_CLEARED_ASSESSMENT_LOADING = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_LOADING';
export const ADD_CORL_CLEARED_ASSESSMENT_SUCCESS = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_SUCCESS';
export const ADD_CORL_CLEARED_ASSESSMENT_FAILURE = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_FAILURE';

export const addCorlClearedAssessment = (data) => async (dispatch) => {
  dispatch({ type: ADD_CORL_CLEARED_ASSESSMENT_LOADING });
  const body = getCorlClearedAssessmentBody(data);
  try {
    const response = await axios.post(`serviceprovider/vendor/product/${data.ProductVendorId}/assessment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchSelectedProductDetails(data?.VendorOrgId, data?.ProductId));
    }
  } catch (err) {
    dispatch({
      type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING';
export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS';
export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE';

export const addCorlClearedAssessmentRequest = (data) => async (dispatch) => {
  dispatch({ type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING });
  const body = getCorlClearedAssessmentRequestBody(data);
  try {
    const response = await axios.post('serviceprovider/vendor/product/assessmentrequest', body, {
      timeout: 180000, // Set the timeout to 180 seconds (in milliseconds)
    });
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_LOADING = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_LOADING';
export const SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_SUCCESS';
export const SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE';

export const searchProductComponentRequirements = (componentId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_LOADING });
  let body = getProductComponentRequirementsSearchBody(componentId);
  try {
    const response = await axios.post('serviceprovider/product/component/requirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING = '@@vendorProduct/ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING';
export const ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS = '@@vendorProduct/ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS';
export const ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE = '@@vendorProduct/ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE';

export const associateProductComponentRequirements = (body, callback) => async (dispatch) => {
  dispatch({ type: ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING });
  const submitBody = JSON.stringify(body);
  try {
    const response = await axios.post('serviceprovider/product/component/requirement/action/bulksave', submitBody);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
      });
    } else {
      dispatch({
        type: ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING';
export const UPDATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS';
export const UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE';

export const updateProductComponentRequirements = (formData, callback) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING,
  });

  let tempFormData = {
    ...formData,
  };

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((File) => {
    body.append('UploadedFileList', File.File);
  });

  try {
    const res = await axios.put(`/serviceprovider/product/componentrequirement/${formData?.ComponentRequirementId}`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS,
        payload: res?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_LOADING = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_LOADING';
export const SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_SUCCESS';
export const SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE';

export const searchProductComponentRequirementDetails = (componentRequirementId = 0) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_LOADING });
  let body = getProductComponentRequirementDetailsBody(componentRequirementId);
  try {
    const response = await axios.post('serviceprovider/product/component/requirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_CORL_CLEARED_STATUS_LOADING = '@@vendorProduct/UPDATE_CORL_CLEARED_STATUS_LOADING';
export const UPDATE_CORL_CLEARED_STATUS_SUCCESS = '@@vendorProduct/UPDATE_CORL_CLEARED_STATUS_SUCCESS';
export const UPDATE_CORL_CLEARED_STATUS_FAILURE = '@@vendorProduct/UPDATE_CORL_CLEARED_STATUS_FAILURE';

export const updateCorlClearedStatus = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_CORL_CLEARED_STATUS_LOADING });
  const body = JSON.stringify(data);
  try {
    const response = await axios.post('/serviceprovider/productvendor/corlclearedstatus/action/update', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_CORL_CLEARED_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_CORL_CLEARED_STATUS_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchSelectedProductDetails(data?.VendorOrgId, data?.ProductId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_CORL_CLEARED_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_EVIDENCE_REQUIREMENT_EXPANDED_ACCORDIAN = '@@vendorProduct/SET_EVIDENCE_REQUIREMENT_EXPANDED_ACCORDIAN';

export const setEvidenceRequirementExpandedAccordian = (index = null) => async (dispatch) => {
  dispatch({
    type: SET_EVIDENCE_REQUIREMENT_EXPANDED_ACCORDIAN,
    payload: index,
  });
};

export const SET_PRODUCT_COMPONENT_REQUIREMENT_LIST = '@@vendorProduct/SET_PRODUCT_COMPONENT_REQUIREMENT_LIST';

export const setProductComponentRequirementList = (list = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_COMPONENT_REQUIREMENT_LIST,
    payload: list,
  });
};

export const SET_LOCK_PRODUCT_COMPONENT_ACCORDION = '@@vendorProduct/SET_LOCK_PRODUCT_COMPONENT_ACCORDION';

export const setLockProductComponentAccordion = (lock = false) => async (dispatch) => {
  dispatch({
    type: SET_LOCK_PRODUCT_COMPONENT_ACCORDION,
    payload: lock,
  });
};

export const SET_SELECTED_VENDOR_DETAILS = '@@vendorProduct/SET_SELECTED_VENDOR_DETAILS';

export const setSelectedVendorDetails = (vendorDetails = {}) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_VENDOR_DETAILS,
    payload: vendorDetails,
  })
}

export const VENDOR_PROVIDED_SCOPE_INFO_LOADING = '@@vendorProduct/VENDOR_PROVIDED_SCOPE_INFO_LOADING';
export const VENDOR_PROVIDED_SCOPE_INFO_SUCCESS = '@@vendorProduct/VENDOR_PROVIDED_SCOPE_INFO_SUCCESS';
export const VENDOR_PROVIDED_SCOPE_INFO_FAILURE = '@@vendorProduct/VENDOR_PROVIDED_SCOPE_INFO_FAILURE';

export const searchVendorScopeInfo = (productVendorId) => async (dispatch) => {
  dispatch({ type: VENDOR_PROVIDED_SCOPE_INFO_LOADING });
  try {

    let body = getVendorScopeInfoSearchBody(productVendorId);

    const response = await axios.post('serviceprovider/vendorproduct/component/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
      });
    } else {
      dispatch({
        type: VENDOR_PROVIDED_SCOPE_INFO_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING = '@@vendorProduct/VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING';
export const VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS = '@@vendorProduct/VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS';
export const VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE = '@@vendorProduct/VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE';

export const searchVendorProductRequirementList = (assessmentId = null, assessmentQuestionnaireRequirementId = null) => async (dispatch) => {
  dispatch({ type: VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING });
  try {

    let body = getVendorProductRequirementListSearchBody(assessmentId, assessmentQuestionnaireRequirementId);

    const response = await axios.post('serviceprovider/assessmentquestionnairerequirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
      });
    } else {
      dispatch({
        type: VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING = '@@vendorProduct/VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING';
export const VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS = '@@vendorProduct/VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS';
export const VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE = '@@vendorProduct/VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE';

export const searchVendorProductRequirementDetails = (requirementId, assessmentQuestionnaireRequirementId) => async (dispatch) => {
  dispatch({ type: VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING });
  try {
    let body = getVendorProductRequirementDetailsSearchBody(assessmentQuestionnaireRequirementId);

    const response = await axios.post('serviceprovider/assessmentquestionnairerequirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS,
        payload: response?.data || [],
        RequirementId: requirementId
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING = '@@evidenceManagement/GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING';
export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS = '@@evidenceManagement/GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS';
export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE = '@@evidenceManagement/GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE';

export const getEvidenceReviewUploadDocumentUrl = (requirementId, evidenceId) => async (dispatch) => {
  dispatch({ type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING });

  try {
    const res = await axios.get(`/vendor/evidence/${evidenceId}/document/action/geturl`);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => it.Message !== NO_DATA_FOUND
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS,
        payload: res?.data,
        RequirementId: requirementId,
        EvidenceId: evidenceId
      });
    }
  } catch (err) {
    dispatch({
      type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REQUIREMENT_INADEQUACY_REASON_LOADING = '@@vendorProduct/SEARCH_REQUIREMENT_INADEQUACY_REASON_LOADING';
export const SEARCH_REQUIREMENT_INADEQUACY_REASON_SUCCESS = '@@vendorProduct/SEARCH_REQUIREMENT_INADEQUACY_REASON_SUCCESS';
export const SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE = '@@vendorProduct/SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE';

export const searchRequirementInadequacyReason = (requirementId) => async (dispatch) => {
  dispatch({ type: SEARCH_REQUIREMENT_INADEQUACY_REASON_LOADING });
  try {
    let body = getRequirementInadequacyReasonSearchBody(requirementId);

    const response = await axios.post('/questionnairetemplate/requirement/inadequacyreason/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_REQUIREMENT_INADEQUACY_REASON_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING = '@@vendorProduct/POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING';
export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS = '@@vendorProduct/POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS';
export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE = '@@vendorProduct/POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE';

export const postAssessmentQuestionnaireReason = (productVendorId, formData, assessmentQuestionnaireRequirementId = null) => async (dispatch) => {
  dispatch({ type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING });
  try {
    const response = await axios.post(`/serviceprovider/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/inadequacyreason`, formData);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE,
      });
    } else {
      dispatch({
        type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS,
        payload: response?.data || {},
      });
      dispatch(searchVendorProductRequirementList(formData?.AssessmentId, assessmentQuestionnaireRequirementId))
    }
  } catch (err) {
    dispatch({
      type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

/* Product Details Summary */
export const SEARCH_ASSESSMENT_SUMMARY_LOADING = '@@vendorProductAssessmentSummary/SEARCH_ASSESSMENT_SUMMARY_LOADING';
export const SEARCH_ASSESSMENT_SUMMARY_SUCCESS = '@@vendorProductAssessmentSummary/SEARCH_ASSESSMENT_SUMMARY_SUCCESS';
export const SEARCH_ASSESSMENT_SUMMARY_FAILURE = '@@vendorProductAssessmentSummary/SEARCH_ASSESSMENT_SUMMARY_FAILURE';

export const searchAssessmentSummary = (assessmentId) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_SUMMARY_LOADING });
  let body = getAssessmentSummarySearchBody(assessmentId);
  try {
    const response = await axios.post('assessment/assessmentsummary/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_ASSESSMENT_SUMMARY_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_SUMMARY_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_SUMMARY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_ASSESSMENT_SUMMARY_LOADING = '@@vendorProductAssessmentSummary/UPDATE_ASSESSMENT_SUMMARY_LOADING';
export const UPDATE_ASSESSMENT_SUMMARY_SUCCESS = '@@vendorProductAssessmentSummary/UPDATE_ASSESSMENT_SUMMARY_SUCCESS';
export const UPDATE_ASSESSMENT_SUMMARY_FAILURE = '@@vendorProductAssessmentSummary/UPDATE_ASSESSMENT_SUMMARY_FAILURE';

export const updateAssessmentSummary = (formData) => async (dispatch) => {
  dispatch({ type: UPDATE_ASSESSMENT_SUMMARY_LOADING });

  const body = JSON.stringify(formData);
  try {
    const response = await axios.post(`assessment/${formData?.AssessmentId}/assessmentsummary`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_ASSESSMENT_SUMMARY_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_ASSESSMENT_SUMMARY_SUCCESS,
        payload: response?.data?.Data,
        assessmentSummaryId: formData?.AssessmentSummaryId,
      });
      dispatch(searchAssessmentSummary(formData?.AssessmentId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_ASSESSMENT_SUMMARY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING';
export const SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE';

export const searchVendorProductPreferences = (vendorOrgId, callback = undefined, currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING });
  try {
    const body = searchVendorProductPreferencesBody(vendorOrgId, PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE);
    const response = await axios.post(`/vendor/productpreferences/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS,
        payload: response?.data?.Data?.Rows,
        currentIndex: currentIndex,
        isReview: isReview
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_QUESTION_INDEX = '@@vendorProduct/SET_PRODUCT_PREFERENCE_QUESTION_INDEX';

export const setProductPreferenceQuestionIndex = (index = DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_QUESTION_INDEX,
    payload: index,
  })
}

export const PUBLISH_ASSESSMENT_SUMMARY_LOADING = '@@vendorProductAssessmentSummary/PUBLISH_ASSESSMENT_SUMMARY_LOADING';
export const PUBLISH_ASSESSMENT_SUMMARY_SUCCESS = '@@vendorProductAssessmentSummary/PUBLISH_ASSESSMENT_SUMMARY_SUCCESS';
export const PUBLISH_ASSESSMENT_SUMMARY_FAILURE = '@@vendorProductAssessmentSummary/PUBLISH_ASSESSMENT_SUMMARY_FAILURE';

export const publishAssessmentSummary = (assessmentQuestionnaireIdList, assessmentId, isChildAssessment) => async (dispatch) => {
  dispatch({ type: PUBLISH_ASSESSMENT_SUMMARY_LOADING });

  const data = {
    "AssessmentQuestionnaireIdList": assessmentQuestionnaireIdList,
    "AssessmentId": assessmentId,
    "IsChildAssessment": isChildAssessment,
  }
  const body = JSON.stringify(data);
  try {
    const response = await axios.post(`/assessment/assessmentquestionnairequestionresult/publish`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUBLISH_ASSESSMENT_SUMMARY_FAILURE,
      });
    } else {
      dispatch({
        type: PUBLISH_ASSESSMENT_SUMMARY_SUCCESS,
        payload: response?.data?.Data
      });
    }
  } catch (err) {
    dispatch({
      type: PUBLISH_ASSESSMENT_SUMMARY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING = '@@vendorProduct/GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING';
export const GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS = '@@vendorProduct/GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS';
export const GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE = '@@vendorProduct/PRODUCT_PREFERENCE_IMAGE_URL_FAILURE';

export const getProductPreferenceImageUrl = (vendorOrgId, productPreferenceQuestionId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING });

  try {
    const body = searchProductPreferencesQuestionImageUrlBody(vendorOrgId);
    const res = await axios.post(`vendor/productpreferences/${productPreferenceQuestionId}/image/action/geturl`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => ((it.Message !== NO_DATA_FOUND) && (it.Message !== NO_IMAGE_FOUND))
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS,
        payload: res?.data,
        ProductPreferenceQuestionId: productPreferenceQuestionId
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SET_PRODUCT_PREFERENCE_QUESTION_LIST = '@@vendorProduct/SET_PRODUCT_PREFERENCE_QUESTION_LIST';

export const setProductPreferenceQuestionList = (list = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_QUESTION_LIST,
    payload: list,
  })
}

export const UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING';
export const UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS';
export const UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE';

export const updateProductPreferenceStatus = (formData, callback, productInformation = false) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING });

  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`vendor/productpreferences/${formData?.ProductPreferenceId}/action/updatestatus`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS,
        payload: response?.data?.Data,
        productInformation: productInformation,
      });
      if (callback) {
        callback();
      }
      if (productInformation) {
        dispatch(searchVendorProductInformation(formData?.VendorOrgId))
      } else dispatch(searchVendorProductPreferences(formData?.VendorOrgId))
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_STATUS_ID = '@@vendorProduct/SET_PRODUCT_PREFERENCE_STATUS_ID';

export const setProductPreferenceStatusId = (val = null) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_STATUS_ID,
    payload: val,
  })
}

// product information wizard actions
export const SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING';
export const SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE';

export const searchVendorProductInformation = (vendorOrgId, currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING });
  try {
    const body = searchVendorProductPreferencesBody(vendorOrgId, PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE);
    const response = await axios.post(`/vendor/productpreferences/search`, body)
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS,
        payload: response?.data.Data?.Rows,
        currentIndex: currentIndex,
        isReview: isReview
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_INFORMATION_QUESTION_INDEX = '@@vendorProduct/SET_PRODUCT_INFORMATION_QUESTION_INDEX';

export const setProductInformationQuestionIndex = (index = DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFORMATION_QUESTION_INDEX,
    payload: index,
  })
}

export const SET_PRODUCT_INFORMATION_QUESTION_LIST = '@@vendorProduct/SET_PRODUCT_INFORMATION_QUESTION_LIST';

export const setProductInformationQuestionList = (list = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFORMATION_QUESTION_LIST,
    payload: list,
  })
}

export const GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING = '@@vendorProduct/GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING';
export const GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS = '@@vendorProduct/GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS';
export const GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE = '@@vendorProduct/PRODUCT_INFORMATION_IMAGE_URL_FAILURE';

export const getProductInformationImageUrl = (vendorOrgId, productPreferenceQuestionId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING });

  try {
    const body = searchProductPreferencesQuestionImageUrlBody(vendorOrgId, PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE);
    const res = await axios.post(`/vendor/productpreferences/${productPreferenceQuestionId}/image/action/geturl`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => it.Message !== NO_DATA_FOUND
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS,
        payload: res?.data,
        ProductPreferenceQuestionId: productPreferenceQuestionId
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING';
export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS';
export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE';

export const saveVendorProductInformation = (data, currentIndex, isReview) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING });
  try {
    const body = JSON.stringify(data);
    const response = await axios.post(`/vendor/productpreferenceresponse`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS,
        payload: response?.data,
        currentIndex: currentIndex,
        isReview: isReview
      });
      dispatch(searchVendorProductInformation(data?.VendorOrgId, currentIndex, isReview));
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_INFO_REVIEW = '@@vendorProduct/SET_PRODUCT_INFO_REVIEW';

export const setProductInformationReview = (val = false) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFO_REVIEW,
    payload: val,
  })
}

export const SEARCH_VENDOR_PRODUCT_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_LOADING';
export const SEARCH_VENDOR_PRODUCT_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_FAILURE';

export const searchVendorProduct = (productName, vendorOrgId, formData, currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_LOADING });

  let warning = [
    {
      Type: 'Error',
      Message: PRODUCT_ALREADY_EXISTS.replace('XXXXX', productName),
      Source: null,
    },
  ];

  let body = searchVendorProductBody(productName, vendorOrgId);
  try {
    const response = await axios.post(`/vendor/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors.filter((it) => it.Message === NO_DATA_FOUND).length > 0) {
        dispatch(saveVendorProductInformation(formData, currentIndex, isReview));
      } else {
        const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
      }
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(setErrors(warning));
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING';
export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS';
export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE';

export const saveVendorProductPreference = (data, currentIndex, isReview) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING });
  try {
    const body = JSON.stringify(data);
    const response = await axios.post(`/vendor/productpreferenceresponse`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchVendorProductPreferences(data?.VendorOrgId, null, currentIndex, isReview));
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_REVIEW = '@@vendorProduct/SET_PRODUCT_PREFERENCE_REVIEW';

export const setProductPreferenceReview = (val = false) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_REVIEW,
    payload: val,
  })
}

export const SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE = '@@vendorProduct/SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE';

export const setProductProfileWizardTabValue = (val = PRODUCT_PROFILE_WIZARD_TAB_OPTIONS.PRODUCT_CATALOG_PREFERENCES) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE,
    payload: val,
  })
}

export const POST_ASSESSMENT_STATUS_LOADING = '@@vendorProduct/POST_ASSESSMENT_STATUS_LOADING';
export const POST_ASSESSMENT_STATUS_SUCCESS = '@@vendorProduct/POST_ASSESSMENT_STATUS_SUCCESS';
export const POST_ASSESSMENT_STATUS_FAILURE = '@@vendorProduct/POST_ASSESSMENT_STATUS_FAILURE';

export const updateAssessmentStatus = (selectedProductDetails, statusId) => async (dispatch) => {
  dispatch({ type: POST_ASSESSMENT_STATUS_LOADING });
  let body = updateAssessmentStatusBody(selectedProductDetails?.AssessmentId, statusId, selectedProductDetails?.AssessmentStatusId);
  try {
    const res = await axios.post(`/assessment/${selectedProductDetails?.AssessmentId}/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_ASSESSMENT_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_ASSESSMENT_STATUS_SUCCESS,
        payload: res?.data,
      });
      dispatch(searchSelectedProductDetails(selectedProductDetails?.VendorOrgId, selectedProductDetails?.ProductId));
    }
  } catch (err) {
    dispatch({
      type: POST_ASSESSMENT_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING = '@@vendorProduct/POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING';
export const POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS = '@@vendorProduct/POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS';
export const POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE = '@@vendorProduct/POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE';

export const confirmAuditorAffordance = (formData) => async (dispatch) => {
  dispatch({ type: POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING });
  try {
    const res = await axios.post(`/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/action/confirm`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS,
        payload: res?.data,
      });
      dispatch(searchVendorProductRequirementList(formData?.AssessmentId))
    }
  } catch (err) {
    dispatch({
      type: POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_CONFIRM_AUDITOR_MILESTONE_LOADING = '@@vendorProduct/POST_CONFIRM_AUDITOR_MILESTONE_LOADING';
export const POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS = '@@vendorProduct/POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS';
export const POST_CONFIRM_AUDITOR_MILESTONE_FAILURE = '@@vendorProduct/POST_CONFIRM_AUDITOR_MILESTONE_FAILURE';

export const confirmAuditorMilestone = (formData, requirementId, assessmentQuestionnaireRequirementId) => async (dispatch) => {
  dispatch({ type: POST_CONFIRM_AUDITOR_MILESTONE_LOADING });
  try {
    const res = await axios.post(`/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/auditorreview/ipi/action/confirm`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS,
        payload: res?.data,
        auditorStatus: formData?.AuditorStatusId
      });
      dispatch(searchVendorProductRequirementDetails(requirementId, assessmentQuestionnaireRequirementId));
    }
  } catch (err) {
    dispatch({
      type: POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};