import { Box, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavBarStyle.scss';
import { menuListWrapper, menuLinkWrapper, menuWrapper, menuLink, linkWrapper } from './NavBarStyles';
import MenuPanel from './MenuPanel';
import { getIcon } from './util';
import { setMenuPanelId } from 'actions/navbar';
import { isEmpty } from 'lodash';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const menuPanelId = useSelector((state) => state.navbar.menuPanelId);

  const menuList = useSelector((state) => state.navbar.menuList);
  const location = useLocation();

  const getActiveNavClass = (subMenuList) => {
    return subMenuList?.filter(
      (it) => it.ButtonRoute === `${location.pathname + location?.search}` || it.MenuRoute === `${location.pathname + location?.search}` || (location.pathname === '/' && it.AppMenuName === 'Clients')
    ).length > 0
      ? 'activeNavBar'
      : '';
  };

  const onClickMenu = (subMenuList) => {
    if (subMenuList?.length > 0) {
      history.push(subMenuList[0].MenuRoute);
    }
  };

  return (
    <Box sx={menuListWrapper}>
      {menuList &&
        menuList.map((menu, index) => {
          const { AppMenuId, AppMenuName, MenuRoute, MenuIcon, SubMenuList = [] } = menu;
          return SubMenuList?.length === 0 ? (
            <Box sx={linkWrapper}>
              <NavLink
                // Code to disable Tab on Navbar
                // onClick = {(e) => { AppMenuName === "TabName" && e.preventDefault()}}
                // className={AppMenuName === "Tabname" ? "inactiveTab" : ""}
                to={MenuRoute}
                key={index}
                onMouseOver={() => {
                  dispatch(setMenuPanelId(0));
                }}
              >
                <Box
                  sx={(theme) => menuLinkWrapper(theme, isEmpty(AppMenuName))}
                  className={
                    // To disable tab on Navbar
                    // AppMenuName === "TabName" ? "inactiveTab" :
                    location.pathname === MenuRoute ? 'activeNavBar' : 'hoverIcon'
                  }
                >
                  <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                  <Typography sx={menuLink}>{AppMenuName}</Typography>
                </Box>
              </NavLink>
            </Box>
          ) : (
            <Box
              key={index}
              onMouseOver={() => {
                dispatch(setMenuPanelId(AppMenuId));
              }}
              onMouseLeave={() => dispatch(setMenuPanelId(0))}
              sx={menuWrapper}
              onClick={() => onClickMenu(SubMenuList)}
            >
              <Box sx={(theme) => menuLinkWrapper(theme, isEmpty(AppMenuName))} className={getActiveNavClass(SubMenuList)}>
                <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                <Typography sx={menuLink}>{AppMenuName}</Typography>
                {menuPanelId === AppMenuId && <MenuPanel subMenuList={SubMenuList} />}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default Menu;
