import { toast } from 'react-toastify';
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  GET_USER_ASSOCIATION_LOADING,
  GET_USER_ASSOCIATION_SUCCESS,
  GET_USER_ASSOCIATION_FAILURE,
  POST_USER_ASSOCIATION_LOADING,
  POST_USER_ASSOCIATION_SUCCESS,
  POST_USER_ASSOCIATION_FAILURE,
  USER_DOMAIN_LIST_LOADING,
  USER_DOMAIN_LIST_FAILURE,
  USER_DOMAIN_LIST_SUCCESS,
  CREATE_USER_DOMAIN_ID_SUCCESS,
  CREATE_USER_DOMAIN_ID_LOADING,
  CREATE_USER_DOMAIN_ID_FAILURE,
  DELETE_USER_DOMAIN_ID_LOADING,
  DELETE_USER_DOMAIN_ID_FAILURE,
  DELETE_USER_DOMAIN_ID_SUCCESS,
  SAVE_USER_DOMAIN_ID_LOADING,
  SAVE_USER_DOMAIN_ID_FAILURE,
  SAVE_USER_DOMAIN_ID_SUCCESS,
} from 'actions/user';
import {
  ERROR_MESSAGE,
  USER_ASSOCIATED_SUCCESSFULLY,
  USER_DOMAIN_ID_CREATED_SUCCESSFULLY,
  USER_DOMAIN_ID_DELETED_SUCCESSFULLY,
  USER_DOMAIN_ID_SAVED_SUCCESSFULLY,
} from 'components/Common/AlertMessages';

const initialState = {
  loading: false,
  error: '',
  users: [],
  userAssociation: {},
  domainConfigList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LIST_REQUEST:
    case POST_USER_ASSOCIATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: payload,
      };
    }

    case USER_LIST_FAIL: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
      };
    }

    case GET_USER_ASSOCIATION_LOADING: {
      return {
        ...state,
        loading: true,
        userAssociation: {},
      };
    }

    case GET_USER_ASSOCIATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        userAssociation: payload,
      };
    }

    case GET_USER_ASSOCIATION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        userAssociation: {},
      };
    }

    case POST_USER_ASSOCIATION_SUCCESS: {
      toast.success(USER_ASSOCIATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_USER_ASSOCIATION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case USER_DOMAIN_LIST_LOADING:
    case CREATE_USER_DOMAIN_ID_LOADING:
    case SAVE_USER_DOMAIN_ID_LOADING:
    case DELETE_USER_DOMAIN_ID_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_DOMAIN_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        domainConfigList: [],
      };
    }

    case USER_DOMAIN_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        domainConfigList: payload,
      };
    }

    case CREATE_USER_DOMAIN_ID_SUCCESS: {
      toast.success(USER_DOMAIN_ID_CREATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_USER_DOMAIN_ID_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SAVE_USER_DOMAIN_ID_SUCCESS: {
      toast.success(USER_DOMAIN_ID_SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case SAVE_USER_DOMAIN_ID_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_DOMAIN_ID_SUCCESS: {
      toast.success(USER_DOMAIN_ID_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_DOMAIN_ID_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
