export const NO_DATA_FOUND = 'No data found';
export const DOMAIN_ERROR_MESSAGE = [
  {
    Type: 'E',
    Message: 'The email ID should be of the company domain only.',
    Source: null,
  },
];
export const NO_CONTACTS_FOUND = [
  {
    Type: 'I',
    Message: 'No Contacts found, please add Contacts.',
  },
];
export const NO_USERS_FOUND = [
  {
    Type: 'I',
    Message: 'No Users found, please add user.',
  },
];
export const NO_CUSTOMERS_FOUND = [
  {
    Type: 'I',
    Message: 'No customers associated, please add Customers.',
  },
];
export const CANCEL_PRODUCT_FORM_CONFIRMATION = 'This will close the page without saving the changes. Are you sure?';
export const DELETE_PRODUCT_GROUP_CONFIRMATION = 'This action will delete the Product Group. Are you sure?';

export const PRODUCT_ADDED_SUCCESSFULLY = 'Product Added Successfully';
export const PRODUCT_UPDATED_SUCCESSFULLY = 'Product Updated Successfully';
export const PRODUCT_DELETED_SUCCESSFULLY = 'Product Deleted Successfully';

export const DELETE_PRODUCT_CONFIRMATION = 'This action will delete the Product. Are you sure?';

export const CANCEL_FILE_UPLOAD_CONFIRMATION = 'This action will not save the changes. Are you sure?';

export const FORCE_UPDATE_ASSESSMENT_QUESTION_CONFIRMATION = "This question is associated with another questionnaire template or requirement. By updating it, changes will be reflected wherever this question has been used. Are you sure you want to proceed?";

export const NO_IMAGE_FOUND = 'Product Preference Question Image not found.';
export const FORCE_UPDATE_PROGRESS_INDICATOR_CONFIRMATION = "This Incremental Progress Indicator / Step is associated with another Inadequecy Reason. By adding or updating it, changes will be reflected wherever this Incremental Progress Indicator / Step has been used. Are you sure you want to proceed?";
export const REACT_TO_PDF_ERROR = 'Target ref must be used or informed.';

export const LOOKUP_SEARCH_VALUE_ERROR_MESSAGE = 'No questions match the search criterion';
export const LOOKUP_SEARCH_LIMIT_ERROR_MESSAGE = 'No more questions available!!';