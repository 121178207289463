import { CORL_APP_ID } from 'constants/users';
import { isEmpty } from 'lodash';

export const searchCorlUsersBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'FirstName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: [
      'UserId',
      'UserEmail',
      'FirstName',
      'MiddleName',
      'LastName',
      'NamePrefix',
      'NameSuffix',
      'ProfilePictureUrl',
      'ExternalIdpId',
      'ServiceProviderUserList',
      'ServiceProviderOrgId',
      'ServiceProviderOrgName',
      'Title',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'FirstName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchUserRoleListBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'UserRoleName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      ...criterion,
      {
        field: 'AppId',
        operator: '=',
        value: CORL_APP_ID,
      },
      {
        field: 'Active',
        operator: '=',
        value: 1,
      },
    ],
    sort: [],
  };
};

export const searchUserRoleListOfUserBody = (userId) => {
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      {
        field: 'UserId',
        operator: '=',
        value: userId,
      },
    ],
    sort: [],
  };
};
