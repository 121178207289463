import axios from 'axios';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import { getEvidenceDetailsBody } from 'utils/evidenceManagementUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { DOWNLOAD_PERIOD_EXPIRED_MESSAGE } from "components/Common/AlertMessages";

export const UPLOAD_EVIDENCE_FILE_LOADING = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_LOADING';
export const UPLOAD_EVIDENCE_FILE_SUCCESS = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_SUCCESS';
export const UPLOAD_EVIDENCE_FILE_FAILURE = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_FAILURE';

export const uploadEvidenceFile = (data, vendorOrgId) => async (dispatch) => {
  dispatch({ type: UPLOAD_EVIDENCE_FILE_LOADING });

  try {
    let body = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'UploadedFile') {
        body.append(key, data[key]);
      } else {
        body.append(key, JSON.stringify(data[key].toString()));
      }
    });
    const response = await axios.post(`/vendor/evidence`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPLOAD_EVIDENCE_FILE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: UPLOAD_EVIDENCE_FILE_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchEvidenceDetails(null, vendorOrgId));
    }
  } catch (err) {
    dispatch({
      type: UPLOAD_EVIDENCE_FILE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_EVIDENCE_DETAILS_LOADING = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_LOADING';
export const SEARCH_EVIDENCE_DETAILS_SUCCESS = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_SUCCESS';
export const SEARCH_EVIDENCE_DETAILS_FAILURE = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_FAILURE';

export const searchEvidenceDetails = (searchData, vendorOrgId) => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_DETAILS_LOADING });

  let body = getEvidenceDetailsBody(searchData, vendorOrgId);
  try {
    const response = await axios.post(`/vendor/evidence/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_EVIDENCE_DOCUMENTS_LOADING = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_LOADING';
export const DELETE_EVIDENCE_DOCUMENTS_SUCCESS = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_SUCCESS';
export const DELETE_EVIDENCE_DOCUMENTS_FAILURE = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_FAILURE';

export const deleteEvidenceDocument = (evidenceId, vendorOrgId) => async (dispatch) => {
  dispatch({ type: DELETE_EVIDENCE_DOCUMENTS_LOADING });

  try {
    const response = await axios.delete(`/vendor/evidence/${evidenceId}`);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(searchEvidenceDetails(null, vendorOrgId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_EVIDENCE_UPLOAD_DOCUMENT_URL_LOADING = '@@evidenceManagement/GET_EVIDENCE_UPLOAD_DOCUMENT_URL_LOADING';
export const GET_EVIDENCE_UPLOAD_DOCUMENT_URL_SUCCESS = '@@evidenceManagement/GET_EVIDENCE_UPLOAD_DOCUMENT_URL_SUCCESS';
export const GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE = '@@evidenceManagement/GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE';

export const getEvidenceUploadDocumentUrl = (evidenceId) => async (dispatch) => {
  dispatch({ type: GET_EVIDENCE_UPLOAD_DOCUMENT_URL_LOADING });

  try {
    const res = await axios.get(`/vendor/evidence/${evidenceId}/document/action/geturl`);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => it.Message !== NO_DATA_FOUND
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE,
        payload: {},
      });
    } else {
      fetch(res?.data?.Data?.DownloadUrl)
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error('Failed to download file');
          }
        })
        .then(blob => {
          // Save the file to local machine
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = res?.data?.Data?.FileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          dispatch({
            type: GET_EVIDENCE_UPLOAD_DOCUMENT_URL_SUCCESS,
            payload: res?.data?.Data,
          });
        })
        .catch(error => {
          dispatch(setErrors([
            {
              Message: DOWNLOAD_PERIOD_EXPIRED_MESSAGE
            }]
          ));
          dispatch({
            type: GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE,
            payload: {},
          });
        });
    }
  } catch (err) {
    dispatch({
      type: GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
