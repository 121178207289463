import { SET_MENU, SET_MENU_PANEL_ID } from '../actions/navbar';

export const MENU_CLAIM_LIST = 'menuClaimList';

const getMenus = () => {
  return JSON.parse(localStorage.getItem(MENU_CLAIM_LIST));
};

const initialState = {
  menuList: getMenus(),
  menuPanelId: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MENU: {
      localStorage.setItem(MENU_CLAIM_LIST, JSON.stringify(payload));
      return {
        ...state,
        menuList: payload,
      };
    }

    case SET_MENU_PANEL_ID: {
      return {
        ...state,
        menuPanelId: payload,
      };
    }

    default:
      return state;
  }
}
