import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getSlaInformationListSearchBody, getSlaStatementListSearchBody } from '../utils/vendorIncidentPreparedness';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const GET_INCIDENT_SLA_SECTION_LIST_LOADING = '@@vendorIncidentPreparedness/GET_INCIDENT_SLA_SECTION_LIST_LOADING';
export const GET_INCIDENT_SLA_SECTION_LIST_SUCCESS = '@@vendorIncidentPreparedness/GET_INCIDENT_SLA_SECTION_LIST_SUCCESS';
export const GET_INCIDENT_SLA_SECTION_LIST_FAILURE = '@@vendorIncidentPreparedness/GET_INCIDENT_SLA_SECTION_LIST_FAILURE';

export const getIncidentSlaSectionList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_SLA_SECTION_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_INCIDENT_SLA_SECTION_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_SLA_INFORMATION_LIST_LOADING = '@@vendorIncidentPreparedness/SEARCH_SLA_INFORMATION_LIST_LOADING';
export const SEARCH_SLA_INFORMATION_LIST_SUCCESS = '@@vendorIncidentPreparedness/SEARCH_SLA_INFORMATION_LIST_SUCCESS';
export const SEARCH_SLA_INFORMATION_LIST_FAILURE = '@@vendorIncidentPreparedness/SEARCH_SLA_INFORMATION_LIST_FAILURE';

export const getSLAInformationList = (vendorOrgId, customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_SLA_INFORMATION_LIST_LOADING });
  try {
    let body = getSlaInformationListSearchBody(vendorOrgId, customerVendorId, productVendorId);
    let response = await axios.post('/vendor/slaresponse/search', body);
    if (response?.data?.ErrorCount > 0) {
      let slastmtbody = getSlaStatementListSearchBody();
      let slaresponse = await axios.post('/vendor/slaresponse/search', slastmtbody);
      if (slaresponse?.data?.ErrorCount > 0) {
        dispatch(setErrors(slaresponse?.data?.Errors));
      } else {
        dispatch({
          type: SEARCH_SLA_INFORMATION_LIST_SUCCESS,
          payload: slaresponse.data,
        });
      }
    } else {
      if (response?.data?.Data.Rows.length > 0) {
        dispatch({
          type: SEARCH_SLA_INFORMATION_LIST_SUCCESS,
          payload: response.data,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SLA_INFORMATION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
