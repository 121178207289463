export default function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: theme.palette.info.main,
          },
          '>.MuiTab-iconWrapper': {
            marginBottom: '10px',
          },
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: '400',
        },
        textColorInherit: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.palette.info.main,
        },
      },
    },
  };
}
