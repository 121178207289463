import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getVendorProfileContactsBody, searchVendorProfileSpecificContactsBody } from 'utils/vendorProfileContactsUtils';
import { setErrors } from './errorHandler';

export const GET_VENDOR_PROFILE_CONTACTS_LOADING = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_LOADING';
export const GET_VENDOR_PROFILE_CONTACTS_SUCCESS = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_SUCCESS';
export const GET_VENDOR_PROFILE_CONTACTS_FAILURE = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_FAILURE';

export const getVendorProfileContacts = (vendorOrgId) => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_PROFILE_CONTACTS_LOADING,
  });

  let body = getVendorProfileContactsBody(vendorOrgId);

  try {
    const response = await axios.post(`/vendor/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_VENDOR_PROFILE_CONTACTS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_VENDOR_PROFILE_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_VENDOR_PROFILE_CONTACTS_FAILURE,
    });
  }
};

export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING';
export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS';
export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE';

export const getVendorProfileSpecificContacts = (searchValue, vendorOrgId) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING,
  });
  let body = searchVendorProfileSpecificContactsBody(searchValue, vendorOrgId);

  try {
    const response = await axios.post(`/vendor/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
    });
  }
};

export const SET_CONTACTS_LOADING = '@@vendorProfileContact/SET_CONTACTS_LOADING';
export const SET_CONTACTS_SUCCESS = '@@vendorProfileContact/SET_CONTACTS_SUCCESS';
export const SET_CONTACTS_FAILURE = '@@vendorProfileContact/SET_CONTACTS_FAILURE';

export const setContacts = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_CONTACTS_LOADING,
  });
  try {
    dispatch({
      type: SET_CONTACTS_SUCCESS,
      payload: payload,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: SET_CONTACTS_FAILURE,
    });
  }
};

export const RESET_CONTACTS_LISTS = '@@vendorProfileContact/RESET_CONTACTS_LISTS';

export const resetContacts = () => async (dispatch) => {
  dispatch({
    type: RESET_CONTACTS_LISTS,
  });
};
