import axios from 'axios';
import { setErrors } from './errorHandler';
import {
  convertToValueLable,
  getCurrentPageNo,
  getCurrentQuestionnaireTemplateList,
  getQuestionnaireTemplateListSearchBodyOnType,
  getQuestionnaireTemplatesUtils,
} from 'utils/getQuestionnaireTemplateUtils';
import { uniqBy, isNil } from 'lodash';
import { SCOPING_QUESTIONNAIRE_TYPE, SECURITY_QUESTIONNAIRE_TYPE, SUPPLEMENTAL_QUESTIONNAIRE_TYPE } from 'pages/QuestionnaireBuilder/constants';

export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST';
export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL';

export const getQuestionnaireTemplate = (id) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST });
  try {
    const res = await axios.get(`/questionnairetemplate/${id}`);

    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
    });
  }
};

export const SET_QUESTIONNAIRE_TEMPLATE_DETAILS = '@@questionnaireTemplate/SET_QUESTIONNAIRE_TEMPLATE_DETAILS';

export const setQuestionnaireTemplateDetails = (detail) => async (dispatch) => {
  dispatch({
    type: SET_QUESTIONNAIRE_TEMPLATE_DETAILS,
    payload: detail,
  });
};

export const SEARCH_QUESTIONNAIRE_TEMPLATES_REQUEST = '@getQuestionnaireTemplet/SEARCH_QUESTIONNAIRE_TEMPLATES_REQUEST';
export const SEARCH_QUESTIONNAIRE_TEMPLATES_SUCCESS = '@getQuestionnaireTemplet/SEARCH_QUESTIONNAIRE_TEMPLATES_SUCCESS';
export const SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE = '@getQuestionnaireTemplet/SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE';

export const searchQuestionnaireTemplateByType = (questionnaireType = null, searchValue = null) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_TEMPLATES_REQUEST,
    });
    const state = getState()?.getQuestionnaireTemplate;
    let page = getCurrentPageNo(questionnaireType, state);
    const body = getQuestionnaireTemplateListSearchBodyOnType(questionnaireType, searchValue, page);

    try {
      const res = await axios.post('/questionnairetemplate/search', body);
      if (res?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);

        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE,
        });
      } else {
        const response = convertToValueLable(res?.data?.Data?.Rows);
        let payload = getCurrentQuestionnaireTemplateList(questionnaireType, state, page, response, searchValue);

        dispatch({
          type: SEARCH_QUESTIONNAIRE_TEMPLATES_SUCCESS,
          payload,
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE,
      });
    }
  };
};
export const SET_QUESTIONNAIRE_TEMPLATES = '@getQuestionnaireTemplet/SET_QUESTIONNAIRE_TEMPLATES';
export const setQuestionnaireTemplates = (data) => async (dispatch, getState) => {
  const state = getState()?.getQuestionnaireTemplate;
  const payload = getQuestionnaireTemplatesUtils(data, state);

  dispatch({
    type: SET_QUESTIONNAIRE_TEMPLATES,
    payload,
  });
};
