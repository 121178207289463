import {
  SESSION_REQUEST,
  SESSION_SUCCESS,
  SESSION_FAIL,
  DELETE_SESSION,
  SET_SESSION_DETAILS,
  VERIFY_SESSION_FAIL,
  VERIFY_SESSION_REQUEST,
  VERIFY_SESSION_SUCCESS,
} from '../actions/session';
import { setSessionDetails, getIsAuthenticated, removeSession, getSessionDetails } from '../utils/localStorage';

const initialState = {
  loading: false,
  user: {},
  details: getSessionDetails(),
  error: '',
  isAuthenticated: getIsAuthenticated(),
  authError: false,
  currentSessionPayload: '',
};

const setSessionDetailsResult = (state, payload) => {
  return {
    ...state,
    details: payload,
  };
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SESSION_DETAILS:
      return setSessionDetailsResult(state, payload);
    case SESSION_REQUEST: {
      window.localStorage.setItem('sessionInProgress', 'yes');
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
        authError: false,
      };
    }

    case SESSION_SUCCESS: {
      window.localStorage.removeItem('sessionInProgress');
      setSessionDetails(payload);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        details: payload,
      };
    }

    case SESSION_FAIL: {
      window.localStorage.removeItem('sessionInProgress');
      removeSession();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        authError: action?.authError || false,
      };
    }

    case DELETE_SESSION: {
      removeSession();
      return {
        ...initialState,
        user: {},
        loading: false,
        isAuthenticated: false,
      };
    }

    case VERIFY_SESSION_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VERIFY_SESSION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case VERIFY_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentSessionPayload: payload,
      };
    }

    default:
      return state;
  }
}
