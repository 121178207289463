import { DATE_FORMAT } from 'constants/constants';
import {
  GET_OUTREACH_TYPE_REQUEST,
  GET_OUTREACH_TYPE_FAILURE,
  GET_OUTREACH_TYPE_SUCCESS,
  GET_OUTREACH_STATUS_REQUEST,
  GET_OUTREACH_STATUS_FAILURE,
  GET_OUTREACH_STATUS_SUCCESS,
  SEARCH_QUESTIONNAIRE_LIST_LOADING,
  SEARCH_QUESTIONNAIRE_LIST_FAILURE,
  SEARCH_QUESTIONNAIRE_LIST_SUCCESS,
  POST_OUTREACH_TEMPLATE_LOADING,
  POST_OUTREACH_TEMPLATE_FAILURE,
  POST_OUTREACH_TEMPLATE_SUCCESS,
  SEARCH_OUTREACH_LIST_LOADING,
  SEARCH_OUTREACH_LIST_FAILURE,
  SEARCH_OUTREACH_LIST_SUCCESS,
  SEARCH_CLIENT_LIST_LOADING,
  SEARCH_CLIENT_LIST_FAILURE,
  SEARCH_CLIENT_LIST_SUCCESS,
  POST_REQUESTOR_TO_OUTREACH_LOADING,
  POST_REQUESTOR_TO_OUTREACH_FAILURE,
  POST_REQUESTOR_TO_OUTREACH_SUCCESS,
  PUT_OUTREACH_TEMPLATE_LOADING,
  PUT_OUTREACH_TEMPLATE_FAILURE,
  PUT_OUTREACH_TEMPLATE_SUCCESS,
  SEARCH_REQUESTORS_LIST_LOADING,
  SEARCH_REQUESTORS_LIST_FAILURE,
  SEARCH_REQUESTORS_LIST_SUCCESS,
  DELETE_REQUESTOR_FROM_OUTREACH_LOADING,
  DELETE_REQUESTOR_FROM_OUTREACH_FAILURE,
  DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS,
  SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_LOADING,
  SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE,
  SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_SUCCESS,
  DELETE_RECIPIENT_FOR_REQUESTOR_LOADING,
  DELETE_RECIPIENT_FOR_REQUESTOR_FAILURE,
  DELETE_RECIPIENT_FOR_REQUESTOR_SUCCESS,
  POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_LOADING,
  POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE,
  POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_SUCCESS,
  SET_POST_OUTREACH_TEMPLATE_LOADING,
  SET_POST_OUTREACH_TEMPLATE_FAILURE,
  SET_POST_OUTREACH_TEMPLATE_SUCCESS,
  UPDATE_OUTREACH_STATUS_LOADING,
  UPDATE_OUTREACH_STATUS_FAILURE,
  UPDATE_OUTREACH_STATUS_SUCCESS,
  SET_VIEW_LAUNCH_POPUP_LOADING,
  SET_VIEW_LAUNCH_POPUP_FAILURE,
  SET_VIEW_LAUNCH_POPUP_SUCCESS,
  SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_LOADING,
  SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE,
  SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_SUCCESS,
  SEARCH_LAUNCH_CONFIRMATION_LOADING,
  SEARCH_LAUNCH_CONFIRMATION_FAILURE,
  SEARCH_LAUNCH_CONFIRMATION_SUCCESS,
  CLEAR_SAVED_OUTREACH_TEMPLATE,
  CLEAR_SAVED_OUTREACH_RECORD,
  CLEAR_RECIPIENT_LIST_FOR_REQUESTOR,
  POST_LAUNCH_OUTREACH_LOADING,
  POST_LAUNCH_OUTREACH_FAILURE,
  POST_LAUNCH_OUTREACH_SUCCESS,
  DELETE_OUTREACH_LOADING,
  DELETE_OUTREACH_SUCCESS,
  DELETE_OUTREACH_FAILURE,
} from '../actions/outreachBuilder';
import {
  ERROR_MESSAGE,
  SAVE_SUCCESSFULLY_MESSAGE,
  UPDATED_SUCCESSFULLY_MESSAGE,
  UPLOADED_SUCCESSFULLY_MESSAGE,
  STATUS_UPDATED_SUCCESSFULLY_MESSAGE,
  LAUNCHED_SUCCESSFULLY_MESSAGE,
  DELETE_SUCCESSFULLY_MESSAGE,
} from '../components/Common/AlertMessages';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';

const initialState = {
  loading: false,
  uploadLoading: false,
  outreachType: [],
  outreachStatus: [],
  outreachTemplate: {},
  showPostOutreachSuccess: false,
  viewLaunchPopup: false,
  questionnaireList: [],
  outreachList: [],
  clientList: [],
  addedRequestor: {},
  requestorList: [],
  requestorRecipientList: [],
  recipientList: [],
  launchOutreach: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OUTREACH_TYPE_REQUEST:
    case GET_OUTREACH_STATUS_REQUEST:
    case DELETE_OUTREACH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_OUTREACH_LIST_LOADING: {
      return {
        ...state,
        loading: false,
        outreachList: [],
      };
    }

    case POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_LOADING: {
      return {
        ...state,
        loading: true,
        uploadLoading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_LOADING:
    case POST_OUTREACH_TEMPLATE_LOADING:
    case PUT_OUTREACH_TEMPLATE_LOADING:
    case SEARCH_CLIENT_LIST_LOADING:
    case POST_REQUESTOR_TO_OUTREACH_LOADING:
    case SEARCH_REQUESTORS_LIST_LOADING:
    case DELETE_REQUESTOR_FROM_OUTREACH_LOADING:
    case SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_LOADING:
    case DELETE_RECIPIENT_FOR_REQUESTOR_LOADING:
    case SET_POST_OUTREACH_TEMPLATE_LOADING:
    case UPDATE_OUTREACH_STATUS_LOADING:
    case SET_VIEW_LAUNCH_POPUP_LOADING:
    case SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_LOADING:
    case SEARCH_LAUNCH_CONFIRMATION_LOADING:
    case POST_LAUNCH_OUTREACH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OUTREACH_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        outreachType: payload,
      };
    }

    case GET_OUTREACH_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        outreachType: payload,
      };
    }

    case SEARCH_REQUESTORS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        requestorList: [],
      };
    }

    case SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE: {
      return {
        ...state,
        loading: false,
        requestorRecipientList: [],
      };
    }

    case SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE: {
      return {
        ...state,
        loading: false,
        recipientList: [],
      };
    }

    case POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        uploadLoading: false,
      };
    }

    case SEARCH_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        clientList: [],
      };
    }

    case SET_POST_OUTREACH_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_LAUNCH_OUTREACH_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_OUTREACH_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_FAILURE:
    case POST_OUTREACH_TEMPLATE_FAILURE:
    case PUT_OUTREACH_TEMPLATE_FAILURE:
    case POST_REQUESTOR_TO_OUTREACH_FAILURE:
    case DELETE_REQUESTOR_FROM_OUTREACH_FAILURE:
    case DELETE_RECIPIENT_FOR_REQUESTOR_FAILURE:
    case SET_VIEW_LAUNCH_POPUP_FAILURE:
    case SEARCH_LAUNCH_CONFIRMATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_OUTREACH_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        outreachList: [],
      };
    }

    case GET_OUTREACH_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        outreachType: payload?.Data,
      };
    }

    case GET_OUTREACH_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        outreachStatus: payload?.Data.map((item, index) => {
          return {
            ...item,
            disabled: false,
          };
        }),
      };
    }

    case SEARCH_OUTREACH_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        outreachList: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: index,
            LastEdited: convertToLocalDateTime(item.LastUpdated, DATE_FORMAT),
            StartDate: !isNil(item.StartDate) && convertToLocalDateTime(item.StartDate, DATE_FORMAT),
          };
        }),
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireList: payload?.Data?.Rows,
      };
    }

    case POST_OUTREACH_TEMPLATE_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        outreachTemplate: payload?.Data,
        showPostOutreachSuccess: true,
      };
    }
    case PUT_OUTREACH_TEMPLATE_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientList: payload?.Data?.Rows,
      };
    }

    case POST_REQUESTOR_TO_OUTREACH_SUCCESS: {
      return {
        ...state,
        loading: false,
        addedRequestor: payload?.Data,
      };
    }

    case SEARCH_REQUESTORS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        requestorList: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: index,
          };
        }),
      };
    }

    case DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS: {
      return {
        ...state,
        loading: false,
        requestorRecipientList: [],
      };
    }

    case SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        requestorRecipientList: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: item?.OutreachRecipientId,
          };
        }),
      };
    }

    case POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_SUCCESS: {
      toast.success(UPLOADED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        uploadLoading: false,
      };
    }

    case DELETE_RECIPIENT_FOR_REQUESTOR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_POST_OUTREACH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        showPostOutreachSuccess: payload,
      };
    }

    case UPDATE_OUTREACH_STATUS_SUCCESS: {
      toast.success(STATUS_UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_VIEW_LAUNCH_POPUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        viewLaunchPopup: payload,
      };
    }

    case SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        recipientList: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: index,
          };
        }),
      };
    }

    case POST_LAUNCH_OUTREACH_SUCCESS: {
      toast.success(LAUNCHED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        launchOutreach: payload?.Data,
      };
    }

    case SEARCH_LAUNCH_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_SAVED_OUTREACH_TEMPLATE: {
      return {
        ...state,
        outreachTemplate: [],
      };
    }

    case CLEAR_SAVED_OUTREACH_RECORD: {
      return {
        ...state,
        outreachList: [],
      };
    }

    case CLEAR_RECIPIENT_LIST_FOR_REQUESTOR: {
      return {
        ...state,
        requestorRecipientList: [],
      };
    }

    case DELETE_OUTREACH_SUCCESS: {
      toast.success(DELETE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_OUTREACH_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
