import { isEmpty, isNil } from 'lodash';
import { ASSESSMENT_TYPE_CLIENT_INITIATED } from 'pages/VendorProfile/Products/constants';

export const getAssessmentRequestQuestionSearchBody = () => {
  return {
    intent: '',
    fields: ['QuestionText', 'QuestionPlainText', 'AssessmentRequestQuestionResponseType', 'IsRequired', 'ResponseOptionList', 'DisplayOrder'],
    Criterion: [],
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentRequestSearchBody = (searchText) => {
  const criterion = [];
  if (!isNil(searchText)) {
    criterion.push({
      field: 'AssessmentRequestId',
      operator: '=',
      value: searchText,
    });
  }
  return {
    intent: '',
    fields: [
      'AssessmentRequestCategory',
      'AssessmentRequestType',
      'VendorOrgId',
      'VendorOrgName',
      'VendorUrl',
      'VendorDescription',
      'VendorContactId',
      'VendorContactTitle',
      'VendorContactFirstName',
      'VendorContactLastName',
      'VendorContactFullName',
      'VendorContactEmail',
      'VendorContactPhone',
      'VendorState',
      'VendorHeadquarterCountry',
      'StakeholderContactId',
      'StakeholderContactFirstName',
      'StakeholderContactLastName',
      'StakeholderContactFullName',
      'StakeholderContactDepartment',
      'StakeholderContactEmail',
      'StakeholderContactPhone',
      'ProductVendorId',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'ProductCategory',
      'ProductVersion',
      'InternalAssessmentId',
      'InternalClientVendorId',
      'ScopingInformation',
      'SecurityControlInformation',
      'Comment',
      'Domain',
      'CapabilityGroup',
      'AssessmentStatus',
      'ClientOrgId',
      'ClientOrgName',
      'AssessmentRequestQuestionAndResponseList',
      'AssessmentRequestUploadedFile',
      'IsProductMedicalDevice',
      'ExternalTicketId',
      'DynVRASId',
      'DomainNameValidation',
      'IsVerified',
      'IsAssessmentValidated',
      'CCDynVRASId',
      'AssessmentVRASRequestType',
      "CORLClearedStatus",
      "IsCorlCleared",
      "Portal",
      "AssessmentRequestQuestionnaireTemplateList",
      "QTConfigExternalTicketId",
      "AllowSecCert",
      "AcceptedSecCertTypes",
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentRequestReviewCommentsbody = (searchText) => {
  const criterion = [];
  if (!isEmpty(searchText)) {
    criterion.push({
      field: 'AssessmentRequestId',
      operator: '=',
      value: searchText,
    });
  }
  return {
    intent: '',
    fields: ['AssessmentRequestId', 'AssessmentStatus', 'ReviewComment', 'FirstName', 'LastName', 'InsertTimestamp'],
    Criterion: criterion,
    sort: [
      {
        field: 'InsertTimestamp',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
