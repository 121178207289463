import { toast } from 'react-toastify';
import {
  SEARCH_CONTACT_LIST_LOADING,
  SEARCH_CONTACT_LIST_FAILURE,
  SEARCH_CONTACT_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_CONTRACT_LIST_LOADING,
  SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
  SEARCH_VENDOR_CONTRACT_LIST_SUCCESS,
  ADD_VENDOR_CONTRACT_SUCCESS,
  ADD_VENDOR_CONTRACT_FAILURE,
  ADD_VENDOR_CONTRACT_LOADING,
} from 'actions/vendorContract';
import { ERROR_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { isArray, isEmpty, isNil } from 'lodash';
import { NO, YES } from 'pages/ClientProfile/constants';

const initialState = {
  loading: false,
  contactDetails: {},
  list: [],
  vendorContractDetails: {},
};

export default function (state = initialState, action) {
  const { type, payload, isEditable } = action;

  switch (type) {
    case SEARCH_CONTACT_LIST_LOADING:
    case SEARCH_VENDOR_LIST_LOADING:
    case ADD_VENDOR_CONTRACT_LOADING:
    case SEARCH_VENDOR_CONTRACT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        contactDetails: payload?.Data?.Rows[0],
      };
    }

    case SEARCH_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload?.Data?.Rows,
      };
    }

    case SEARCH_VENDOR_CONTRACT_LIST_SUCCESS: {
      let tempData = {};
      tempData = {
        ...payload?.Data,
        IsCORLCustomer: payload?.Data?.IsCORLCustomer ? YES : NO,
      };
      Object.entries(tempData).forEach((item) => {
        const [key, value] = item;
        tempData[`${key}`] = isNil(value) || isArray(value) ? value : value.toString();
      });
      return {
        ...state,
        loading: false,
        vendorContractDetails: tempData,
      };
    }

    case ADD_VENDOR_CONTRACT_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case SEARCH_CONTACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        contactDetails: {},
      };
    }

    case SEARCH_VENDOR_CONTRACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorContractDetails: {},
      };
    }

    case ADD_VENDOR_CONTRACT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
