import { ERROR_MESSAGE, FILE_UPLOADED_SUCCESSFULLY_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { toast } from 'react-toastify';
import {
  SEARCH_VENDOR_INFO_LOADING,
  SEARCH_VENDOR_INFO_SUCCESS,
  SEARCH_VENDOR_INFO_FAILURE,
  POST_CSV_UPLOAD_LOADING,
  POST_CSV_UPLOAD_SUCCESS,
  POST_CSV_UPLOAD_FAILURE,
  SET_FILE_UPLOAD_INFO_LOADING,
  SET_FILE_UPLOAD_INFO_SUCCESS,
  SET_FILE_UPLOAD_INFO_FAILURE,
  GET_COMPANY_INFO_LOADING,
  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_FAILURE,
  ADD_VENDOR_INFO_LOADING,
  ADD_VENDOR_INFO_FAILURE,
  ADD_VENDOR_INFO_SUCCESS,
  SET_VENDOR_TAB_VALUE,
  SEARCH_ALL_VENDORS_INFO_LOADING,
  SEARCH_ALL_VENDORS_INFO_SUCCESS,
  SEARCH_ALL_VENDORS_INFO_FAILURE,
  SEARCH_UNVERIFIED_VENDORS_INFO_LOADING,
  SEARCH_UNVERIFIED_VENDORS_INFO_SUCCESS,
  SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE,
  SET_VENDOR_PAGE_NO,
  SET_VENDOR_ROW_PER_PAGE,
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_UNVERIFIED_VENDOR_LIST_LOADING,
  SEARCH_UNVERIFIED_VENDOR_LIST_SUCCESS,
  SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE,
} from 'actions/vendorInformation'
import { DEFAULT_ROWS_PER_PAGE } from "constants/constants";
import { isEmpty } from "lodash";
import { VENDOR_TAB_OPTIONS } from 'pages/Vendors/constants';

const initialState = {
  vendorInformation: {},
  allVendorsInformation: {},
  unverifiedVendorsInformation: {},
  companyProfile: {},
  numberOfEmployeesRange: [],
  statesList: [],
  orgId: null,
  loading: false,
  showFileUploadSucces: false,
  addVendorSuccess: false,
  vendorTabValue: VENDOR_TAB_OPTIONS.ALL_VENDORS,
  vendorInformationList: {},
  vendorInformationListCount: 0,
  unverifiedVendorInformationList: {},
  unverifiedVendorInformationListCount: 0,
  vendorInformationPageNo: 0,
  vendorInformationRowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

const getModifiedList = (actualList) => {

  let list = actualList;
  if (!isEmpty(list) && list?.length > 0) {
    return list.slice(1);
  }
  return list;
}

export default function vendorInformation(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_INFO_LOADING: {
      return {
        ...state,
        loading: true,
        companyProfile: {},
        orgId: null,
      };
    }

    case GET_COMPANY_INFO_SUCCESS: {
      let { vendorOrgId } = action;
      return {
        ...state,
        loading: false,
        companyProfile: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0],
        orgId: vendorOrgId,
      };
    }

    case GET_COMPANY_INFO_FAILURE: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
        companyProfile: {},
        orgId: null,
      };
    }
    case SEARCH_VENDOR_INFO_LOADING:
    case SEARCH_ALL_VENDORS_INFO_LOADING:
    case SEARCH_UNVERIFIED_VENDORS_INFO_LOADING:
    case ADD_VENDOR_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_INFO_SUCCESS: {
      if (payload.length > 0) {
        payload.forEach((item, index) => {
          payload[index] = { ...payload[index], index };
        });
        return {
          ...state,
          loading: false,
          vendorInformation: payload,
          addVendorSuccess: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          addVendorSuccess: false,
        };
      }
    }

    case SEARCH_ALL_VENDORS_INFO_SUCCESS: {
      if (payload.length > 0) {
        payload.forEach((item, index) => {
          payload[index] = { ...payload[index], index };
        });
        return {
          ...state,
          loading: false,
          allVendorsInformation: payload,
          addVendorSuccess: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          addVendorSuccess: false,
        };
      }
    }

    case SEARCH_UNVERIFIED_VENDORS_INFO_SUCCESS: {
      if (payload.length > 0) {
        payload.forEach((item, index) => {
          payload[index] = { ...payload[index], index };
        });
        return {
          ...state,
          loading: false,
          unverifiedVendorsInformation: payload,
          addVendorSuccess: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          addVendorSuccess: false,
        };
      }
    }

    case SEARCH_VENDOR_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        addVendorSuccess: false,
        vendorInformation: [],
      };
    }

    case SEARCH_ALL_VENDORS_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        addVendorSuccess: false,
        allVendorsInformation: [],
      };
    }

    case SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        addVendorSuccess: false,
        unverifiedVendorsInformation: [],
      };
    }

    case POST_CSV_UPLOAD_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CSV_UPLOAD_SUCCESS: {
      toast.success(FILE_UPLOADED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        showFileUploadSucces: true,
      };
    }
    case POST_CSV_UPLOAD_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_FILE_UPLOAD_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_FILE_UPLOAD_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        showFileUploadSucces: payload,
      };
    }

    case SET_FILE_UPLOAD_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_VENDOR_INFO_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        addVendorSuccess: true,
      };
    }

    case ADD_VENDOR_INFO_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        addVendorSuccess: false,
      };
    }

    case SET_VENDOR_TAB_VALUE: {
      return {
        ...state,
        vendorTabValue: payload || VENDOR_TAB_OPTIONS.ALL_VENDORS,
      };
    }

    case SET_VENDOR_PAGE_NO: {
      return {
        ...state,
        vendorInformationPageNo: payload,
      };
    }

    case SET_VENDOR_ROW_PER_PAGE: {
      return {
        ...state,
        vendorInformationRowsPerPage: payload,
      };
    }

    case SEARCH_VENDOR_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorInformationList: getModifiedList(payload?.Data?.Rows),
        vendorInformationListCount:
          payload?.Data?.Rows?.length > 0 &&
          payload?.Data?.Rows[0]?.TotalCountOfVendors,
          addVendorSuccess: false,
      };
    }
    case SEARCH_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorInformationList: payload,
        vendorInformationListCount: payload,
        addVendorSuccess: false,
      };
    }

    case SEARCH_UNVERIFIED_VENDOR_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_UNVERIFIED_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        unverifiedVendorInformationList: getModifiedList(payload?.Data?.Rows),
        unverifiedVendorInformationListCount:
          payload?.Data?.Rows?.length > 0 &&
          payload?.Data?.Rows[0]?.TotalCountOfVendors,
          addVendorSuccess: false,
      };
    }
    case SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        unverifiedVendorInformationList: payload,
        unverifiedVendorInformationListCount: payload,
        addVendorSuccess: false,
      };
    }

    default:
      return state;
  }
}
