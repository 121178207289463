import { faCalendarDay, faCircleDot, faSquareCheck, faT, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Language, List, Visibility, DateRange } from '@mui/icons-material';

export const GENERAL_SIDEBAR_MENU = 'general';
export const QUESTIONS_SIDEBAR_MENU = 'questions';
export const REVIEW_SIDEBAR_MENU = 'review';
export const REQUIREMENT_SIDEBAR_MENU = 'requirement';
export const REMEDIATION_PATHWAYS_SIDEBAR_MENU = 'remediationpathways';
export const QUESTIONS_AND_EVIDENCE_SIDEBAR_MENU = 'questionsandevidence';

export const QUESTIONNAIRE_TEMPLATE_SIDEBAR_MENUS = [
  { label: 'General', value: GENERAL_SIDEBAR_MENU, icon: Language },
  { label: 'Questions', value: QUESTIONS_SIDEBAR_MENU, icon: List },
  { label: 'Review', value: REVIEW_SIDEBAR_MENU, icon: Visibility },
];

export const QUESTIONNAIRE_TEMPLATE_SIDEBAR_MENUS_FOR_ASSESSMENTS = [
  { label: 'General', value: GENERAL_SIDEBAR_MENU, icon: Language },
  { label: 'Requirements', value: REQUIREMENT_SIDEBAR_MENU, icon: List },
  { label: 'Remediation Pathways', value: REMEDIATION_PATHWAYS_SIDEBAR_MENU, icon: List },
  { label: 'Questions', value: QUESTIONS_AND_EVIDENCE_SIDEBAR_MENU, icon: List },
  { label: 'Review', value: REVIEW_SIDEBAR_MENU, icon: Visibility },
];
export const QUESTIONNAIRE_TEMPLATE_TYPE_FOR_VSQ_TYPE = 4;
export const QUESTIONNAIRE_TEMPLATE_TYPE_FOR_SUPPLEMENTAL_TYPE = 5;

export const QUESTIONNAIRE_TEMPLATE_SUBTYPE_FOR_SUPPLEMENTAL_TYPE = 7;
export const QUESTIONNAIRE_TEMPLATE_SUBTYPE_FOR_VSQ_TYPE = 2;

export const QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT = 3;
export const QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT = 4;
export const QUESTIONNAIRE_TEMPLATE_SUBTYPE = 1;
export const QUESTIONNAIRE_STATUS_LOOKUP_ENTITY_NAME = 'QuestionnaireTemplateStatus';
export const DRAFT_STATUS_ID = 1;
export const DRAFT_STATUS = 'Draft';
export const READY_FOR_QA_STATUS = 'Ready For QA';
export const QA_REJECTED_STATUS = 'QA Rejected';
export const QA_APPROVED_STATUS = 'QA Approved';
export const CLIENT_REJECTED_STATUS = 'Client Rejected';
export const CLIENT_APPROVED_STATUS = 'Client Approved';

export const QUESTIONNAIRE_TEMPLATE_TYPE_LIST = [
  { value: 1, label: 'Scoping', disabled: true },
  { value: 2, label: 'Control', disabled: true },
  { value: 3, label: 'Incident Response', disabled: false },
  { value: 4, label: 'Assessment', disabled: true },
];

export const QUESTIONNAIRE_TEMPLATE_TYPE_LIST_FOR_ASSESSMENT = [
  { value: 1, label: 'Scoping', disabled: true },
  { value: 2, label: 'Control', disabled: true },
  { value: 3, label: 'Incident Response', disabled: true },
  { value: 4, label: 'Assessment', disabled: false },
];

export const ANSWER_TYPES_RESPONSES_FOR = [1, 2];

export const ANSWER_TYPES = [
  {
    value: 1,
    label: 'Single Select',
    icon: faCircleDot,
  },
  {
    value: 2,
    label: 'Multi-Select',
    icon: faSquareCheck,
  },
  {
    value: 3,
    label: 'Date',
    icon: faCalendarDay,
  },
  {
    value: 4,
    label: 'Free Text',
    icon: faT,
  },  
];

export const POSITIVE_RESPONSE_SCORE = 1;
export const NEGATIVE_RESPONSE_SCORE = -1;

export const FREE_TEXT_QUESTION_RESPONSE_TYPE_ID = 4;
export const FILE_UPLOAD_QUESTION_RESPONSE_TYPE_ID = 5;

export const REQUIREMENT_GROUP_LOOKUP_ENTITY_ID = 76;

export const PAGE_INCIDENT = 'incident';
export const PAGE_ASSESSMENT = 'assessment';
export const DEFAULT_REQUIREMENT_VERSION = '1.0.0';

export const VIEW_MODE = 'view';
export const EDIT_MODE = 'edit';

export const UNSAVED_QUESTIONNAIRE_REQUIREMENTS_QUESTION = "This action will not save the data entered. Are you sure?";

export const QUESTION_DELETE_CONFIRMATION_MESSAGE = "Are you sure you want to delete this question?";

export const REQUIREMENT_DELETE_CONFIRMATION_MESSAGE = "This action will remove association between the Questionnaire and the Requirement. Are you sure?";

export const RESPONSE_EVIDENCE_NA = 0;    
export const RESPONSE_EVIDENCE_REQUIRED = 1;  
export const RESPONSE_EVIDENCE_OPTIONAL = 2;  

export const NO_INADEQUACY_REASONS = 'No Inadequacy Reasons Associated to the Requirement';
export const NO_INCREMENTAL_PROGRESS_INDICATORS = 'No Incremental Progress Indicators available';

export const FORCE_REQUIREMENT_UPDATE_CONFIRMATION_YES = 1;
export const FORCE_REQUIREMENT_UPDATE_CONFIRMATION_NO = 2;

export const QUESTION_ASSOCIATED_WITH_REQUIREMENT_ERROR_MESSAGE = "This requirement has question(s) associated with it. Do you wish to keep these question(s) with the updated requirement?";

export const UNSAVED_DETAILS_MESSAGE = 'This action will not save the data entered. Are you sure?';
export const NO_EXPIRATION_DEFAULT_VALUE = 6;
export const OTHER_DEFAULT_VALUE = 5;

export const ALL = 'All';
export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 5;

export const DEFAULT_QUESTION_NUMBER = 1;
export const RESPONSE_SCORE_YES = 1;
export const RESPONSE_SCORE_NO = -1;
export const DEFAULT_RESPONSE_SCORE = 0;
export const IS_DEFAULT = 0;

export const REQUIREMENT_TYPE_VALUE_FOR_CONTROL_BASED_TYPE = 1;
export const REQUIREMENT_TYPE_VALUE_FOR_CORL_CLEARED_TYPE = 2;
export const REQUIREMENT_TYPE_VALUE_FOR_SUPPLEMENTAL_TYPE = 3;

export const DEFAULT_TIER_VALUE_FOR_NOT_APPLICABLE = 6 ;
export const DEFAULT_REQUIREMENT_LIFESPAN_VALUE_FOR_NO_EXPIRATION = 6;
export const DEFAULT_LOOKUP_QUESTION_PAGE_NO = 1;
export const DEFAULT_LOOKUP_QUESTION_ROWS_PER_PAGE = 10;

export const REQUIREMENT_GROUP_VALUE_FOR_SUPPLEMENTAL_TYPE = 2;
export const REQUIREMENT_GROUP_VALUE_FOR_PRIVACY_TYPE = 20;

export const PAGE_ZERO_CONSTANT = 0;
export const ROWS_PER_PAGE_ZERO_CONSTANT = 0;

export const LOOKUP_ADD_QUESTION_CONFIRMATION_MESSAGE = "Proceed with adding the Question?";

export const INCREMENT_MAX_SUPPLIMENT_COUNT = 1;

export const CORL_CLEARED_GROUP_TYPE = 1;
export const SUPPLEMENTAL_INFORMATION_GROUP_TYPE = 2;

export const DISABLE_FIELDS_FOR_NON_DRAFT_STATE = ['questionnaireTemplateTypeId'];

export const SCOPING_QUESTIONNAIRE_TYPE = 1;
export const SECURITY_QUESTIONNAIRE_TYPE = 4;
export const SUPPLEMENTAL_QUESTIONNAIRE_TYPE = 5;
export const QA_APPROVED_QUESTIONNAIRE_TEMPLATE = 23;

export const ALLOWED_QUESTIONNAIRE_TEMPLATE_TYPES = [
  QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT, 
  QUESTIONNAIRE_TEMPLATE_TYPE_FOR_SUPPLEMENTAL_TYPE,
  QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT
];

export const QUESTIONNAIRE_TEMPLATE_SUBTYPE_FOR_SCOPING = 8;

export const ASSESSMENT_QUESTIONNAIRE_TYPE_FOR_VSRA = [
  QUESTIONNAIRE_TEMPLATE_SUBTYPE_FOR_VSQ_TYPE,
  QUESTIONNAIRE_TEMPLATE_SUBTYPE,
  QUESTIONNAIRE_TEMPLATE_SUBTYPE_FOR_SCOPING
];