import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getErrorObject } from 'utils/apiUtils';
import { getVendorByVendorOrgIdSearchBody, getVendorContractListSearchBody, getVendorListSearchBody } from 'utils/vendorContractUtils';
import { setErrors } from './errorHandler';
import { getContactDetailsSearchBody } from 'utils/clientUtil';
import { isNil } from 'lodash';
import { YES } from 'pages/ClientProfile/constants';
import { IS_CORL_CUSTOMER_NO, IS_CORL_CUSTOMER_YES } from 'pages/VendorProfile/ContractConfiguration/constants';

export const SEARCH_CONTACT_LIST_LOADING = '@@vendorContract/SEARCH_CONTACT_LIST_LOADING';
export const SEARCH_CONTACT_LIST_SUCCESS = '@@vendorContract/SEARCH_CONTACT_LIST_SUCCESS';
export const SEARCH_CONTACT_LIST_FAILURE = '@@vendorContract/SEARCH_CONTACT_LIST_FAILURE';

export const getContactDetailsData = (searchText) => async (dispatch) => {
  dispatch({ type: SEARCH_CONTACT_LIST_LOADING });
  try {
    const body = getContactDetailsSearchBody(searchText);
    const response = await axios.post(`/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_CONTACT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_CONTACT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CONTACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_LIST_LOADING = '@@vendorContract/SEARCH_VENDOR_LIST_LOADING';
export const SEARCH_VENDOR_LIST_SUCCESS = '@@vendorContract/SEARCH_VENDOR_LIST_SUCCESS';
export const SEARCH_VENDOR_LIST_FAILURE = '@@vendorContract/SEARCH_VENDOR_LIST_FAILURE';

export const getVendorListData =
  (searchText = '', vendorOrgId) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_VENDOR_LIST_LOADING });
    try {
      let body;
      if (!isNil(vendorOrgId)) {
        body = getVendorByVendorOrgIdSearchBody(vendorOrgId);
      } else {
        body = getVendorListSearchBody(searchText);
      }
      const response = await axios.post(`serviceprovider/vendor/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_VENDOR_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_VENDOR_LIST_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_VENDOR_CONTRACT_LIST_LOADING = '@@vendorContract/SEARCH_VENDOR_CONTRACT_LIST_LOADING';
export const SEARCH_VENDOR_CONTRACT_LIST_SUCCESS = '@@vendorContract/SEARCH_VENDOR_CONTRACT_LIST_SUCCESS';
export const SEARCH_VENDOR_CONTRACT_LIST_FAILURE = '@@vendorContract/SEARCH_VENDOR_CONTRACT_LIST_FAILURE';

export const getVendorContractListData = (id) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_CONTRACT_LIST_LOADING });
  try {
    const body = getVendorContractListSearchBody(id);
    const response = await axios.post(`/vendor/current/contract/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_CONTRACT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_VENDOR_CONTRACT_LOADING = '@@vendorContract/ADD_VENDOR_CONTRACT_LOADING';
export const ADD_VENDOR_CONTRACT_SUCCESS = '@@vendorContract/ADD_VENDOR_CONTRACT_SUCCESS';
export const ADD_VENDOR_CONTRACT_FAILURE = '@@vendorContract/ADD_VENDOR_CONTRACT_FAILURE';

export const addVendorContract = (formData, corlContactId, id, callback) => async (dispatch) => {
  dispatch({
    type: ADD_VENDOR_CONTRACT_LOADING,
  });

  let tempFormData = {
    ...formData,
    VendorOrgId: id,
    ServiceProviderContactId: corlContactId,
    IsCORLCustomer: formData?.IsCORLCustomer === YES ? IS_CORL_CUSTOMER_YES : IS_CORL_CUSTOMER_NO,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/vendor/contract`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_VENDOR_CONTRACT_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_VENDOR_CONTRACT_SUCCESS,
        payload: response?.data,
      });
      if(callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: ADD_VENDOR_CONTRACT_FAILURE,
    });
  }
};
