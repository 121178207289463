export const INITIAL_INDEX = -1;
export const INITIAL_SELECTED_CUSTOMER_INDEX = 0;
export const VENDOR_ROLE = [
  { value: 'Reseller', label: 'Reseller' },
  { value: 'Implementor', label: 'Implementor' },
  { value: 'OEM', label: 'OEM' },
];
export const PRODUCT_TYPE = [
  { value: 'Saas Product', label: 'Saas Product' },
  { value: 'Business to Business', label: 'Business to Business' },
  { value: 'Paas Product', label: 'Paas Product' },
];
export const YES = 'Yes';
export const NO = 'No';
export const PRODUCT_GROUP_NAME_UPPER = 'ProductGroupName';
export const PRODUCT_GROUP_NAME_LOWER = 'productGroupName';

export const EDIT = 'edit';
export const NEW = 'new';

export const ALL_CUSTOMERS = 'All Customers';
export const ALL_PRODUCTS = 'All Products';

export const BREACH_PRIMARY_CONTACT_TYPE_ID = 2;
export const BREACH_ESCALATION_CONTACT_TYPE_ID = 3;
export const VULNERABILITY_PRIMARY_CONTACT_TYPE_ID = 4;
export const VULNERABILITY_ESCALATION_CONTACT_TYPE_ID = 5;

export const BREACH_PRIMARY_CONTACT_TITLE = 'Add Primary Contact For Breach Incidents';
export const BREACH_ESCALATION_CONTACT_TITLE = 'Add Escalation Contact For Breach Incidents';
export const VULNERABILITY_PRIMARY_CONTACT_TITLE = 'Add Primary Contact For Vulnerability Incidents';
export const VULNERABILITY_ESCALATION_CONTACT_TITLE = 'Add Escalation Contact For Vulnerability Incidents';

export const NO_PRODUCTS_AVAILABLE = 'No Products Available';
export const FETCHING_DATA = 'Fetching Data';

export const FORCE_DELETE_CONTACT_API_CONFIRMATION =
  'is an Incident Escalation contact. Deleting this contact will remove them from Incident Escalation as well.';
export const CONFIRMATION_MESSAGE = 'Are you sure you want to delete them?';

export const NO_ROLES_ASSIGNED_MESSAGE = 'No roles are assigned to the user.';
