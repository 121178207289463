import {
  POST_PRODUCT_GROUP_TO_LIST_LOADING,
  POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
  POST_PRODUCT_GROUP_TO_LIST_FAILURE,
  PUT_PRODUCT_GROUP_TO_LIST_LOADING,
  PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
  PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
  DELETE_PRODUCT_GROUP_FROM_LIST_LOADING,
  DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
  POST_PRODUCT_TO_LIST_LOADING,
  POST_PRODUCT_TO_LIST_SUCCESS,
  POST_PRODUCT_TO_LIST_FAILURE,
  PUT_PRODUCT_TO_LIST_LOADING,
  PUT_PRODUCT_TO_LIST_SUCCESS,
  PUT_PRODUCT_TO_LIST_FAILURE,
  DELETE_PRODUCT_FROM_LIST_LOADING,
  DELETE_PRODUCT_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_FROM_LIST_FAILURE,
  SEARCH_PRODUCT_GROUP_LIST_LOADING,
  SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
  SEARCH_PRODUCT_GROUP_LIST_FAILURE,
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  SET_VENDORS_PRODUCTS_TAB_VALUE,
  SET_PRODUCT_DELETE_ERRORS,
  SET_PRODUCTS_GROUP_FORCE_DELETE,
  SEARCH_SELECTED_PRODUCT_DETAILS_LOADING,
  SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS,
  SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
  ADD_PRODUCT_COMPONENT_LOADING,
  ADD_PRODUCT_COMPONENT_SUCCESS,
  ADD_PRODUCT_COMPONENT_FAILURE,
  SEARCH_PRODUCT_COMPONENT_LOADING,
  SEARCH_PRODUCT_COMPONENT_SUCCESS,
  SEARCH_PRODUCT_COMPONENT_FAILURE,
  UPDATE_PRODUCT_COMPONENT_LOADING,
  UPDATE_PRODUCT_COMPONENT_SUCCESS,
  UPDATE_PRODUCT_COMPONENT_FAILURE,
  DELETE_PRODUCT_COMPONENT_LOADING,
  DELETE_PRODUCT_COMPONENT_SUCCESS,
  DELETE_PRODUCT_COMPONENT_FAILURE,
  ADD_CORL_CLEARED_ASSESSMENT_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
  ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_LOADING,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_SUCCESS,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE,
  ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING,
  ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS,
  ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
  UPDATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING,
  UPDATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS,
  UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_LOADING,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_SUCCESS,
  SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE,
  UPDATE_CORL_CLEARED_STATUS_LOADING,
  UPDATE_CORL_CLEARED_STATUS_SUCCESS,
  UPDATE_CORL_CLEARED_STATUS_FAILURE,
  SET_EVIDENCE_REQUIREMENT_EXPANDED_ACCORDIAN,
  SET_PRODUCT_COMPONENT_REQUIREMENT_LIST,
  SET_LOCK_PRODUCT_COMPONENT_ACCORDION,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
  SET_SELECTED_VENDOR_DETAILS,
  VENDOR_PROVIDED_SCOPE_INFO_LOADING,
  VENDOR_PROVIDED_SCOPE_INFO_SUCCESS,
  VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
  VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING,
  VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS,
  VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
  VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING,
  VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS,
  VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS,
  GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
  SEARCH_REQUIREMENT_INADEQUACY_REASON_LOADING,
  SEARCH_REQUIREMENT_INADEQUACY_REASON_SUCCESS,
  SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE,
  POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING,
  POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS,
  POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE,
  UPDATE_ASSESSMENT_SUMMARY_LOADING,
  UPDATE_ASSESSMENT_SUMMARY_SUCCESS,
  UPDATE_ASSESSMENT_SUMMARY_FAILURE,
  SEARCH_ASSESSMENT_SUMMARY_LOADING,
  SEARCH_ASSESSMENT_SUMMARY_SUCCESS,
  SEARCH_ASSESSMENT_SUMMARY_FAILURE,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
  PUBLISH_ASSESSMENT_SUMMARY_LOADING,
  PUBLISH_ASSESSMENT_SUMMARY_SUCCESS,
  PUBLISH_ASSESSMENT_SUMMARY_FAILURE,
  SET_PRODUCT_PREFERENCE_QUESTION_INDEX,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
  SET_PRODUCT_PREFERENCE_QUESTION_LIST,
  UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING,
  UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS,
  UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
  SET_PRODUCT_PREFERENCE_STATUS_ID,
  SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS,
  SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING,
  SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
  SET_PRODUCT_INFORMATION_QUESTION_INDEX,
  SET_PRODUCT_INFORMATION_QUESTION_LIST,
  GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS,
  GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING,
  GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
  SET_PRODUCT_INFO_REVIEW,
  SEARCH_VENDOR_PRODUCT_LOADING,
  SEARCH_VENDOR_PRODUCT_SUCCESS,
  SEARCH_VENDOR_PRODUCT_FAILURE,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS,
  SET_PRODUCT_PREFERENCE_REVIEW,
  POST_ASSESSMENT_STATUS_LOADING,
  POST_ASSESSMENT_STATUS_SUCCESS,
  POST_ASSESSMENT_STATUS_FAILURE,
  POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING,
  POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS,
  POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
  POST_CONFIRM_AUDITOR_MILESTONE_LOADING,
  POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS,
  POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
  SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE
} from '../actions/vendorProduct';
import {
  DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  ERROR_MESSAGE,
  PRODUCT_GROUP_ADDED_SUCCESSFULLY,
  PRODUCT_GROUP_DELETED_SUCCESSFULLY,
  PRODUCT_GROUP_UPDATED_SUCCESSFULLY,
  PRODUCT_PREFERENCES_SET_SUCCESSFULLY,
  PRODUCT_PROFILE_WIZARD_TAB_OPTIONS,
  VENDORS_PRODUCTS_TAB_OPTIONS,
} from 'pages/VendorProfile/Products/constants';
import { toast } from 'react-toastify';
import { PRODUCT_ADDED_SUCCESSFULLY, PRODUCT_DELETED_SUCCESSFULLY, PRODUCT_UPDATED_SUCCESSFULLY } from 'constants/errorMessage';
import { getSelectOptionsList } from 'utils/arrayUtils';
import {
  ASSOCIATION_SUCCESSFUL_MESSAGE,
  AUDITOR_REVEIW_DECLINED_SUCCESSFULLY_MESSAGE,
  AUDITOR_REVIEW_CONFIRMED_SUCCESSFULLY_MESSAGE,
  CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY,
  CORL_CLEARED_ASSESSMENT_REQUEST_ADDED_SUCCESSFULLY,
  EVIDENCE_REVIEW_UPDATED_SUCCESSFULLY,
  PRODUCT_COMPONENT_ADDED_SUCCESSFULLY,
  PRODUCT_COMPONENT_DELETED_SUCCESSFULLY,
  PRODUCT_COMPONENT_UPDATED_SUCCESSFULLY,
  SAVE_SUCCESSFULLY_MESSAGE,
  UPDATED_SUCCESSFULLY_MESSAGE,
} from 'components/Common/AlertMessages';
import { convertToLocalTime } from 'utils/dateAndTimeUtils';
import { isEmpty, isNil } from "lodash";
import { NO, YES, ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, PUBLISHED_SUCCESSFULLY } from 'constants/constants';
import { ADEQUATE, AUDITOR_DECLINE, INADEQUATE } from 'pages/VendorProfile/Products/ProductDetails/EvidenceReview/constant';
import { ZERO } from 'constants/numbers';

const getEvidenceDisplayName = (evidenceList) => {
  let evidenceNameList = [];
  evidenceList?.map((item, index) => {
    evidenceNameList.push(item?.FileName);
  })

  return evidenceNameList.join(" | ");
}

const getEvidenceDocumentUrl = (evidenceList, requirementId, evidenceId, url) => {
  let tempData = evidenceList?.map(item => {
    if (item?.RequirementId === requirementId) {
      let tempRequirement = {
        ...item,
        ResponseAttachmentList: item?.ResponseAttachmentList?.map(it => {
          if (it?.EvidenceId === evidenceId) {
            return {
              ...it,
              DownloadUrl: r,
            }
          } else {
            return it;
          }
        }),
      }
      return tempRequirement;
    } else {
      return item;
    }
  });

  return tempData;
}

const initialState = {
  loading: false,
  productGroupNames: {},
  productGroupList: [],
  productsList: [],
  deleteProductGroup: false,
  vendorProductsTabValue: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  autoCompleteProductGroup: [],
  vendorProductDeleteError: [],
  productComponents: [],
  selectedProductDetails: {},
  productComponentRequirementList: [],
  productComponentRequirementDetail: {},
  selectedVendorDetails: {},
  productComponentExpandedAccordian: null,
  lockProductComponentAccordion: false,
  vendorOrgId: null,
  forceDelete: false,
  vendorProvidedScopeInfo: {},
  vendorProductAssessmentRequirementList: [],
  vendorProductAssessmentRequirementDetailsList: [],
  requirementInadequacyReasonList: [],
  assessmentSummary: {},
  productPreferences: [],
  productPreferenceQuestionIndex: DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  productPreferenceQuestionList: [],
  productPreferenceStatusId: null,
  productInformations: [],
  productInformationQuestionIndex: DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  productInformationQuestionList: [],
  productInformationStatusId: null,
  isProductInformationReview: false,
  isProductPreferenceReview: false,
  productProfileWizardTabValue: PRODUCT_PROFILE_WIZARD_TAB_OPTIONS.PRODUCT_CATALOG_PREFERENCES
};

export function vendorProductsPage(state = initialState, action) {
  const { type, payload, RequirementId, EvidenceId, ProductPreferenceQuestionId } = action;

  switch (type) {
    case SEARCH_PRODUCT_GROUP_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCT_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productGroupList: payload,
        autoCompleteProductGroup: getSelectOptionsList(payload, 'ProductGroupName', 'ProductGroupId'),
      };
    }
    case SEARCH_PRODUCT_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productGroupList: [],
        autoCompleteProductGroup: []
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        productGroup: payload,
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        forceDelete: false,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE: {
      if (!payload) {
        toast.error(ERROR_MESSAGE);
      }
      return {
        ...state,
        loading: false,
        forceDelete: payload,
      };
    }
    case SEARCH_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_LIST_SUCCESS: {
      const { vendorOrgId } = action;
      let tempData = [];
      payload.map((item) => {
        if (
          !isNil(item?.ProductGroupId) &&
          item?.ProductGroupId > 0 &&
          !isNil(item?.ProductGroupName)
        ) {
          tempData.push({
            ...item,
            ProductGroup: {
              label: item?.ProductGroupName,
              value: item?.ProductGroupId,
            },
          });
        } else {
          tempData.push({ ...item, ProductGroup: "" });
        }
      });
      return {
        ...state,
        loading: false,
        productsList: tempData,
        vendorOrgId: vendorOrgId,
      };
    }

    case SEARCH_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productsList: [],
      };
    }

    case POST_PRODUCT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_PRODUCT_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_PRODUCT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_PRODUCT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUT_PRODUCT_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_PRODUCT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_SUCCESS: {
      toast.success(PRODUCT_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_VENDORS_PRODUCTS_TAB_VALUE: {
      return {
        ...state,
        vendorProductsTabValue: payload || VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
      };
    }
    case SET_PRODUCT_DELETE_ERRORS: {
      return {
        ...state,
        vendorProductDeleteError: payload,
      };
    }
    case SET_PRODUCTS_GROUP_FORCE_DELETE: {
      return {
        ...state,
        forceDelete: payload,
      };
    }
    case SEARCH_SELECTED_PRODUCT_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedProductDetails: payload,
      };
    }
    case SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedProductDetails: {},
      };
    }
    case ADD_PRODUCT_COMPONENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_PRODUCT_COMPONENT_SUCCESS: {
      toast.success(PRODUCT_COMPONENT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_PRODUCT_COMPONENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_PRODUCT_COMPONENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PRODUCT_COMPONENT_SUCCESS: {
      toast.success(PRODUCT_COMPONENT_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_PRODUCT_COMPONENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_COMPONENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRODUCT_COMPONENT_SUCCESS: {
      toast.success(PRODUCT_COMPONENT_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_COMPONENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponents: payload,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_FAILURE: {
      return {
        ...state,
        loading: false,
        productComponents: [],
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_SUCCESS: {
      toast.success(CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponentRequirementList: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: index + 1,
          }
        }),
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        productComponentRequirementList: []
      };
    }
    case ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS: {
      toast.success(ASSOCIATION_SUCCESSFUL_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case ASSOCIATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_PRODUCT_COMPONENT_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_PRODUCT_COMPONENT_REQUIREMENT_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_PRODUCT_COMPONENT_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponentRequirementDetail: {
          ...payload?.Data?.Rows[0],
          ComponentRequirementEvidenceList: (payload?.Data?.Rows[0]?.ComponentRequirementEvidenceList || []).map((item, index) => {
            return {
              ...item,
              id: index + 1,
              EvidenceDate: convertToLocalTime(item.EvidenceDate),
            };
          }),
        }
      };
    }
    case SEARCH_PRODUCT_COMPONENT_REQUIREMENT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        productComponentRequirementDetail: {},
      };
    }
    case SET_EVIDENCE_REQUIREMENT_EXPANDED_ACCORDIAN: {
      return {
        ...state,
        productComponentExpandedAccordian: payload
      };
    }
    case UPDATE_CORL_CLEARED_STATUS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_CORL_CLEARED_STATUS_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_CORL_CLEARED_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_PRODUCT_COMPONENT_REQUIREMENT_LIST: {
      return {
        ...state,
        productComponentRequirementList: payload
      };
    }
    case SET_LOCK_PRODUCT_COMPONENT_ACCORDION: {
      return {
        ...state,
        lockProductComponentAccordion: payload
      }
    }
    case SET_SELECTED_VENDOR_DETAILS: {
      return {
        ...state,
        selectedVendorDetails: payload
      }
    }
    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_SUCCESS: {
      let data = {};
      if (payload?.Data?.Rows?.length > 0) {
        const tempData = payload?.Data?.Rows[0];
        data = {
          ...tempData,
          ProductComponentTypeName: tempData?.ProductComponentTypeName?.replace(/,/g, " | "),
          ProductComponentDataLocationName: tempData?.ProductComponentDataLocationName?.split(",")
        }
      }
      return {
        ...state,
        loading: false,
        vendorProvidedScopeInfo: data
      };
    }

    case VENDOR_PROVIDED_SCOPE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorProvidedScopeInfo: {}
      };
    }

    case VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorProductAssessmentRequirementList: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            DisplayControlName: item?.RequirementNumber + " " + item?.RequirementName,
            IsAdequate: item?.AuditorAdequacyName === ADEQUATE ? true : item?.AuditorAdequacyName === INADEQUATE ? false : null,
            AdequacyId: item?.AuditorAdequacyId,
            AdequacyName: item?.AuditorAdequacyName,
          }
        }),
      };
    }

    case VENDOR_PRODUCT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorProductAssessmentRequirementList: []
      };
    }

    case VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS: {
      let requirementDetails = payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows[0] : {};
      requirementDetails = {
        ...requirementDetails,
        ResponseAttachmentList: (requirementDetails?.ResponseAttachmentList || [])?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
          };
        }),
        AssessmentQuestionnaireQuestionResponseList: (requirementDetails?.AssessmentQuestionnaireQuestionResponseList || [])?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            EvidenceDisplayName: item?.ResponseAttachmentList?.length > 0 ? getEvidenceDisplayName(item?.ResponseAttachmentList) : "",
            isPositiveResponse: item?.PositiveResponseScore ? true : item?.NegativeResponseScore ? false : null,
          };
        }),
      }
      let filteredRequirements = state?.vendorProductAssessmentRequirementDetailsList?.filter(item => item?.RequirementId !== RequirementId);
      let tempData = [...filteredRequirements, requirementDetails];
      return {
        ...state,
        loading: false,
        vendorProductAssessmentRequirementDetailsList: tempData,
      }
    }

    case VENDOR_PRODUCT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE: {
      let tempData = state?.vendorProductAssessmentRequirementDetailsList?.filter(item => item?.RequirementId !== RequirementId);
      return {
        ...state,
        loading: false,
        vendorProductAssessmentRequirementDetailsList: tempData,
      }
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      let tempData = state?.vendorProductAssessmentRequirementDetailsList?.map(item => {
        if (item?.RequirementId === RequirementId) {
          let tempRequirement = {
            ...item,
            ResponseAttachmentList: item?.ResponseAttachmentList?.map(it => {
              if (it?.EvidenceId === EvidenceId) {
                return {
                  ...it,
                  DownloadUrl: downloadUrl,
                }
              } else {
                return it;
              }
            }),
          }
          return tempRequirement;
        } else {
          return item;
        }
      });
      return {
        ...state,
        urlLoading: false,
        vendorProductAssessmentRequirementDetailsList: tempData,
      };
    }

    case GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE: {
      const downloadUrl = "";
      let tempData = state?.vendorProductAssessmentRequirementDetailsList?.map(item => {
        if (item?.RequirementId === RequirementId) {
          let tempRequirement = {
            ...item,
            ResponseAttachmentList: item?.ResponseAttachmentList?.map(it => {
              if (it?.EvidenceId === EvidenceId) {
                return {
                  ...it,
                  DownloadUrl: downloadUrl,
                }
              } else {
                return it;
              }
            }),
          }
          return tempRequirement;
        } else {
          return item;
        }
      });
      return {
        ...state,
        urlLoading: false,
        vendorProductAssessmentRequirementDetailsList: tempData,
      };
    }

    case SEARCH_REQUIREMENT_INADEQUACY_REASON_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_REQUIREMENT_INADEQUACY_REASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        requirementInadequacyReasonList: payload?.Data?.Rows?.length > 0 ? getSelectOptionsList(payload?.Data?.Rows, "Name", "InadequacyReasonId") : [],
      };
    }

    case SEARCH_REQUIREMENT_INADEQUACY_REASON_FAILURE: {
      return {
        ...state,
        loading: false,
        requirementInadequacyReasonList: []
      };
    }

    case POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS: {
      toast.success(EVIDENCE_REVIEW_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ASSESSMENT_SUMMARY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_ASSESSMENT_SUMMARY_SUCCESS: {
      const { assessmentSummaryId } = action;
      assessmentSummaryId === ZERO ? toast.success(ADDED_SUCCESSFULLY) : toast.success(UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ASSESSMENT_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case PUBLISH_ASSESSMENT_SUMMARY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUBLISH_ASSESSMENT_SUMMARY_SUCCESS: {
      toast.success(PUBLISHED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case PUBLISH_ASSESSMENT_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }


    case SEARCH_ASSESSMENT_SUMMARY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentSummary: payload,
      };
    }

    case SEARCH_ASSESSMENT_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentSummary: {},
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING: {
      return {
        ...state,
        loadingProductPreferences: true,
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS: {
      const { currentIndex, isReview } = action;
      const firstUnAnsweredQuestionIndex = payload?.findIndex(item => item?.QuestionResponseText === null || isEmpty(item?.QuestionResponseText));
      const firstUnAnsweredQuestion = payload?.filter(item => isEmpty(item?.QuestionResponseText));
      let resultIndex = null;
      if (isReview) {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      } else if (!isNil(currentIndex)) {
        resultIndex = currentIndex + 1;
      } else {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      }

      return {
        ...state,
        loadingProductPreferences: false,
        productPreferences: payload?.length > 0 ? payload?.map((item, index) => {
          return {
            ...item,
            index: index,
          }
        }) : [],
        productPreferenceQuestionIndex: resultIndex,
        productPreferenceStatusId: payload?.length > 0 ? payload[0]?.StatusId : null,
        firstUnAnsweredPreferenceQuestion: firstUnAnsweredQuestion,
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE: {
      return {
        ...state,
        loadingProductPreferences: false,
        productPreferences: [],
        productPreferenceStatusId: null,
      };
    }

    case SET_PRODUCT_PREFERENCE_QUESTION_INDEX: {
      return {
        ...state,
        productPreferenceQuestionIndex: payload,
      };
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      const productPreferenceQuestion = state?.productPreferenceQuestionList?.find(item => item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId);
      productPreferenceQuestion["QuestionImageUrl"] = downloadUrl;
      let tempData = state?.productPreferenceQuestionList?.map(item => {
        if (item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId) {
          return productPreferenceQuestion;
        } else {
          return item;
        }
      });
      return {
        ...state,
        urlLoading: false,
        productPreferenceQuestionList: tempData,
      }
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE: {
      return {
        ...state,
        urlLoading: false,
      }
    }

    case SET_PRODUCT_PREFERENCE_QUESTION_LIST: {
      return {
        ...state,
        productPreferenceQuestionList: payload,
      }
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING: {
      return {
        ...state,
        loadingProductPreferences: true,
      };
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS: {
      const { productInformation } = action;

      toast.success(!productInformation ? PRODUCT_PREFERENCES_SET_SUCCESSFULLY : PRODUCT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loadingProductPreferences: false,
      };
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingProductPreferences: false,
      };
    }

    case SET_PRODUCT_PREFERENCE_STATUS_ID: {
      return {
        ...state,
        productPreferenceStatusId: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING: {
      return {
        ...state,
        loadingProductInformations: true,
      };
    }

    case SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS: {
      const { currentIndex, isReview } = action;
      const firstUnAnsweredQuestionIndex = payload?.findIndex(item => item?.QuestionResponseText === null || isEmpty(item?.QuestionResponseText));
      const firstUnAnsweredQuestion = payload?.filter(item => isEmpty(item?.QuestionResponseText));
      let resultIndex = null;
      if (isReview) {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      } else if (!isNil(currentIndex)) {
        resultIndex = currentIndex + 1;
      } else {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      }

      return {
        ...state,
        loadingProductInformations: false,
        productInformations: payload?.length > 0 ? payload?.map((item, index) => {
          return {
            ...item,
            index: index,
          }
        }) : [],
        productInformationQuestionIndex: resultIndex,
        productInformationStatusId: payload?.length > 0 ? payload[0]?.StatusId : null,
        firstUnAnsweredQuestion: firstUnAnsweredQuestion,
      };
    }

    case SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE: {
      return {
        ...state,
        loadingProductInformations: false,
        productInformations: [],
        productInformationStatusId: null,
      };
    }

    case SET_PRODUCT_INFORMATION_QUESTION_INDEX: {
      return {
        ...state,
        productInformationQuestionIndex: payload,
      };
    }

    case SET_PRODUCT_INFORMATION_QUESTION_LIST: {
      return {
        ...state,
        productInformationQuestionList: payload,
      }
    }

    case GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      const productInformationQuestion = state?.productInformationQuestionList?.find(item => item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId);
      productInformationQuestion["QuestionImageUrl"] = downloadUrl;

      let tempData = state?.productInformationQuestionList?.map(item => {
        if (item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId) {
          return productInformationQuestion;
        } else {
          return item;
        }
      });

      return {
        ...state,
        urlLoading: false,
        productInformationQuestionList: tempData,
      }
    }
    case GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE: {
      return {
        ...state,
        urlLoading: false,
      }
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING: {
      return {
        ...state,
        loadingProductInformation: true,
      };
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS: {
      return {
        ...state,
        loadingProductInformation: false,
      };
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingProductInformation: false,
      };
    }

    case SET_PRODUCT_INFO_REVIEW: {
      return {
        ...state,
        isProductInformationReview: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_PRODUCT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING: {
      return {
        ...state,
        loadingProductPreference: true,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS: {
      return {
        ...state,
        loadingProductPreference: false,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingProductPreference: false,
      };
    }

    case SET_PRODUCT_PREFERENCE_REVIEW: {
      return {
        ...state,
        isProductPreferenceReview: payload,
      };
    }

    case POST_ASSESSMENT_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_ASSESSMENT_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_ASSESSMENT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS: {
      const { auditorStatus } = action;
      toast.success(auditorStatus === AUDITOR_DECLINE ? AUDITOR_REVEIW_DECLINED_SUCCESSFULLY_MESSAGE : AUDITOR_REVIEW_CONFIRMED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
      };
    }

    case POST_CONFIRM_AUDITOR_MILESTONE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE: {
      return {
        ...state,
        productProfileWizardTabValue: payload,
      };
    }

    default:
      return state;
  }
}
