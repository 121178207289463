import { DELETE_SUCCESSFULLY_MESSAGE, ERROR_MESSAGE } from 'components/Common/AlertMessages';
import {
  GET_QUESTIONNAIRE_LIST_DATA_REQUEST,
  GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
  GET_QUESTIONNAIRE_LIST_DATA_SUCCESS,
  DELETE_QUESTIONNAIRE_LOADING,
  DELETE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTIONNAIRE_FAILURE,
} from '../actions/questionnaireTemplate';

import { isNil } from 'lodash';
import { toast } from 'react-toastify';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { DATE_FORMAT } from 'constants/constants';

const initialState = {
  loading: false,
  generalInformation: {},
  list: [],
  questionsList: [],
  selectedQuestionIndex: null,
  lookupList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_QUESTIONNAIRE_LIST_DATA_REQUEST:
    case DELETE_QUESTIONNAIRE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_QUESTIONNAIRE_LIST_DATA_SUCCESS: {
      let questionniaresList = payload.sort((firstItem, lastItem) => new Date(lastItem.LastUpdated) - new Date(firstItem.LastUpdated));
      return {
        ...state,
        loading: false,
        list: questionniaresList.map((item, index) => {
          return {
            ...item,
            id: index,
            LastEdited: !isNil(item.LastUpdated) && convertToLocalDateTime(item.LastUpdated, DATE_FORMAT),
          };
        }),
        lookupList: getSelectOptionsList(payload, 'QuestionnaireTemplateName', 'QuestionnaireTemplateId')
      };
    }

    case GET_QUESTIONNAIRE_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case DELETE_QUESTIONNAIRE_SUCCESS: {
      toast.success(DELETE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_QUESTIONNAIRE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
