import {
  CLEAR_COMMENTS,
  SEARCH_COMMENTS_FAILURE,
  SEARCH_COMMENTS_LOADING,
  SEARCH_COMMENTS_SUCCESS,
  SET_COMMENTS_FAILURE,
  SET_COMMENTS_LOADING,
  SET_COMMENTS_SUCCESS,
} from 'actions/comments';

const initialState = {
  loading: false,
  list: [],
  showComments: false,
};

export default function comments(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_COMMENTS_LOADING:
    case SEARCH_COMMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_COMMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload,
        showComments: true,
      };
    }
    case SEARCH_COMMENTS_SUCCESS:
    case SET_COMMENTS_FAILURE:
    case SEARCH_COMMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        showComments: true,
      };
    }
    case CLEAR_COMMENTS: {
      return {
        ...state,
        list: [],
        showComments: false,
      };
    }

    default:
      return state;
  }
}
