import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { CORL_APP_ID, CORL_USER_ROLE, USER_ID } from 'constants/users';
import { isEmpty } from 'lodash';
import { getErrorObject } from 'utils/apiUtils';
import { searchCorlUsersBody, searchUserRoleListBody, searchUserRoleListOfUserBody } from 'utils/corlUsersUtils';
import { setErrors } from './errorHandler';

export const SEARCH_CORL_USERS_LOADING = '@@corlUsers/SEARCH_CORL_USERS_LOADING';
export const SEARCH_CORL_USERS_SUCCESS = '@@corlUsers/SEARCH_CORL_USERS_SUCCESS';
export const SEARCH_CORL_USERS_FAILURE = '@@corlUsers/SEARCH_CORL_USERS_FAILURE';

export const searchCorlUsers = (searchValue) => async (dispatch) => {
  dispatch({
    type: SEARCH_CORL_USERS_LOADING,
  });

  const body = searchCorlUsersBody(searchValue);

  try {
    const response = await axios.post(`/app/user/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_CORL_USERS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_CORL_USERS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: SEARCH_CORL_USERS_FAILURE,
    });
  }
};

export const POST_CORL_USER_TO_LIST_LOADING = '@@corlUsers/POST_CORL_USER_TO_LIST_LOADING';
export const POST_CORL_USER_TO_LIST_SUCCESS = '@@corlUsers/POST_CORL_USER_TO_LIST_SUCCESS';
export const POST_CORL_USER_TO_LIST_FAILURE = '@@corlUsers/POST_CORL_USER_TO_LIST_FAILURE';

export const addCorlUsersToList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_CORL_USER_TO_LIST_LOADING,
  });
  let updatedFormData = {
    ...formData,
    MiddleName: !isEmpty(formData?.MiddleName) ? formData?.MiddleName : '',
    Roles: CORL_USER_ROLE,
    AppId: CORL_APP_ID,
    UserID: USER_ID,
    SendEmail: 'true',
  };
  const body = JSON.stringify(updatedFormData);
  try {
    const response = await axios.post(`/app/user`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_CORL_USER_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CORL_USER_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchCorlUsers());
      if (callback) callback(response?.data?.Data);
    }
  } catch {
    dispatch({
      type: POST_CORL_USER_TO_LIST_FAILURE,
    });
  }
};

export const PUT_CORL_USER_TO_LIST_LOADING = '@@corlUsers/PUT_CORL_USER_TO_LIST_LOADING';
export const PUT_CORL_USER_TO_LIST_SUCCESS = '@@corlUsers/PUT_CORL_USER_TO_LIST_SUCCESS';
export const PUT_CORL_USER_TO_LIST_FAILURE = '@@corlUsers/PUT_CORL_USER_TO_LIST_FAILURE';

export const updateCorlUsersToList = (formData) => async (dispatch) => {
  dispatch({
    type: PUT_CORL_USER_TO_LIST_LOADING,
  });

  let updatedFormData = {
    UserId: formData.editedFormData.UserId,
    UserEmail: formData.editedFormData.UserEmail,
    FirstName: formData.editedFormData.FirstName,
    MiddleName: !isEmpty(formData.editedFormData.MiddleName) ? formData.editedFormData.MiddleName : '',
    LastName: formData.editedFormData.LastName,
    NamePrefix: formData.editedFormData.NamePrefix,
    Roles: CORL_USER_ROLE,
    AppId: CORL_APP_ID,
  };

  const body = JSON.stringify(updatedFormData);
  try {
    const response = await axios.put(`/app/user/${formData.editedFormData.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_CORL_USER_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_CORL_USER_TO_LIST_SUCCESS,
        payload: {
          response: response?.data?.Data,
          formData,
        },
      });
    }

    dispatch(
      setCorlUser({
        selectedUserIndex: formData?.itemToBeUpdatedIndex + 1,
        actions: null,
        selectedCorlUserID: formData.editedFormData.UserId,
      })
    );
  } catch {
    dispatch({
      type: PUT_CORL_USER_TO_LIST_FAILURE,
    });
  }
};
export const SET_CORL_USERS_LOADING = '@@corlUsers/SET_CORL_USERS_LOADING';
export const SET_CORL_USERS_SUCCESS = '@@corlUsers/SET_CORL_USERS_SUCCESS';
export const SET_CORL_USERS_FAILURE = '@@corlUsers/SET_CORL_USERS_FAILURE';

export const setCorlUser = (payload) => async (dispatch) => {
  dispatch({
    type: SET_CORL_USERS_LOADING,
  });
  try {
    dispatch({
      type: SET_CORL_USERS_SUCCESS,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SET_CORL_USERS_FAILURE,
    });
  }
};

export const SEARCH_USER_ROLE_LIST_LOADING = '@@corlUsers/SEARCH_USER_ROLE_LIST_LOADING';
export const SEARCH_USER_ROLE_LIST_SUCCESS = '@@corlUsers/SEARCH_USER_ROLE_LIST_SUCCESS';
export const SEARCH_USER_ROLE_LIST_FAILURE = '@@corlUsers/SEARCH_USER_ROLE_LIST_FAILURE';

export const searchUserRoleList =
  (searchText = '') =>
  async (dispatch) => {
    dispatch({ type: SEARCH_USER_ROLE_LIST_LOADING });

    const body = searchUserRoleListBody(searchText);

    try {
      let res = await axios.post(`/userrole/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
        dispatch({
          type: SEARCH_USER_ROLE_LIST_FAILURE,
          payload: [],
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_USER_ROLE_LIST_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_USER_ROLE_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_USER_ROLE_LIST_OF_USER_LOADING = '@@corlUsers/SEARCH_USER_ROLE_LIST_OF_USER_LOADING';
export const SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS = '@@corlUsers/SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS';
export const SEARCH_USER_ROLE_LIST_OF_USER_FAILURE = '@@corlUsers/SEARCH_USER_ROLE_LIST_OF_USER_FAILURE';

export const searchUserRoleListOfUser = (userId) => async (dispatch) => {
  dispatch({ type: SEARCH_USER_ROLE_LIST_OF_USER_LOADING });

  const body = searchUserRoleListOfUserBody(userId);

  try {
    let res = await axios.post(`/userrole/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_USER_ROLE_TO_USER_LOADING = '@@corlUsers/POST_USER_ROLE_TO_USER_LOADING';
export const POST_USER_ROLE_TO_USER_SUCCESS = '@@corlUsers/POST_USER_ROLE_TO_USER_SUCCESS';
export const POST_USER_ROLE_TO_USER_FAILURE = '@@corlUsers/POST_USER_ROLE_TO_USER_FAILURE';

export const addUserRoleToUser = (userRoleId, reqBody, callback) => async (dispatch) => {
  dispatch({ type: POST_USER_ROLE_TO_USER_LOADING });

  const body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/userrole/${userRoleId}/user/association`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_USER_ROLE_TO_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_USER_ROLE_TO_USER_SUCCESS,
        payload: res?.data,
      });
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch({
      type: POST_USER_ROLE_TO_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_CORL_USER_LOADING = '@@corlUsers/DELETE_CORL_USER_LOADING';
export const DELETE_CORL_USER_SUCCESS = '@@corlUsers/DELETE_CORL_USER_SUCCESS';
export const DELETE_CORL_USER_FAILURE = '@@corlUsers/DELETE_CORL_USER_FAILURE';

export const removeCorlUser = (orgId, userId, userRoleId) => async (dispatch) => {
  dispatch({ type: DELETE_CORL_USER_LOADING });

  let tempFormData = {
    UserId: userId,
    Roles: [{ UserRoleId: userRoleId }],
    AppId: CORL_APP_ID,
    OrgId: orgId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    let res = await axios.post(`/app/user/${userId}/action/unassign`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_CORL_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: DELETE_CORL_USER_SUCCESS,
        payload: res?.data,
      });
      dispatch(searchCorlUsers());
      dispatch(
        setCorlUser({
          selectedUserIndex: null,
          actions: null,
          selectedCorlUserID: null,
        })
      );
    }
  } catch (err) {
    dispatch({
      type: DELETE_CORL_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_RESEND_INVITE_TO_USER_LOADING = '@@corlUsers/POST_RESEND_INVITE_TO_USER_LOADING';
export const POST_RESEND_INVITE_TO_USER_SUCCESS = '@@corlUsers/POST_RESEND_INVITE_TO_USER_SUCCESS';
export const POST_RESEND_INVITE_TO_USER_FAILURE = '@@corlUsers/POST_RESEND_INVITE_TO_USER_FAILURE';

export const resendInviteToUser = (reqBody) => async (dispatch) => {
  dispatch({ type: POST_RESEND_INVITE_TO_USER_LOADING });

  const body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/app/user/${reqBody.UserId}/action/sendemailinvite`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_RESEND_INVITE_TO_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_CORL_USER_FORM_EDITABLE = '@client/SET_CORL_USER_FORM_EDITABLE';

export const setCORLUserFormEditable =
  (isEditable = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_CORL_USER_FORM_EDITABLE,
      isEditable,
    });
  };
