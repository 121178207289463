import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import { getAppLookupBody, getAssessmentWorkflowConfigLookupBody, getClientSecCertConfigBody, getClientSecCertTypesConfigBody, getDescriptionValueLabelSearchBody, getLookupBodyByFields, getManagementDashboardLookupBody, getProgramMetricsLookupBody, getUserDomainRuleLookupBody } from 'utils/lookupUtils';

export const GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE';

export const getNumberOfEmployeeRange = () => async (dispatch) => {
  dispatch({
    type: GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=NumberOfEmployeesRange`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STATE_LIST_LOADING = '@@lookup/GET_STATE_LIST_LOADING';
export const GET_STATE_LIST_SUCCESS = '@@lookup/GET_STATE_LIST_SUCCESS';
export const GET_STATE_LIST_FAILURE = '@@lookup/GET_STATE_LIST_FAILURE';

export const getStatesList = (countryName) => async (dispatch) => {
  dispatch({
    type: GET_STATE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=region?param=` + countryName);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_STATE_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_STATE_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_STATE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_COUNTRY_LIST_LOADING = '@@lookup/GET_COUNTRY_LIST_LOADING';
export const GET_COUNTRY_LIST_SUCCESS = '@@lookup/GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = '@@lookup/GET_COUNTRY_LIST_FAILURE';

export const getCountriesList = () => async (dispatch) => {
  dispatch({
    type: GET_COUNTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=Country`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COUNTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COUNTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_YES_NO_LIST_LOADING = '@@lookup/GET_YES_NO_LIST_LOADING';
export const GET_YES_NO_LIST_SUCCESS = '@@lookup/GET_YES_NO_LIST_SUCCESS';
export const GET_YES_NO_LIST_FAILURE = '@@lookup/GET_YES_NO_LIST_FAILURE';

export const getYesNoList = () => async (dispatch) => {
  dispatch({
    type: GET_YES_NO_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=YesNo`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_YES_NO_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_YES_NO_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_YES_NO_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CONTRACT_INDUSTRY_LIST_LOADING = '@@lookup/GET_CONTRACT_INDUSTRY_LIST_LOADING';
export const GET_CONTRACT_INDUSTRY_LIST_SUCCESS = '@@lookup/GET_CONTRACT_INDUSTRY_LIST_SUCCESS';
export const GET_CONTRACT_INDUSTRY_LIST_FAILURE = '@@lookup/GET_CONTRACT_INDUSTRY_LIST_FAILURE';

export const getContractIndustryList = () => async (dispatch) => {
  dispatch({
    type: GET_CONTRACT_INDUSTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=ContractIndustry`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CONTRACT_INDUSTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CONTRACT_INDUSTRY_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONTRACT_INDUSTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CONTRACT_LANE_TYPE_LIST_LOADING = '@@lookup/GET_CONTRACT_LANE_TYPE_LIST_LOADING';
export const GET_CONTRACT_LANE_TYPE_LIST_SUCCESS = '@@lookup/GET_CONTRACT_LANE_TYPE_LIST_SUCCESS';
export const GET_CONTRACT_LANE_TYPE_LIST_FAILURE = '@@lookup/GET_CONTRACT_LANE_TYPE_LIST_FAILURE';

export const getContractLaneTypeList = () => async (dispatch) => {
  dispatch({
    type: GET_CONTRACT_LANE_TYPE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=LaneType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CONTRACT_LANE_TYPE_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CONTRACT_LANE_TYPE_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONTRACT_LANE_TYPE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CUSTOMER_SUPPORT_LEVEL_LOADING = '@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_LOADING';
export const GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS = '@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS';
export const GET_CUSTOMER_SUPPORT_LEVEL_FAILURE = '@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_FAILURE';

export const getCustomerSupportLevel = () => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_SUPPORT_LEVEL_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CustomerSupportLevel`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CUSTOMER_SUPPORT_LEVEL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CUSTOMER_SUPPORT_LEVEL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CUSTOMER_SUCCESS_TIER_LOADING = '@@lookup/GET_CUSTOMER_SUCCESS_TIER_LOADING';
export const GET_CUSTOMER_SUCCESS_TIER_SUCCESS = '@@lookup/GET_CUSTOMER_SUCCESS_TIER_SUCCESS';
export const GET_CUSTOMER_SUCCESS_TIER_FAILURE = '@@lookup/GET_CUSTOMER_SUCCESS_TIER_FAILURE';

export const getCustomerSuccessTier = () => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_SUCCESS_TIER_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CustomerSuccessTier`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CUSTOMER_SUCCESS_TIER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUCCESS_TIER_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CUSTOMER_SUCCESS_TIER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STAKEHOLDER_PORTAL_LOADING = '@@lookup/GET_STAKEHOLDER_PORTAL_LOADING';
export const GET_STAKEHOLDER_PORTAL_SUCCESS = '@@lookup/GET_STAKEHOLDER_PORTAL_SUCCESS';
export const GET_STAKEHOLDER_PORTAL_FAILURE = '@@lookup/GET_STAKEHOLDER_PORTAL_FAILURE';

export const getStakeHolderPortal = () => async (dispatch) => {
  dispatch({
    type: GET_STAKEHOLDER_PORTAL_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=StakeHolderPortal`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_STAKEHOLDER_PORTAL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_STAKEHOLDER_PORTAL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_STAKEHOLDER_PORTAL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_EXECUTIVE_SUMMARY_CONFIG_LOADING = '@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_LOADING';
export const GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS = '@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS';
export const GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE = '@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE';

export const getExecutiveSummaryConfig = () => async (dispatch) => {
  dispatch({
    type: GET_EXECUTIVE_SUMMARY_CONFIG_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ExecutiveSummarySetting`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING = '@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING';
export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS = '@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS';
export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE = '@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE';

export const getDynamicVendorRosterConfig = () => async (dispatch) => {
  dispatch({
    type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=DynamicVendorRoaster`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CYBER_RISK_SCORE_LOADING = '@@lookup/GET_CYBER_RISK_SCORE_LOADING';
export const GET_CYBER_RISK_SCORE_SUCCESS = '@@lookup/GET_CYBER_RISK_SCORE_SUCCESS';
export const GET_CYBER_RISK_SCORE_FAILURE = '@@lookup/GET_CYBER_RISK_SCORE_FAILURE';

export const getCyberRiskScore = () => async (dispatch) => {
  dispatch({
    type: GET_CYBER_RISK_SCORE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CyberRiskScore`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CYBER_RISK_SCORE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CYBER_RISK_SCORE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CYBER_RISK_SCORE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_COLLABORATION_SCORE_LOADING = '@@lookup/GET_VENDOR_COLLABORATION_SCORE_LOADING';
export const GET_VENDOR_COLLABORATION_SCORE_SUCCESS = '@@lookup/GET_VENDOR_COLLABORATION_SCORE_SUCCESS';
export const GET_VENDOR_COLLABORATION_SCORE_FAILURE = '@@lookup/GET_VENDOR_COLLABORATION_SCORE_FAILURE';

export const getVendorCollaborationScore = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_COLLABORATION_SCORE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=VendorCollaborationScore`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_VENDOR_COLLABORATION_SCORE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VENDOR_COLLABORATION_SCORE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_COLLABORATION_SCORE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_TYPE_LOADING = '@@lookup/ASSESSMENT_REQUEST_TYPE_LOADING';
export const ASSESSMENT_REQUEST_TYPE_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_TYPE_SUCCESS';
export const ASSESSMENT_REQUEST_TYPE_FAILURE = '@@lookup/ASSESSMENT_REQUEST_TYPE_FAILURE';

export const getAssessmentRequestType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookUp/e=AssessmentRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_CATEGORY_LOADING = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_LOADING';
export const ASSESSMENT_REQUEST_CATEGORY_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_SUCCESS';
export const ASSESSMENT_REQUEST_CATEGORY_FAILURE = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_FAILURE';

export const getAssessmentRequestCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestCategory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING';
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS';
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE';

export const getAssessmentRequestProductCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestProductCategory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING';
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS';
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE';

export const getAssessmentRequestScopingInformation = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookUp/e=AssessmentRequestScopingInformation`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING';
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS';
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE';

export const getAssessmentRequestSecurityControlInformation = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestSecurityControlInformation`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_DOMAIN_LIST_LOADING = '@@lookup/GET_DOMAIN_LIST_LOADING';
export const GET_DOMAIN_LIST_SUCCESS = '@@lookup/GET_DOMAIN_LIST_SUCCESS';
export const GET_DOMAIN_LIST_FAILURE = '@@lookup/GET_DOMAIN_LIST_FAILURE';

export const getDomainList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=Domain?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_DOMAIN_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOMAIN_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CLIENT_VENDOR_INVENTORY_LOADING = '@@lookup/GET_CLIENT_VENDOR_INVENTORY_LOADING';
export const GET_CLIENT_VENDOR_INVENTORY_SUCCESS = '@@lookup/GET_CLIENT_VENDOR_INVENTORY_SUCCESS';
export const GET_CLIENT_VENDOR_INVENTORY_FAILURE = '@@lookup/GET_CLIENT_VENDOR_INVENTORY_FAILURE';

export const getClientVendorInventory = () => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_VENDOR_INVENTORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ClientVendorInventory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CLIENT_VENDOR_INVENTORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CLIENT_VENDOR_INVENTORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_VENDOR_INVENTORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CAPABILITY_GROUP_LIST_LOADING = '@@lookup/GET_CAPABILITY_GROUP_LIST_LOADING';
export const GET_CAPABILITY_GROUP_LIST_SUCCESS = '@@lookup/GET_CAPABILITY_GROUP_LIST_SUCCESS';
export const GET_CAPABILITY_GROUP_LIST_FAILURE = '@@lookup/GET_CAPABILITY_GROUP_LIST_FAILURE';

export const getCapabilityGroupList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_CAPABILITY_GROUP_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=CapabilitiesGroup?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CAPABILITY_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING = '@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING';
export const GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS';
export const GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE';

export const getAssessmentRequestTypeList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=AssessmentRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_FILE_TYPE_LOADING = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_LOADING';
export const ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS';
export const ASSESSMENT_REQUEST_FILE_TYPE_FAILURE = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_FAILURE';

export const getAssessmentRequestFileType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_FILE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ClientFileType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING = '@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING';
export const GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS';
export const GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE';

export const getAssessmentRequestStatusList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=AssessmentRequestStatus`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_LABEL_LIST_LOADING = '@@lookup/GET_ASSESSMENT_LABEL_LIST_LOADING';
export const GET_ASSESSMENT_LABEL_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_LABEL_LIST_SUCCESS';
export const GET_ASSESSMENT_LABEL_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_LABEL_LIST_FAILURE';

export const getAssessmentLabelList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_LABEL_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=EditAssessmentOption`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_LABEL_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_LABEL_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_LABEL_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_REMEDIATION_PREFERENCES_LOADING = '@@lookup/GET_REMEDIATION_PREFERENCES_LOADING';
export const GET_REMEDIATION_PREFERENCES_SUCCESS = '@@lookup/GET_REMEDIATION_PREFERENCES_SUCCESS';
export const GET_REMEDIATION_PREFERENCES_FAILURE = '@@lookup/GET_REMEDIATION_PREFERENCES_FAILURE';

export const getRemediationPreferences = () => async (dispatch) => {
  dispatch({
    type: GET_REMEDIATION_PREFERENCES_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=RemediationPreferences`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_REMEDIATION_PREFERENCES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_REMEDIATION_PREFERENCES_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REMEDIATION_PREFERENCES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_PRODUCT_TYPE_LIST_LOADING = '@@lookup/GET_PRODUCT_TYPE_LIST_LOADING';
export const GET_PRODUCT_TYPE_LIST_SUCCESS = '@@lookup/GET_PRODUCT_TYPE_LIST_SUCCESS';
export const GET_PRODUCT_TYPE_LIST_FAILURE = '@@lookup/GET_PRODUCT_TYPE_LIST_FAILURE';

export const getProductTypesList = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_TYPE_LIST_LOADING,
  });
  const body = getAppLookupBody(['ProductType']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_FAILURE,
    });
  }
};

export const GET_VENDOR_ROLE_LIST_LOADING = '@@lookup/GET_VENDOR_ROLE_LIST_LOADING';
export const GET_VENDOR_ROLE_LIST_SUCCESS = '@@lookup/GET_VENDOR_ROLE_LIST_SUCCESS';
export const GET_VENDOR_ROLE_LIST_FAILURE = '@@lookup/GET_VENDOR_ROLE_LIST_FAILURE';

export const getVendorRolesList = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_ROLE_LIST_LOADING,
  });
  const body = getAppLookupBody(['ProductVendorRole']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_VENDOR_ROLE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_VENDOR_ROLE_LIST_FAILURE,
    });
  }
};

export const GET_DOMAIN_RULE_LOOKUP_LOADING = '@@lookup/GET_DOMAIN_RULE_LOOKUP_LOADING';
export const GET_DOMAIN_RULE_LOOKUP_SUCCESS = '@@lookup/GET_DOMAIN_RULE_LOOKUP_SUCCESS';
export const GET_DOMAIN_RULE_LOOKUP_FAILURE = '@@lookup/GET_DOMAIN_RULE_LOOKUP_FAILURE';

export const getUserDomainRuleLookupList = () => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_RULE_LOOKUP_LOADING });
  try {
    const body = getUserDomainRuleLookupBody();
    const response = await axios.post(`/app/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_RULE_LOOKUP_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_DOMAIN_RULE_LOOKUP_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOMAIN_RULE_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_RESPONSE_CATEGORY_LIST_LOADING = '@@lookup/GET_RESPONSE_CATEGORY_LIST_LOADING';
export const GET_RESPONSE_CATEGORY_LIST_SUCCESS = '@@lookup/GET_RESPONSE_CATEGORY_LIST_SUCCESS';
export const GET_RESPONSE_CATEGORY_LIST_FAILURE = '@@lookup/GET_RESPONSE_CATEGORY_LIST_FAILURE';

export const getResponseCategoryList = () => async (dispatch) => {
  dispatch({ type: GET_RESPONSE_CATEGORY_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=YesNo`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VALIDATION_PREFERENCES_LOADING = '@@lookup/GET_VALIDATION_PREFERENCES_LOADING';
export const GET_VALIDATION_PREFERENCES_SUCCESS = '@@lookup/GET_VALIDATION_PREFERENCES_SUCCESS';
export const GET_VALIDATION_PREFERENCES_FAILURE = '@@lookup/GET_VALIDATION_PREFERENCES_FAILURE';

export const getValidationPreferences = () => async (dispatch) => {
  dispatch({
    type: GET_VALIDATION_PREFERENCES_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ValidationPreferences`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_VALIDATION_PREFERENCES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VALIDATION_PREFERENCES_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VALIDATION_PREFERENCES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_PORTAL_ASSESSMENT_ROUTING_LOADING = '@@lookup/GET_PORTAL_ASSESSMENT_ROUTING_LOADING';
export const GET_PORTAL_ASSESSMENT_ROUTING_SUCCESS = '@@lookup/GET_PORTAL_ASSESSMENT_ROUTING_SUCCESS';
export const GET_PORTAL_ASSESSMENT_ROUTING_FAILURE = '@@lookup/GET_PORTAL_ASSESSMENT_ROUTING_FAILURE';

export const getPortalAssessmentRouting = () => async (dispatch) => {
  dispatch({
    type: GET_PORTAL_ASSESSMENT_ROUTING_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=Portal2AssessmentRouting`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_PORTAL_ASSESSMENT_ROUTING_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PORTAL_ASSESSMENT_ROUTING_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PORTAL_ASSESSMENT_ROUTING_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CO_PILOT_INTEGRATION_LOADING = '@@lookup/GET_CO_PILOT_INTEGRATION_LOADING';
export const GET_CO_PILOT_INTEGRATION_SUCCESS = '@@lookup/GET_CO_PILOT_INTEGRATION_SUCCESS';
export const GET_CO_PILOT_INTEGRATION_FAILURE = '@@lookup/GET_CO_PILOT_INTEGRATION_FAILURE';

export const getIsCoPilot = () => async (dispatch) => {
  dispatch({
    type: GET_CO_PILOT_INTEGRATION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CoPilot`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CO_PILOT_INTEGRATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CO_PILOT_INTEGRATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CO_PILOT_INTEGRATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const GET_IS_CORL_CLEARED_COPILOT_LOADING = '@@lookup/GET_IS_CORL_CLEARED_COPILOT_LOADING';
export const GET_IS_CORL_CLEARED_COPILOT_SUCCESS = '@@lookup/GET_IS_CORL_CLEARED_COPILOT_SUCCESS';
export const GET_IS_CORL_CLEARED_COPILOT_FAILURE = '@@lookup/GET_IS_CORL_CLEARED_COPILOT_FAILURE';

export const getIsCorlClearedCopilot = () => async (dispatch) => {
  dispatch({
    type: GET_IS_CORL_CLEARED_COPILOT_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CORLClearedCopilot`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_IS_CORL_CLEARED_COPILOT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_IS_CORL_CLEARED_COPILOT_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_IS_CORL_CLEARED_COPILOT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const GET_DATA_REUSE_LOADING = '@@lookup/GET_DATA_REUSE_LOADING';
export const GET_DATA_REUSE_SUCCESS = '@@lookup/GET_DATA_REUSE_SUCCESS';
export const GET_DATA_REUSE_FAILURE = '@@lookup/GET_DATA_REUSE_FAILURE';

export const getDataReuse = () => async (dispatch) => {
  dispatch({
    type: GET_DATA_REUSE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=DataReuse`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_DATA_REUSE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_DATA_REUSE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DATA_REUSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_COMPANION_LOADING = '@@lookup/GET_COMPANION_LOADING';
export const GET_COMPANION_SUCCESS = '@@lookup/GET_COMPANION_SUCCESS';
export const GET_COMPANION_FAILURE = '@@lookup/GET_COMPANION_FAILURE';

export const getCompanion = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CompanionAssessment`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COMPANION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COMPANION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_EVIDENCE_LOOKUP_LOADING = '@@lookup/GET_EVIDENCE_LOOKUP_LOADING';
export const GET_EVIDENCE_LOOKUP_SUCCESS = '@@lookup/GET_EVIDENCE_LOOKUP_SUCCESS';
export const GET_EVIDENCE_LOOKUP_FAILURE = '@@lookup/GET_EVIDENCE_LOOKUP_FAILURE';

export const getEvidenceLookup = () => async (dispatch) => {
  dispatch({
    type: GET_EVIDENCE_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['EvidenceType', 'EvidenceSubType', 'FileType']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_EVIDENCE_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_EVIDENCE_LOOKUP_FAILURE,
    });
  }
};

export const GET_VENDOR_REPLACEMENT_REASON_LOADING = '@@lookup/GET_VENDOR_REPLACEMENT_REASON_LOADING';
export const GET_VENDOR_REPLACEMENT_REASON_SUCCESS = '@@lookup/GET_VENDOR_REPLACEMENT_REASON_SUCCESS';
export const GET_VENDOR_REPLACEMENT_REASON_FAILURE = '@@lookup/GET_VENDOR_REPLACEMENT_REASON_FAILURE';

export const getVendorReplacementReason = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_REPLACEMENT_REASON_LOADING,
  });
  const body = getAppLookupBody(['VendorReplacementReason']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_VENDOR_REPLACEMENT_REASON_SUCCESS,
      payload: res?.data?.Data?.VendorReplacementReason,
    });
  } catch {
    dispatch({
      type: GET_VENDOR_REPLACEMENT_REASON_FAILURE,
    });
  }
};

export const GET_ASSESSMENT_VRAS_REQUST_LOADING = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_LOADING';
export const GET_ASSESSMENT_VRAS_REQUST_SUCCESS = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_SUCCESS';
export const GET_ASSESSMENT_VRAS_REQUST_FAILURE = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_FAILURE';

export const getAssessmentVRASRequestType = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_VRAS_REQUST_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentVRASRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_LOADING = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_LOADING';
export const GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_FAILURE = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_FAILURE';

export const getQuestionnaireTemplateSubType = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=QuestionnaireTemplateSubType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_SUBTYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_TEMPLATE_TYPE_LOADING = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_TYPE_LOADING';
export const GET_QUESTIONNAIRE_TEMPLATE_TYPE_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_TYPE_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_TYPE_FAILURE = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_TYPE_FAILURE';

export const getQuestionnaireTemplateType = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_TEMPLATE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=QuestionnaireTemplateType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_LOADING = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_LOADING';
export const GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_FAILURE = '@@lookup/GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_FAILURE';

export const getQuestionnaireTemplateVersionType = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=VersionType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_VERSION_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_REQUIREMENT_TYPE_LOADING = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_TYPE_LOADING';
export const GET_QUESTIONNAIRE_REQUIREMENT_TYPE_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_TYPE_SUCCESS';
export const GET_QUESTIONNAIRE_REQUIREMENT_TYPE_FAILURE = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_TYPE_FAILURE';

export const getQuestionnaireRequirementType = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_REQUIREMENT_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=RequirementType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_REQUIREMENT_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_REQUIREMENT_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_REQUIREMENT_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING';
export const GET_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS';
export const GET_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE = '@@lookup/GET_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE';

export const getQuestionnaireRequirementGroup = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=RequirementGroup`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_DEFAULT_TIER_LOADING = '@@lookup/GET_QUESTIONNAIRE_DEFAULT_TIER_LOADING';
export const GET_QUESTIONNAIRE_DEFAULT_TIER_SUCCESS = '@@lookup/GET_QUESTIONNAIRE_DEFAULT_TIER_SUCCESS';
export const GET_QUESTIONNAIRE_DEFAULT_TIER_FAILURE = '@@lookup/GET_QUESTIONNAIRE_DEFAULT_TIER_FAILURE';

export const getQuestionnaireRequirementDefaultTier = () => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_DEFAULT_TIER_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=Tier`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_DEFAULT_TIER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_DEFAULT_TIER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_DEFAULT_TIER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_LOOKUP_DESCRIPTION_LOADING = '@@lookup/GET_LOOKUP_DESCRIPTION_LOADING';
export const GET_LOOKUP_DESCRIPTION_SUCCESS = '@@lookup/GET_LOOKUP_DESCRIPTION_SUCCESS';
export const GET_LOOKUP_DESCRIPTION_FAILURE = '@@lookup/GET_LOOKUP_DESCRIPTION_FAILURE';

export const getLookupDescriptionLabel = (lookupEntityId) => async (dispatch) => {
  dispatch({
    type: GET_LOOKUP_DESCRIPTION_LOADING,
  });
  try {
    const body = getDescriptionValueLabelSearchBody(lookupEntityId)
    const res = await axios.post(`/app/lookup/entity/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_LOOKUP_DESCRIPTION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_LOOKUP_DESCRIPTION_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_LOOKUP_DESCRIPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_LOADING = '@@lookup/GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_LOADING';
export const GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_SUCCESS = '@@lookup/GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_SUCCESS';
export const GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_FAILURE = '@@lookup/GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_FAILURE';

export const getProductDetailsComponentLookup = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['DataAccessOptions', 'ComponentType', 'ComponentDataLocation', 'MedicalDevice', 'CORLClearedStatus']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_DETAILS_COMPONENT_LOOKUP_FAILURE,
    });
  }
};

export const GET_RISK_RATING_OVERVIEW_LOOKUP_LOADING = '@@lookup/GET_RISK_RATING_OVERVIEW_LOOKUP_LOADING';
export const GET_RISK_RATING_OVERVIEW_LOOKUP_SUCCESS = '@@lookup/GET_RISK_RATING_OVERVIEW_LOOKUP_SUCCESS';
export const GET_RISK_RATING_OVERVIEW_LOOKUP_FAILURE = '@@lookup/GET_RISK_RATING_OVERVIEW_LOOKUP_FAILURE';

export const getRiskRatingOverviewLookup = () => async (dispatch) => {
  dispatch({
    type: GET_RISK_RATING_OVERVIEW_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['RiskRating', 'ImpactLevel', 'LikelihoodLevel']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_RISK_RATING_OVERVIEW_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_RISK_RATING_OVERVIEW_LOOKUP_FAILURE,
    });
  }
};

export const GET_REQUIREMENT_EXPIRATION_LOOKUP_LOADING = '@@lookup/GET_REQUIREMENT_EXPIRATION_LOOKUP_LOADING';
export const GET_REQUIREMENT_EXPIRATION_LOOKUP_SUCCESS = '@@lookup/GET_REQUIREMENT_EXPIRATION_LOOKUP_SUCCESS';
export const GET_REQUIREMENT_EXPIRATION_LOOKUP_FAILURE = '@@lookup/GET_REQUIREMENT_EXPIRATION_LOOKUP_FAILURE';

export const getRequirementExpirationLookup = () => async (dispatch) => {
  dispatch({
    type: GET_REQUIREMENT_EXPIRATION_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['RequirementExpiration']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_REQUIREMENT_EXPIRATION_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_REQUIREMENT_EXPIRATION_LOOKUP_FAILURE,
    });
  }
};

export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING';
export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS';
export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE';

export const portalRedirectionPreference = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=Portal`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_LOADING = '@@lookup/GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_LOADING';
export const GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_SUCCESS = '@@lookup/GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_SUCCESS';
export const GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_FAILURE = '@@lookup/GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_FAILURE';

export const getAssessmentWorkflowConfigLookuPList = () => async (dispatch) => {
  dispatch({ type: GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_LOADING });
  try {
    const body = getAssessmentWorkflowConfigLookupBody();
    const response = await axios.post(`/app/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_WORKFLOW_CONFIG_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_MANAGEMENT_DASHBOARD_LOADING = '@@lookup/GET_MANAGEMENT_DASHBOARD_LOADING';
export const GET_MANAGEMENT_DASHBOARD_SUCCESS = '@@lookup/GET_MANAGEMENT_DASHBOARD_SUCCESS';
export const GET_MANAGEMENT_DASHBOARD_FAILURE = '@@lookup/GET_MANAGEMENT_DASHBOARD_FAILURE';

export const getManagementDashboard = () => async (dispatch) => {
  dispatch({
    type: GET_MANAGEMENT_DASHBOARD_LOADING,
  });
  try {
    const body = getManagementDashboardLookupBody();
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_MANAGEMENT_DASHBOARD_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_MANAGEMENT_DASHBOARD_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_MANAGEMENT_DASHBOARD_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_PROGRAM_METRICS_LOADING = '@@lookup/GET_PROGRAM_METRICS_LOADING';
export const GET_PROGRAM_METRICS_SUCCESS = '@@lookup/GET_PROGRAM_METRICS_SUCCESS';
export const GET_PROGRAM_METRICS_FAILURE = '@@lookup/GET_PROGRAM_METRICS_FAILURE';

export const getProgramMetrics = () => async (dispatch) => {
  dispatch({
    type: GET_PROGRAM_METRICS_LOADING,
  });
  try {
    const body = getProgramMetricsLookupBody();
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_PROGRAM_METRICS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PROGRAM_METRICS_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PROGRAM_METRICS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_SEC_CERT_OPTIONS_LOADING = '@@lookup/GET_SEC_CERT_OPTIONS_LOADING';
export const GET_SEC_CERT_OPTIONS_SUCCESS = '@@lookup/GET_SEC_CERT_OPTIONS_SUCCESS';
export const GET_SEC_CERT_OPTIONS_FAILURE = '@@lookup/GET_SEC_CERT_OPTIONS_FAILURE';

export const getAllowSecCertOptions = () => async (dispatch) => {
  dispatch({
    type: GET_SEC_CERT_OPTIONS_LOADING,
  });
  try {
    const body = getClientSecCertConfigBody();
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_SEC_CERT_OPTIONS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_SEC_CERT_OPTIONS_SUCCESS,
        payload: res?.data?.Data?.AllowSecCertOptions,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SEC_CERT_OPTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_SEC_CERT_TYPES_LOADING = '@@lookup/GET_SEC_CERT_TYPES_LOADING';
export const GET_SEC_CERT_TYPES_SUCCESS = '@@lookup/GET_SEC_CERT_TYPES_SUCCESS';
export const GET_SEC_CERT_TYPES_FAILURE = '@@lookup/GET_SEC_CERT_TYPES_FAILURE';

export const getSecCertTypes = () => async (dispatch) => {
  dispatch({
    type: GET_SEC_CERT_TYPES_LOADING,
  });
  try {
    const body = getClientSecCertTypesConfigBody();
    const res = await axios.post(`/app/lookup/search`, body);
    if (res.data?.ErrorCount > 0) {
      dispatch(setErrors(res.data?.Errors));
      dispatch({
        type: GET_SEC_CERT_TYPES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_SEC_CERT_TYPES_SUCCESS,
        payload: res.data?.Data?.SecCertTypes,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SEC_CERT_TYPES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};