import {
  SEARCH_ALL_PORTAL_USERS_LIST_LOADING,
  SEARCH_ALL_PORTAL_USERS_LIST_SUCCESS,
  SEARCH_ALL_PORTAL_USERS_LIST_FAILURE,
  SEARCH_ORGANISATION_LIST_LOADING,
  SEARCH_ORGANISATION_LIST_FAILURE,
  SEARCH_ORGANISATION_LIST_SUCCESS,
  SET_ORG_LIST_PAGE,
  CLEAR_ORG_LIST,
  SEARCH_USER_DETAILS_LOADING,
  SEARCH_USER_DETAILS_SUCCESS,
  SEARCH_USER_DETAILS_FAILURE,
  SET_USER_DETAILS_FORM_EDITABLE,
  UPDATE_USER_DETAILS_LOADING,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  DELETE_USER_FROM_AUTH0_LOADING,
  DELETE_USER_FROM_AUTH0_SUCCESS,
  DELETE_USER_FROM_AUTH0_FAILURE,
  DELETE_USER_ASSOCIATION_LOADING,
  DELETE_USER_ASSOCIATION_SUCCESS,
  DELETE_USER_ASSOCIATION_FAILURE,
  SET_USER_DETAILS,
  UPDATE_ORG_LIST,
  UPDATE_SEARCH_LIST,
  BLOCK_UNBLOCK_USER_FROM_AUTH0_LOADING,
  BLOCK_UNBLOCK_USER_FROM_AUTH0_SUCCESS,
  BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE,
  SET_ALL_USERS_PAGE_NO,
  SET_ALL_USERS_ROW_PER_PAGE,
} from 'actions/allUsers';
import { isEmpty, uniqBy } from 'lodash';
import { getSelectOptionsList } from 'utils/arrayUtils';
import {
  DELETED_USER_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
  UPDATED_SUCCESSFULLY_MESSAGE,
  USER_UNASSOCIATED_SUCCESSFULLY,
  BLOCKED_UNBLOCKED_USER_SUCCESSFULLY_MESSAGE,
} from 'components/Common/AlertMessages';
import { toast } from 'react-toastify';
import { CLIENT_APP_ID, CORL_APP_ID, VENDOR_APP_ID } from 'constants/users';
import { DEFAULT_ROWS_PER_PAGE, NO } from 'constants/constants';

const initialState = {
  loading: false,
  orgTypeLoading: false,
  allUsers: [],
  organisationType: [],
  allOrgList: [],
  latestOrgList: [],
  searchValueOrgsList: [],
  orgListPage: 1,
  userDetails: {},
  isUserDetailsFormEditable: false,
  allUsersListCount: 0,
  allUsersPageNo: 0,
  allUsersRowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

export default function (state = initialState, action) {
  const { type, payload, isEditable, searchValue } = action;

  switch (type) {
    case SEARCH_ALL_PORTAL_USERS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ALL_PORTAL_USERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        allUsersListCount: payload?.length > 0 && payload[0]?.CountOfUsers,
        allUsers: payload?.map((item) => ({
          ...item,
          CorlUserRoleName: !isEmpty(item?.UserRoleList.filter((item) => item.AppId === CORL_APP_ID)) ? 
                                  item?.UserRoleList.filter((item) => item.AppId === CORL_APP_ID)[0]?.UserRoleName : NO,
          ClientUserRoleName: !isEmpty(item?.UserRoleList.filter((item) => item.AppId === CLIENT_APP_ID)) ? 
                                    item?.UserRoleList.filter((item) => item.AppId === CLIENT_APP_ID)[0]?.UserRoleName : NO,
          VendorUserRoleName: !isEmpty(item?.UserRoleList.filter((item) => item.AppId === VENDOR_APP_ID)) ? 
                                    item?.UserRoleList.filter((item) => item.AppId === VENDOR_APP_ID)[0]?.UserRoleName : NO                                        
        })),
      };
    }

    case SEARCH_ALL_PORTAL_USERS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        allUsers: [],
        allUsersListCount: 0,
      };
    }

    case SEARCH_ORGANISATION_LIST_LOADING: {
      return {
        ...state,
        orgTypeLoading: true,
      };
    }

    case SEARCH_ORGANISATION_LIST_FAILURE: {
      return {
        ...state,
        orgTypeLoading: false,
        organisationType: [],
      };
    }

    case SEARCH_ORGANISATION_LIST_SUCCESS: {
      let searchValueOrgsList = [];
      let currentOrgsList = [];
      if (searchValue) {
        searchValueOrgsList = getSelectOptionsList(payload?.Rows, 'OrgName', 'OrgId');
      } else {
        currentOrgsList = getSelectOptionsList(payload?.Rows, 'OrgName', 'OrgId');
      }
      return {
        ...state,
        orgTypeLoading: false,
        latestOrgList: currentOrgsList,
        allOrgList: uniqBy([...state.allOrgList, ...currentOrgsList], 'value'),
        searchValueOrgsList: uniqBy([...state.searchValueOrgsList, ...searchValueOrgsList], 'value'),
      };
    }

    case SET_ORG_LIST_PAGE: {
      return {
        ...state,
        orgListPage: payload,
      };
    }

    case CLEAR_ORG_LIST: {
      return {
        ...state,
        latestOrgList: [],
        allOrgList: [],
      };
    }

    case SEARCH_USER_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: payload?.Data?.Rows.length > 0 && payload?.Data?.Rows[0],
      };
    }

    case SEARCH_USER_DETAILS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        userDetails: {},
      };
    }

    case UPDATE_USER_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_USER_DETAILS_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_USER_DETAILS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_FROM_AUTH0_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_USER_FROM_AUTH0_SUCCESS: {
      toast.success(DELETED_USER_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_FROM_AUTH0_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_ASSOCIATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_USER_ASSOCIATION_SUCCESS: {
      toast.success(USER_UNASSOCIATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_USER_ASSOCIATION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_USER_DETAILS_FORM_EDITABLE: {
      return {
        ...state,
        isUserDetailsFormEditable: isEditable,
      };
    }

    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: payload,
      };
    }

    case UPDATE_ORG_LIST: {
      return {
        ...state,
        allOrgList: uniqBy(payload, 'value'),
      };
    }

    case UPDATE_SEARCH_LIST: {
      return {
        ...state,
        searchValueOrgsList: uniqBy(payload, 'value'),
      };
    }

    case BLOCK_UNBLOCK_USER_FROM_AUTH0_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case BLOCK_UNBLOCK_USER_FROM_AUTH0_SUCCESS: {
      toast.success(BLOCKED_UNBLOCKED_USER_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_ALL_USERS_PAGE_NO: {
      return {
        ...state,
        allUsersPageNo: payload,
      };
    }

    case SET_ALL_USERS_ROW_PER_PAGE: {
      return {
        ...state,
        allUsersRowsPerPage: payload,
      };
    }

    default:
      return state;
  }
}
