import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { setCustomErrors } from './customErrorHandler';
import {
  getOutreachListBody,
  getOutreachClientSearchBody,
  getQuestionnaireListBody,
  addRequestorToOutreachBody,
  getRecipientListForRequestorBody,
  constructOutreachTemplateData,
  editOutreachTemplateData,
  getRequestorForOutreach,
  getRecipientsAndAssociatedRequestorsBody,
  editOutreachStatusBody,
} from '../utils/outreachUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const GET_OUTREACH_TYPE_REQUEST = '@@outreachBuilder/GET_OUTREACH_TYPE_REQUEST';
export const GET_OUTREACH_TYPE_FAILURE = '@@outreachBuilder/GET_OUTREACH_TYPE_FAILURE';
export const GET_OUTREACH_TYPE_SUCCESS = '@@outreachBuilder/GET_OUTREACH_TYPE_SUCCESS';

export const getOutreachType = (entityName) => async (dispatch) => {
  dispatch({ type: GET_OUTREACH_TYPE_REQUEST });
  try {
    const res = await axios.get(`/app/lookup/e=${entityName}`);
    dispatch({
      type: GET_OUTREACH_TYPE_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: GET_OUTREACH_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_OUTREACH_STATUS_REQUEST = '@@outreachBuilder/GET_OUTREACH_STATUS_REQUEST';
export const GET_OUTREACH_STATUS_FAILURE = '@@outreachBuilder/GET_OUTREACH_STATUS_FAILURE';
export const GET_OUTREACH_STATUS_SUCCESS = '@@outreachBuilder/GET_OUTREACH_STATUS_SUCCESS';

export const getOutreachStatus = (entityName) => async (dispatch) => {
  dispatch({ type: GET_OUTREACH_STATUS_REQUEST });
  try {
    const res = await axios.get(`/app/lookup/e=${entityName}`);
    dispatch({
      type: GET_OUTREACH_STATUS_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: GET_OUTREACH_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_QUESTIONNAIRE_LIST_LOADING = '@@outreachBuilder/SEARCH_QUESTIONNAIRE_LIST_LOADING';
export const SEARCH_QUESTIONNAIRE_LIST_SUCCESS = '@@outreachBuilder/SEARCH_QUESTIONNAIRE_LIST_SUCCESS';
export const SEARCH_QUESTIONNAIRE_LIST_FAILURE = '@@outreachBuilder/SEARCH_QUESTIONNAIRE_LIST_FAILURE';

export const getQuestionnaireList = () => async (dispatch) => {
  dispatch({ type: SEARCH_QUESTIONNAIRE_LIST_LOADING });

  const body = getQuestionnaireListBody();

  try {
    const res = await axios.post(`/questionnairetemplate/search`, body);
    dispatch({
      type: SEARCH_QUESTIONNAIRE_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_OUTREACH_TEMPLATE_LOADING = '@@outreachBuilder/POST_OUTREACH_TEMPLATE_LOADING';
export const POST_OUTREACH_TEMPLATE_FAILURE = '@@outreachBuilder/POST_OUTREACH_TEMPLATE_FAILURE';
export const POST_OUTREACH_TEMPLATE_SUCCESS = '@@outreachBuilder/POST_OUTREACH_TEMPLATE_SUCCESS';

export const postOutreachTemplate = (data, callback) => async (dispatch) => {
  dispatch({
    type: POST_OUTREACH_TEMPLATE_LOADING,
  });

  const body = constructOutreachTemplateData(data);

  try {
    const res = await axios.post('/outreach', body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_OUTREACH_TEMPLATE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_OUTREACH_TEMPLATE_SUCCESS,
        payload: res?.data,
      });

      if (callback) {
        callback(res?.data?.Data);
      }
    }
  } catch (err) {
    dispatch({
      type: POST_OUTREACH_TEMPLATE_FAILURE,
    });
  }
};

export const CLEAR_SAVED_OUTREACH_TEMPLATE = '@@outreachBuilder/CLEAR_SAVED_OUTREACH_TEMPLATE';

export const clearOutreachTemplate = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SAVED_OUTREACH_TEMPLATE,
  });
};

export const CLEAR_SAVED_OUTREACH_RECORD = '@@outreachBuilder/CLEAR_SAVED_OUTREACH_RECORD';

export const clearOutreachRecord = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SAVED_OUTREACH_RECORD,
  });
};

export const PUT_OUTREACH_TEMPLATE_LOADING = '@@outreachBuilder/PUT_OUTREACH_TEMPLATE_LOADING';
export const PUT_OUTREACH_TEMPLATE_FAILURE = '@@outreachBuilder/PUT_OUTREACH_TEMPLATE_FAILURE';
export const PUT_OUTREACH_TEMPLATE_SUCCESS = '@@outreachBuilder/PUT_OUTREACH_TEMPLATE_SUCCESS';

export const putOutreachTemplate = (data) => async (dispatch) => {
  dispatch({
    type: PUT_OUTREACH_TEMPLATE_LOADING,
  });

  const body = editOutreachTemplateData(data);

  try {
    const res = await axios.put(`/outreach/${data?.OutreachId}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: PUT_OUTREACH_TEMPLATE_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_OUTREACH_TEMPLATE_SUCCESS,
        payload: res?.data,
      });

      dispatch(getOutreachList(data.OutreachId));
    }
  } catch (err) {
    dispatch({
      type: PUT_OUTREACH_TEMPLATE_FAILURE,
    });
  }
};

export const SEARCH_OUTREACH_LIST_LOADING = '@@outreachBuilder/SEARCH_OUTREACH_LIST_LOADING';
export const SEARCH_OUTREACH_LIST_FAILURE = '@@outreachBuilder/SEARCH_OUTREACH_LIST_FAILURE';
export const SEARCH_OUTREACH_LIST_SUCCESS = '@@outreachBuilder/SEARCH_OUTREACH_LIST_SUCCESS';

export const getOutreachList =
  (field = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_OUTREACH_LIST_LOADING });

    const body = getOutreachListBody(field);

    try {
      const res = await axios.post(`/outreach/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_OUTREACH_LIST_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_OUTREACH_LIST_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_OUTREACH_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_CLIENT_LIST_LOADING = '@@outreachBuilder/SEARCH_CLIENT_LIST_LOADING';
export const SEARCH_CLIENT_LIST_FAILURE = '@@outreachBuilder/SEARCH_CLIENT_LIST_FAILURE';
export const SEARCH_CLIENT_LIST_SUCCESS = '@@outreachBuilder/SEARCH_CLIENT_LIST_SUCCESS';

export const searchClientList =
  (searchText = '') =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CLIENT_LIST_LOADING });

    const body = getOutreachClientSearchBody(searchText);

    try {
      const res = await axios.post(`/client/search`, body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CLIENT_LIST_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_LIST_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CLIENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const POST_REQUESTOR_TO_OUTREACH_LOADING = '@@outreachBuilder/POST_REQUESTOR_TO_OUTREACH_LOADING';
export const POST_REQUESTOR_TO_OUTREACH_FAILURE = '@@outreachBuilder/POST_REQUESTOR_TO_OUTREACH_FAILURE';
export const POST_REQUESTOR_TO_OUTREACH_SUCCESS = '@@outreachBuilder/POST_REQUESTOR_TO_OUTREACH_SUCCESS';

export const postRequestorToOutreach = (addToRequestor) => async (dispatch) => {
  dispatch({ type: POST_REQUESTOR_TO_OUTREACH_LOADING });

  const body = addRequestorToOutreachBody(addToRequestor);

  try {
    const res = await axios.post(`/outreach/${addToRequestor?.outreachId}/requestor`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_REQUESTOR_TO_OUTREACH_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_REQUESTOR_TO_OUTREACH_SUCCESS,
        payload: res?.data,
      });

      dispatch(searchRequestorsList(addToRequestor?.outreachId));
    }
  } catch (err) {
    dispatch({
      type: POST_REQUESTOR_TO_OUTREACH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REQUESTORS_LIST_LOADING = '@@outreachBuilder/SEARCH_REQUESTORS_LIST_LOADING';
export const SEARCH_REQUESTORS_LIST_FAILURE = '@@outreachBuilder/SEARCH_REQUESTORS_LIST_FAILURE';
export const SEARCH_REQUESTORS_LIST_SUCCESS = '@@outreachBuilder/SEARCH_REQUESTORS_LIST_SUCCESS';

export const searchRequestorsList = (outreachId) => async (dispatch) => {
  dispatch({ type: SEARCH_REQUESTORS_LIST_LOADING });

  const body = getRequestorForOutreach(outreachId);

  try {
    const res = await axios.post(`/outreach/${outreachId}/requestor/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REQUESTORS_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_REQUESTORS_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REQUESTORS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_REQUESTOR_FROM_OUTREACH_LOADING = '@@outreachBuilder/DELETE_REQUESTOR_FROM_OUTREACH_LOADING';
export const DELETE_REQUESTOR_FROM_OUTREACH_FAILURE = '@@outreachBuilder/DELETE_REQUESTOR_FROM_OUTREACH_FAILURE';
export const DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS = '@@outreachBuilder/DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS';

export const deleteRequestorFromOutreach = (outreachId, outreachRequestorId, outreachRequestorName, forcedState, callback) => async (dispatch) => {
  dispatch({ type: DELETE_REQUESTOR_FROM_OUTREACH_LOADING });

  let url = `/outreach/${outreachId}/requestor/${outreachRequestorId}`;
  if (forcedState) {
    url = `${url}?forceDelete=true`;
  }

  try {
    const res = await axios.delete(url);
    if (res?.data?.ErrorCount > 0) {
      let errorList = res?.data?.Errors;
      if (errorList[0]?.Message === 'The Outreach requestor have recipients associated with it. Cannot be deleted.') {
        errorList[0].Message = `${outreachRequestorName} has recipients associated to it. By deleting it, you will remove the recipients as well. Are you sure you want to proceed?`;
      }
      dispatch(setCustomErrors(errorList));
      dispatch({
        type: DELETE_REQUESTOR_FROM_OUTREACH_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS,
        payload: res?.data,
      });

      if (callback) {
        callback();
      }

      dispatch(searchRequestorsList(outreachId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_REQUESTOR_FROM_OUTREACH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_LOADING = '@@outreachBuilder/SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_LOADING';
export const SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE = '@@outreachBuilder/SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE';
export const SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_SUCCESS = '@@outreachBuilder/SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_SUCCESS';

export const searchRecipientListForRequestor = (outreachId, requestorOrgId) => async (dispatch) => {
  dispatch({ type: SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_LOADING });

  const body = getRecipientListForRequestorBody(requestorOrgId);

  try {
    const res = await axios.post(`/outreach/${outreachId}/recipient/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_RECIPIENT_LIST_FOR_REQUESTOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLEAR_RECIPIENT_LIST_FOR_REQUESTOR = '@@outreachBuilder/CLEAR_RECIPIENT_LIST_FOR_REQUESTOR';

export const clearRecipientListForRequestor = () => async (dispatch) => {
  dispatch({ type: CLEAR_RECIPIENT_LIST_FOR_REQUESTOR });
};

export const DELETE_RECIPIENT_FOR_REQUESTOR_LOADING = '@@outreachBuilder/DELETE_RECIPIENT_FOR_REQUESTOR_LOADING';
export const DELETE_RECIPIENT_FOR_REQUESTOR_FAILURE = '@@outreachBuilder/DELETE_RECIPIENT_FOR_REQUESTOR_LOADING';
export const DELETE_RECIPIENT_FOR_REQUESTOR_SUCCESS = '@@outreachBuilder/DELETE_REQUESTOR_FROM_OUTREACH_SUCCESS';

export const deleteRecipientForRequestor = (outreachId, outreachRecipientId, selectedRequestorOrgId) => async (dispatch) => {
  dispatch({ type: DELETE_RECIPIENT_FOR_REQUESTOR_LOADING });

  try {
    const res = await axios.delete(`/outreach/${outreachId}/recipient/${outreachRecipientId}`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: DELETE_RECIPIENT_FOR_REQUESTOR_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_RECIPIENT_FOR_REQUESTOR_SUCCESS,
        payload: res?.data,
      });

      dispatch(searchRecipientListForRequestor(outreachId, selectedRequestorOrgId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_RECIPIENT_FOR_REQUESTOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_LOADING = '@@outreachBuilder/POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_LOADING';
export const POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE = '@@outreachBuilder/POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE';
export const POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_SUCCESS = '@@outreachBuilder/POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_SUCCESS';

export const uploadRecipientForRequestor = (outreachId, selectedRequestorOrgId, files) => async (dispatch) => {
  dispatch({ type: POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_LOADING });
  try {
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      formData.append('files[]', files[key]);
    });
    const res = await axios.post(`/outreach/${outreachId}/requestor/${selectedRequestorOrgId}/recipientcontact/action/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });

      dispatch(searchRecipientListForRequestor(outreachId, selectedRequestorOrgId));
    }
  } catch (err) {
    dispatch({
      type: POST_CSV_RECIPIENT_LIST_UPLOAD_FOR_REQUESTOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_POST_OUTREACH_TEMPLATE_LOADING = '@@outreachBuilder/SET_POST_OUTREACH_TEMPLATE_LOADING';
export const SET_POST_OUTREACH_TEMPLATE_FAILURE = '@@outreachBuilder/SET_POST_OUTREACH_TEMPLATE_FAILURE';
export const SET_POST_OUTREACH_TEMPLATE_SUCCESS = '@@outreachBuilder/SET_POST_OUTREACH_TEMPLATE_SUCCESS';

export const setPostOutreachTemplateSuccess = (status) => async (dispatch) => {
  dispatch({
    type: SET_POST_OUTREACH_TEMPLATE_LOADING,
  });
  try {
    dispatch({
      type: SET_POST_OUTREACH_TEMPLATE_SUCCESS,
      payload: status,
    });
  } catch (err) {
    dispatch({
      type: SET_POST_OUTREACH_TEMPLATE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_OUTREACH_STATUS_LOADING = '@@outreachBuilder/UPDATE_OUTREACH_STATUS_LOADING';
export const UPDATE_OUTREACH_STATUS_FAILURE = '@@outreachBuilder/UPDATE_OUTREACH_STATUS_FAILURE';
export const UPDATE_OUTREACH_STATUS_SUCCESS = '@@outreachBuilder/UPDATE_OUTREACH_STATUS_SUCCESS';

export const updateOutreachStatus = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_OUTREACH_STATUS_LOADING });

  const body = editOutreachStatusBody(data);

  try {
    const res = await axios.post(`/outreach/${data?.OutreachId}/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_OUTREACH_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: UPDATE_OUTREACH_STATUS_SUCCESS,
        payload: res?.data,
      });

      dispatch(getOutreachList(data?.OutreachId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_OUTREACH_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_LOADING =
  '@@outreachBuilder/SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_LOADING';
export const SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE =
  '@@outreachBuilder/SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE';
export const SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_SUCCESS =
  '@@outreachBuilder/SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_SUCCESS';

export const searchRecipientsAndAssociatedRequestors =
  (outreachId, searchText = '') =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_LOADING,
    });

    const body = getRecipientsAndAssociatedRequestorsBody(searchText);

    try {
      const res = await axios.post(`/outreach/${outreachId}/recipient/search`, body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_FULL_LIST_OF_RECIPIENTS_AND_ASSOCIATED_REQUESTORS_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_VIEW_LAUNCH_POPUP_LOADING = '@@outreachBuilder/SET_VIEW_LAUNCH_POPUP_LOADING';
export const SET_VIEW_LAUNCH_POPUP_FAILURE = '@@outreachBuilder/SET_VIEW_LAUNCH_POPUP_FAILURE';
export const SET_VIEW_LAUNCH_POPUP_SUCCESS = '@@outreachBuilder/SET_VIEW_LAUNCH_POPUP_SUCCESS';

export const setOpenLaunchPopup = (status) => async (dispatch) => {
  dispatch({
    type: SET_VIEW_LAUNCH_POPUP_LOADING,
  });
  try {
    dispatch({
      type: SET_VIEW_LAUNCH_POPUP_SUCCESS,
      payload: status,
    });
  } catch (err) {
    dispatch({
      type: SET_VIEW_LAUNCH_POPUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_LAUNCH_CONFIRMATION_LOADING = '@@outreachBuilder/SEARCH_LAUNCH_CONFIRMATION_LOADING';
export const SEARCH_LAUNCH_CONFIRMATION_FAILURE = '@@outreachBuilder/SEARCH_LAUNCH_CONFIRMATION_FAILURE';
export const SEARCH_LAUNCH_CONFIRMATION_SUCCESS = '@@outreachBuilder/SEARCH_LAUNCH_CONFIRMATION_SUCCESS';

export const searchLaunchConfirmation = () => async (dispatch) => {
  dispatch({
    type: SEARCH_LAUNCH_CONFIRMATION_LOADING,
  });
  try {
    dispatch({
      type: SEARCH_LAUNCH_CONFIRMATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_LAUNCH_CONFIRMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_LAUNCH_OUTREACH_LOADING = '@@outreachBuilder/POST_LAUNCH_OUTREACH_LOADING';
export const POST_LAUNCH_OUTREACH_FAILURE = '@@outreachBuilder/POST_LAUNCH_OUTREACH_FAILURE';
export const POST_LAUNCH_OUTREACH_SUCCESS = '@@outreachBuilder/POST_LAUNCH_OUTREACH_SUCCESS';

export const postLaunchOutreach = (outreachId) => async (dispatch) => {
  dispatch({
    type: POST_LAUNCH_OUTREACH_LOADING,
  });

  try {
    const res = await axios.post(`/outreach/${outreachId}/action/launch`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_LAUNCH_OUTREACH_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_LAUNCH_OUTREACH_SUCCESS,
        payload: res?.data,
      });

      dispatch(getOutreachList(outreachId));
    }
  } catch (err) {
    dispatch({
      type: POST_LAUNCH_OUTREACH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_OUTREACH_LOADING = '@incident/DELETE_OUTREACH_LOADING';
export const DELETE_OUTREACH_SUCCESS = '@incident/DELETE_OUTREACH_SUCCESS';
export const DELETE_OUTREACH_FAILURE = '@incident/DELETE_OUTREACH_FAILURE';

export const deleteOutreach = (outreachId) => async (dispatch) => {
  dispatch({ type: DELETE_OUTREACH_LOADING });
  try {
    const response = await axios.delete(`/outreach/${outreachId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_OUTREACH_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_OUTREACH_SUCCESS,
      });
      dispatch(getOutreachList());
    }
  } catch (err) {
    dispatch({
      type: DELETE_OUTREACH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
