export const ADEQUATE = 'Adequate';
export const INADEQUATE = 'Inadequate';
export const NOT_APPLICABLE = 'N/A';
export const ADEQUATE_VALUE = 2;
export const INADEQUATE_VALUE = 1;
export const NOT_APPLICABLE_TEXT = "Not Applicable";
export const NOT_APPLICABLE_VALUE = 3;

export const EVIDENCE_REVIEW_ADEQUACY_OPTIONS = {
  ADEQUATE,
  INADEQUATE,
  NOT_APPLICABLE_TEXT
};

export const EVIDENCE_REVIEW_ADEQUACY_TAB_LIST = [
  {
    label: EVIDENCE_REVIEW_ADEQUACY_OPTIONS.ADEQUATE,
    value: ADEQUATE_VALUE,
  },
  {
    label: EVIDENCE_REVIEW_ADEQUACY_OPTIONS.INADEQUATE,
    value: INADEQUATE_VALUE,
  },
  {
    label: EVIDENCE_REVIEW_ADEQUACY_OPTIONS.NOT_APPLICABLE_TEXT,
    value: NOT_APPLICABLE_VALUE,
  }
];

export const NO_DOCUMENTS_FOUND = 'No Documents Found';

export const IS_REQUIREMENT_AUDITOR_REVIEW_REQUIRED = 1;