import { isNil } from 'lodash';
import { QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT } from 'pages/QuestionnaireBuilder/constants';

export const constructOutreachTemplateData = (data) => {
  return {
    OutreachName: data?.OutreachName,
    Description: data?.Description,
    OutreachTypeId: data?.OutreachTypeId,
    OutreachStatus: 1,
    OutreachQuestionnaireTemplate: {
      QuestionnaireTemplateId: data?.QuestionnaireTemplateId,
    },
  };
};

export const editOutreachTemplateData = (data) => {
  return {
    OutreachId: data?.OutreachId,
    OutreachName: data?.OutreachName,
    Description: data?.Description,
    OutreachTypeId: data?.OutreachTypeId,
    OutreachStatus: data?.OutreachStatus,
    OutreachQuestionnaireTemplate: {
      OutreachQuestionnaireTemplateId: data?.OutreachQuestionnaireTemplateId,
      OutreachId: data?.OutreachId,
      QuestionnaireTemplateId: data?.QuestionnaireTemplateId,
      DisplayOrder: 1,
    },
  };
};

export const getQuestionnaireListBody = () => {
  return {
    Intent: '',
    Fields: ['QuestionnaireTemplateName'],
    Criterion: [
      {
        Field: 'Status',
        Operator: '=',
        Value: 23,
      },
      {
        field: 'QuestionnaireTemplateTypeId',
        operator: '=',
        value: QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getOutreachListBody = (field) => {
  return {
    intent: '',
    fields: [
      'OutreachId',
      'OutreachStatus',
      'OutreachQuestionnaireTemplateId',
      'OutreachStatusName',
      'OutreachType',
      'OutreachName',
      'IncidentName',
      'Description',
      'QuestionnaireTemplateId',
      'QuestionnaireTemplateName',
      'CountOfRequestorOrgs',
      'CountOfRecipientOrgs',
      'CountOfRecipientContacts',
      'StartDate',
      'LastUpdated',
    ],
    criterion: isNil(field)
      ? []
      : !isNaN(parseInt(field))
      ? [
          {
            Field: 'OutreachId',
            Operator: '=',
            Value: field,
          },
        ]
      : [
          {
            Field: 'OutreachName',
            Operator: 'startswith',
            Value: field,
          },
        ],
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getOutreachClientSearchBody = (searchText) => {
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientOrgName',
      'DomainName',
      'YearFounded',
      'NumberOfEmployeesRange',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressZip',
      'Phone',
    ],
    criterion: [
      {
        field: 'ClientOrgId',
        operator: '<>',
        value: '0',
      },
      {
        field: 'ClientOrgName',
        operator: 'startswith',
        value: searchText,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const addRequestorToOutreachBody = (addToRequestor) => {
  return {
    OutreachId: addToRequestor?.outreachId,
    OrgId: addToRequestor?.orgId,
    OrgRole: addToRequestor?.orgRole,
  };
};

export const getRequestorForOutreach = (outreachId) => {
  return {
    intent: '',
    fields: ['OutreachId', 'OutreachRequestorId', 'RequestorOrgId', 'RequestorOrgName'],
    criterion: [],
    sort: [
      {
        field: 'RequestorOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getRecipientListForRequestorBody = (requestorOrgId) => {
  return {
    intent: '',
    fields: ['RecipientOrgId', 'OutreachRecipientId', 'RecipientOrgName', 'RecipientContactId', 'RecipientContactList'],
    criterion: [
      {
        Field: 'RecipientRequestorOrgId',
        Operator: '=',
        Value: requestorOrgId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRecipientsAndAssociatedRequestorsBody = (searchtext) => {
  return {
    intent: '',
    fields: [
      'OutreachId',
      'OutreachName',
      'RecipientOrgId',
      'RecipientOrgName',
      'RecipientContactId',
      'RecipientRequestorOrgId',
      'RecipientRequestorOrgName',
      'RequestorRecipientOrgList',
    ],
    criterion:
      searchtext === ''
        ? []
        : [
            {
              Field: 'RecipientOrgName',
              Operator: 'startswith',
              Value: searchtext,
            },
          ],
    sort: [
      {
        field: 'RecipientRequestorOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const editOutreachStatusBody = (data) => {
  return {
    OutreachId: data?.OutreachId,
    Status: data?.OutreachStatus,
    ReviewComment: data?.ReviewComment,
  };
};
