import {
  CLEAR_ASSESSMENT_RESULTS,
  SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
  SEARCH_ASSESSMENT_OVERVIEW_LOADING,
  SEARCH_ASSESSMENT_OVERVIEW_SUCCESS,
  SEARCH_EVIDENCE_REVIEW_FAILURE,
  SEARCH_EVIDENCE_REVIEW_LOADING,
  SEARCH_EVIDENCE_REVIEW_SUCCESS,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS,
  SEARCH_RISK_RATING_OVERVIEW_FAILURE,
  SEARCH_RISK_RATING_OVERVIEW_LOADING,
  SEARCH_RISK_RATING_OVERVIEW_SUCCESS,
  SET_EXPAND_ALL_SECTIONS_SUCCESS,
  SET_LOADING_EXPANDED_SECTIONS,
  SEARCH_RISK_FINDING_SUMMARY_LOADING,
  SEARCH_RISK_FINDING_SUMMARY_SUCCESS,
  SEARCH_RISK_FINDING_SUMMARY_FAILURE,
  SAVE_ES_FEEDBACK_LOADING,
  SAVE_ES_FEEDBACK_SUCCESS,
  SAVE_ES_FEEDBACK_FAILURE,
  SEARCH_ASSESSMENT_BY_ID_LOADING,
  SEARCH_ASSESSMENT_BY_ID_SUCCESS,
  SEARCH_ASSESSMENT_BY_ID_FAILURE,
  SEARCH_EVIDENCE_REVIEW_ACCORDION_LOADING,
  SEARCH_EVIDENCE_REVIEW_ACCORDION_SUCCESS,
  SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE,
} from 'actions/assessmentResults';
import { getValidUrl } from 'utils/urlUtils';
import { DATE_FORMAT, YES_VALUE } from 'constants/constants';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import {
  ADEQUATE, 
  RISK_RATING_SUMMARY_MET_STATUS_LIST,
  RISK_RATING_SUMMARY_OPTIONS,
  RISK_RATING_SUMMARY_UNMET_STATUS_LIST,
} from 'pages/VendorProfile/Products/ProductDetails/AssessmentResults/constants';
import { getDisplayControlName } from 'utils/stringUtils';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { getModifiedList } from 'utils/assessmentResultsUtils';

const initialState = {
  loading: false,
  loadingExpandedSections: false,
  loadingRiskRatingOverview: false,
  loadingAssessmentOverview: false,
  loadingEvidenceReview: false,
  loadingRiskRatingControlSummary: false,
  loadingRiskFindingSummary: false,
  riskRatingOverview: {},
  assessmentOverview: {},
  riskRatingControlSummary: {},
  validationEvidence: {},
  expandAllSections: false,
  riskFindingSummary: {},
  assessment: {},
  showAssessmentResultsReady: false,
  assessmentLastUpdated: ''
};


const addImpactPillsLabel = (data) => {
  let impactPills = [];
  if (data?.PIIDataAccessId === YES_VALUE) {
    impactPills.push('Personally Identifiable Information (PII)');
  }
  if (data?.PHIDataAccessId === YES_VALUE) {
    impactPills.push('Protected Health Information (PHI)');
  }
  if (data?.PCIDataAccessId === YES_VALUE) {
    impactPills.push('Payment Card Industry (PCI)');
  }
  if (data?.EmployeeProprietaryDataAccessId === YES_VALUE) {
    impactPills.push('Employee/Proprietary Information');
  }
  return impactPills;
};

const addLikelihoodPillsLabel = (data) => {
  let likelihoodPills = [];
  if (data?.OffshoreDataAccessId === YES_VALUE) {
    likelihoodPills.push('OFFSHORE ACCESS');
  }
  if (data?.OffshoreDataStorageId === YES_VALUE) {
    likelihoodPills.push('OFFSHORE STORAGE');
  }
  return likelihoodPills;
}


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_EXPAND_ALL_SECTIONS_SUCCESS: {
      return {
        ...state,
        expandAllSections: payload,
      };
    }

    case SET_LOADING_EXPANDED_SECTIONS: {
      return {
        ...state,
        loadingExpandedSections: payload,
      };
    }

    case CLEAR_ASSESSMENT_RESULTS: {
      return {
        ...initialState,
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_LOADING: {
      return {
        ...state,
        loadingRiskRatingOverview: true,
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_SUCCESS: {
      const riskRatingList = [
        {
          Id: 1,
          Type: 'Risk Rating',
          Score: payload?.RiskRatingName,
          TileColor: payload?.RiskRatingName,
          Summary: '',
          Pills: [],
          Description: payload?.RiskRatingSummary || payload?.RiskRecommendation
            ? `**Summary**</br></br><div style="padding-left:30px;">${payload?.RiskRatingSummary.trim()}</div></br>  **Recommended Action**</br></br><div style="padding-left:30px;">${payload?.RiskRecommendation
            }</div>`
            : '',
          DisabledExpansion: false,
        },
        {
          Id: 2,
          Type: 'Impact Level',
          Score: payload?.ImpactLevelName,
          TileColor: payload?.ImpactLevelName,
          Pills: addImpactPillsLabel(payload),
          Summary: '',
          Description: '',
          DisabledExpansion: true,
        },
        {
          Id: 3,
          Type: 'Likelihood Level',
          Score: payload?.LikelihoodName,
          TileColor: payload?.LikelihoodName,
          Pills: addLikelihoodPillsLabel(payload),
          Summary: '',
          Description: '',
          DisabledExpansion: true,
        },
      ];

      return {
        ...state,
        loadingRiskRatingOverview: false,
        riskRatingOverview: riskRatingList,
        assessmentLastUpdated: payload?.LastUpdated
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_FAILURE: {
      return {
        ...state,
        loadingRiskRatingOverview: false,
        riskRatingOverview: [],
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_LOADING: {
      return {
        ...state,
        loadingAssessmentOverview: true,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_SUCCESS: {
      let data = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0];
      data.DomainName = getValidUrl(data?.DomainName);
      return {
        ...state,
        loadingAssessmentOverview: false,
        assessmentOverview: data,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_FAILURE: {
      return {
        ...state,
        loadingAssessmentOverview: false,
        assessmentOverview: {},
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING: {
      return {
        ...state,
        loadingRiskRatingControlSummary: true,
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS: {
      const tempRiskRatingControlSummary = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows;
      let finalRiskRatingControlSummary = [];

      tempRiskRatingControlSummary &&
        tempRiskRatingControlSummary.forEach((it) => {
          let Tier1DescriptionBullets = '';
          let Tier2DescriptionBullets = '';
          let Tier3DescriptionBullets = '';
          let Tier4DescriptionBullets = '';
          const Tier1InadequaciesList = it?.Tier1InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier1DescriptionBullets = Tier1DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier2InadequaciesList = it?.Tier2InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier2DescriptionBullets = Tier2DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier3InadequaciesList = it?.Tier3InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier3DescriptionBullets = Tier3DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier4InadequaciesList = it?.Tier4InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier4DescriptionBullets = Tier4DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const tempRiskRatingControlSummaryList = [
            {
              Id: 1,
              Type: `${it?.Tier1Name} Met`,
              Score: it?.Tier1Percent === "Not Applicable" ? "Not Applicable" : `${Math.floor(it?.Tier1Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} ${it?.Tier1TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier1InadequaciesList,
              Description: `**${it?.Tier1Name} Results**</br></br>There ${it?.Tier1TotalAdequacies === 1
                ? `was ${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} inadequacies`
                } identified in ${it?.Tier1Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier1DescriptionBullets}</ul></div>`,
            },
            {
              Id: 2,
              Type: `${it?.Tier2Name} Met`,
              Score: it?.Tier2Percent === "Not Applicable" ? "Not Applicable" : `${Math.floor(it?.Tier2Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} ${it?.Tier2TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier2InadequaciesList,
              Description: `**${it?.Tier2Name} Results**</br></br>There ${it?.Tier2TotalAdequacies === 1
                ? `was ${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} inadequacies`
                } identified in ${it?.Tier2Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier2DescriptionBullets}</ul></div>`,
            },
            {
              Id: 3,
              Type: `${it?.Tier3Name} Met`,
              Score: it?.Tier3Percent === "Not Applicable" ? "Not Applicable" : `${Math.floor(it?.Tier3Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} ${it?.Tier3TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier3InadequaciesList,
              Description: `**${it?.Tier3Name} Results**</br></br>There ${it?.Tier3TotalAdequacies === 1
                ? `was ${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} inadequacies`
                } identified in ${it?.Tier3Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier3DescriptionBullets}</ul></div>`,
            },
            {
              Id: 4,
              Type: `${it?.Tier4Name} Met`,
              Score: it?.Tier4Percent === "Not Applicable" ? "Not Applicable" : `${Math.floor(it?.Tier4Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} ${it?.Tier4TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier4InadequaciesList,
              Description: `**${it?.Tier4Name} Results**</br></br>There ${it?.Tier4TotalAdequacies === 1
                ? `was ${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} inadequacies`
                } identified in ${it?.Tier4Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier4DescriptionBullets}</ul></div>`,
            },
          ];

          finalRiskRatingControlSummary = [...tempRiskRatingControlSummaryList]

        });

      return {
        ...state,
        loadingRiskRatingControlSummary: false,
        riskRatingControlSummary: finalRiskRatingControlSummary,
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE: {
      return {
        ...state,
        loadingRiskRatingControlSummary: false,
        riskRatingControlSummary: [],
      };
    }

    case SEARCH_EVIDENCE_REVIEW_LOADING: {
      return {
        ...state,
        loadingEvidenceReview: true,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_SUCCESS: {
      let finalValidationEvidence = payload?.map((requirement, index) => {
        return {
          ...requirement,
          IsMet: isNil(requirement?.AuditorAdequacyName) || requirement?.AuditorAdequacyName === 'N/A' || requirement?.AuditorAdequacyName === 'Not Applicable' ? null : requirement?.AuditorAdequacyName?.toLowerCase() === ADEQUATE,
          Id: index + 1,
          AdequacyId : requirement?.AuditorAdequacyId,
          AdequacyName : requirement?.AuditorAdequacyName,
        };
      });

      return {
        ...state,
        loadingEvidenceReview: false,
        validationEvidence: finalValidationEvidence,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_FAILURE: {
      return {
        ...state,
        loadingEvidenceReview: false,
        validationEvidence: [],
      };
    }

    case SEARCH_EVIDENCE_REVIEW_ACCORDION_LOADING: {
      return {
        ...state,
        loadingEvidenceReviewAccordion: true,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_ACCORDION_SUCCESS: {
      const list = cloneDeep(state.validationEvidence)
      let finalValidationEvidence = list?.map((requirement) => {
        if (requirement?.RequirementId === payload?.RequirementId) {
          let evidences = '';
          payload?.ResponseAttachmentList?.forEach((attachment) => {
            evidences = isEmpty(evidences) ? attachment?.FileName : evidences.concat(", ", attachment?.FileName)
          });
          return {
            ...requirement,
            ResultsOfTesting: payload?.ResultsOfTesting,
            NotesToVendor: payload?.NotesToVendor,
            DefaultTierId: payload?.DefaultTierId,
            DefaultTierName: payload?.DefaultTierName,
            QuestionResponseList: payload?.AssessmentQuestionnaireQuestionResponseList?.map((question) => {
              return {
                ...question,
                RequirementName: requirement?.RequirementName,
                Adequacy: requirement?.AdequacyName
              }
            }),
            EvidenceReviewed: evidences
          }
        } else {
          return requirement;
        }
      });

      return {
        ...state,
        loadingEvidenceReviewAccordion: false,
        validationEvidence: finalValidationEvidence,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE: {
      return {
        ...state,
        loadingEvidenceReviewAccordion: false,
        // validationEvidence: [],
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_LOADING: {
      return {
        ...state,
        loadingRiskFindingSummary: true,
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_SUCCESS: {
      let tempRiskFindingSummary = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows;
      tempRiskFindingSummary = tempRiskFindingSummary?.map((riskSummary, index) => {
        return {
          ...riskSummary,
          metUnmetLabel: RISK_RATING_SUMMARY_MET_STATUS_LIST?.includes(riskSummary?.Status)
            ? RISK_RATING_SUMMARY_OPTIONS.MET
            : RISK_RATING_SUMMARY_UNMET_STATUS_LIST?.includes(riskSummary?.Status)
              ? RISK_RATING_SUMMARY_OPTIONS.UNMET
              : '',
          Id: index + 1,
          DisplayControlName: getDisplayControlName(riskSummary),
          CompletionDate: !isNil(riskSummary?.CompletionDate) ? convertToLocalDateTime(riskSummary?.CompletionDate, DATE_FORMAT) : '',
        };
      });
      return {
        ...state,
        loadingRiskFindingSummary: false,
        riskFindingSummary: tempRiskFindingSummary,
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_FAILURE: {
      return {
        ...state,
        loadingRiskFindingSummary: false,
        riskFindingSummary: [],
      };
    }

    case SAVE_ES_FEEDBACK_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SAVE_ES_FEEDBACK_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SAVE_ES_FEEDBACK_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_LOADING: {
      return {
        ...state,
        loadingAssessment: true,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_SUCCESS: {
      const list = getModifiedList(payload?.Data?.Rows);
      const assessment = list?.length > 0 && list[0];
      const showAssessmentResultsReady = assessment?.DisplayResultAndEvidence
        && assessment?.AssessmentApplicableForES.toLowerCase() === 'true'
        && assessment?.Threshold !== '1' && assessment?.ReportInExecSummary;
      return {
        ...state,
        loadingAssessment: false,
        assessment: assessment,
        showAssessmentResultsReady
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_FAILURE: {
      return {
        ...state,
        loadingAssessment: false,
        assessment: {},
        showAssessmentResultsReady: false
      };
    }

    default:
      return state;
  }
}
