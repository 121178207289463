import axios from 'axios';
import { setErrors } from './errorHandler';
import { QUESTIONNAIRE_TEMPLATE_STATUS } from '../constants/questionnaireTemplate';
import { ACTIVE } from '../constants/active';
import { getErrorObject } from '../utils/apiUtils';
import { FORCE_UPDATE_ASSESSMENT_QUESTION_CONFIRMATION, FORCE_UPDATE_PROGRESS_INDICATOR_CONFIRMATION, NO_DATA_FOUND } from '../constants/errorMessage';
import { getIncidentListSearchBody, getQuestionnaireTemplateQuestionListBody, getQuestionnaireRequirementListSearchBody, updateQuestionnaireRequirementListSearchBody, getCreateNewVersionQuestionnaireBody, addQuestionnaireRequirementSearchBody, getAllQuestionnaireRequirementsListSearchBody, getQuestionnaireTemplateQuestionBody, getInadequacyListSearchBody, getInadequacyReasonsForRequirementBody, getInadequacyReasonsOptionIdBody, getMasterIPIListSearchBody, getIPIListSearchBody, getAllReasonsListSearchBody, addInadequacyReasonsBody, updateInadequacyReasonsBody, getLookupQuestionListBody, updateRequirementAuditReviewBody } from '../utils/questionnaireBuilderUtil';
import { DRAFT_STATUS_ID, QA_REJECTED_STATUS, READY_FOR_QA_STATUS, QA_APPROVED_STATUS, PAGE_INCIDENT, DEFAULT_LOOKUP_QUESTION_PAGE_NO, DEFAULT_LOOKUP_QUESTION_ROWS_PER_PAGE } from '../pages/QuestionnaireBuilder/constants';
import { setCustomErrors } from './customErrorHandler';

export const SET_QUESTION_LIST = '@questionnaireBuilder/SET_QUESTION_LIST';
export const setQuestionList = (question) => async (dispatch) => {
  dispatch({
    type: SET_QUESTION_LIST,
    payload: question,
  });
};

export const HIDE_ALERT = '@questionnaireBuilder/HIDE_ALERT';
export const hideAlert = () => async (dispatch) => {
  dispatch({
    type: HIDE_ALERT,
  });
};

export const POST_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const createQuestionnaireTemplate = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_TEMPLATE_LOADING,
  });
  const data = {
    ...formData,
    questionnaireTemplateStatus: QUESTIONNAIRE_TEMPLATE_STATUS.DRAFT,
    active: ACTIVE.active,
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.post('/questionnairetemplate', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_SUCCESS,
        payload: response?.data,
      });

      if (callback) {
        callback(response?.data?.Data.QuestionnaireTemplate);
      }
    }
  } catch {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_FAILURE,
    });
  }
};

export const PUT_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_LOADING';
export const PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const PUT_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const updateQuestionnaireTemplate = (id, formData) => async (dispatch) => {
  dispatch({
    type: PUT_QUESTIONNAIRE_TEMPLATE_LOADING,
  });
  const data = {
    ...formData,
    active: ACTIVE.active,
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.put(`/questionnairetemplate/${id}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS,
        payload: response?.data,
      });
      let status = response?.data?.Data?.QuestionnaireTemplate?.QuestionnaireTemplateStatusName;
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: 1,
          })
        );
      }
      dispatch(getQuestionnaireTemplate(id));
    }
  } catch {
    dispatch({
      type: PUT_QUESTIONNAIRE_TEMPLATE_FAILURE,
    });
  }
};

export const GET_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_LOADING';
export const GET_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const getQuestionnaireTemplate = (id) => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_TEMPLATE_LOADING,
  });

  try {
    const response = await axios.get(`/questionnairetemplate/${id}`);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
    });
  }
};

export const initCurrentQuestionnaireTemplate = (currentTemplate) => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
    payload: {
      Data: currentTemplate,
    },
  });
};

export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const createQuestionnaireTemplateQuestion = (id, status, formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  });
  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/questionnairetemplate/${id}/question`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      dispatch(getQuestionnaireTemplateQuestionList(id, formData?.requirementId));
      dispatch(getQuestionnaireTemplate(id));
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const updateQuestionnaireTemplateQuestion = (id, status, formData, forceUpdate = false) => async (dispatch) => {
  dispatch({
    type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  });
  let url = `/questionnairetemplate/${id}/question`;
  if (forceUpdate) {
    url = `${url}?forceUpdate=true`;
  }
  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(url, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message === FORCE_UPDATE_ASSESSMENT_QUESTION_CONFIRMATION);
      if (errors.length > 0) {
        dispatch(setAssessmentQuestionForceUpdate(true))
      }else dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      if (formData?.requirementId) {
        dispatch(getQuestionnaireTemplateQuestionList(id, formData?.requirementId));
        dispatch(getQuestionnaireTemplate(id));
      } else dispatch(getQuestionnaireTemplate(id));
    }
  } catch {
    dispatch({
      type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const deleteQuestionnaireTemplateQuestion = (id, status, questionId) => async (dispatch) => {
  dispatch({
    type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  });

  try {
    const response = await axios.delete(`/questionnairetemplate/${id}/question/${questionId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      dispatch(getQuestionnaireTemplate(id));
    }
  } catch {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const SET_SELECTED_QUESTION = '@questionnaireBuilder/SET_SELECTED_QUESTION';
export const setSelectedQuestion = (question) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_QUESTION,
    payload: question,
  });
};

export const RESET_QUESTIONNAIRE_TEMPLATE = '@questionnaireBuilder/RESET_QUESTIONNAIRE_TEMPLATE';
export const resetQuestionnaireTemplate = (question) => async (dispatch) => {
  dispatch({
    type: RESET_QUESTIONNAIRE_TEMPLATE,
    payload: question,
  });
};

export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING';
export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS';
export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE';

export const actionQuestionnaireTemplateQuestionRequired = (id, status, questionId, formData) => async (dispatch) => {
  dispatch({
    type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING,
  });
  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/questionnairetemplate/${questionId}/action/updateIsRequired`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE,
      });
    } else {
      dispatch({
        type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      dispatch(getQuestionnaireTemplate(id));
      dispatch(getQuestionnaireTemplateQuestionList(id, formData?.requirementId))
    }
  } catch {
    dispatch({
      type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE,
    });
  }
};

export const SEARCH_INCIDENT_LIST_LOADING = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_LOADING';
export const SEARCH_INCIDENT_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_SUCCESS';
export const SEARCH_INCIDENT_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_FAILURE';

export const getIncidentListData = () => async (dispatch) => {
  dispatch({ type: SEARCH_INCIDENT_LIST_LOADING });
  try {
    const body = getIncidentListSearchBody();
    const response = await axios.post(`/incident/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_INCIDENT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_INCIDENT_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INCIDENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING';
export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS';
export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE';

export const getQuestionnaireStatusTypeNameList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_COMMENT_QUESTIONNAIRE_LOADING = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_LOADING';
export const POST_COMMENT_QUESTIONNAIRE_SUCCESS = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_SUCCESS';
export const POST_COMMENT_QUESTIONNAIRE_FAILURE = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_FAILURE';

export const addCommentToQuestionnaire = (formData) => async (dispatch) => {
  dispatch({
    type: POST_COMMENT_QUESTIONNAIRE_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    let response;
    response = await axios.post(`/questionnairetemplate/action/reviewComment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_COMMENT_QUESTIONNAIRE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_COMMENT_QUESTIONNAIRE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_COMMENT_QUESTIONNAIRE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_QUESTIONNAIRE_STATUS_LOADING = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_LOADING';
export const POST_QUESTIONNAIRE_STATUS_SUCCESS = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_SUCCESS';
export const POST_QUESTIONNAIRE_STATUS_FAILURE = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_FAILURE';

export const updateStatusOfQuestionnaire = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_STATUS_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    let response;
    response = await axios.post(`/questionnairetemplate/action/updatestatus`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_STATUS_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_QUESTIONNAIRE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const QUESTION_PANEL_DISABLE_STATE = '@questionnaireBuilder/QUESTION_PANEL_DISABLE_STATE';
export const questionPanelDisableStateUpdate = (status) => async (dispatch) => {
  dispatch({
    type: QUESTION_PANEL_DISABLE_STATE,
    payload: status,
  });
};

export const REORDER_QUESTIONS_LOADING = '@@questionnaireBuilder/REORDER_QUESTIONS_LOADING';
export const REORDER_QUESTIONS_SUCCESS = '@@questionnaireBuilder/REORDER_QUESTIONS_SUCCESS';
export const REORDER_QUESTIONS_FAILURE = '@@questionnaireBuilder/REORDER_QUESTIONS_FAILURE';

export const reorderQuestions = (formData, id) => async (dispatch) => {
  dispatch({
    type: REORDER_QUESTIONS_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    let response;
    response = await axios.post(`/QuestionnaireTemplate/${id}/action/reorderquestions`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: REORDER_QUESTIONS_FAILURE,
      });
    } else {
      dispatch({
        type: REORDER_QUESTIONS_SUCCESS,
        payload: response?.data,
      });
      dispatch(getQuestionnaireTemplate(id));
    }
  } catch (err) {
    dispatch({
      type: REORDER_QUESTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_LOADING = '@questionnaireBuilder/SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_LOADING';
export const SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_SUCCESS = '@questionnaireBuilder/SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_SUCCESS';
export const SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE = '@questionnaireBuilder/SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE';

export const searchAllQuestionnaireRequirementsList = (callback, searchRequiremenTypeId = null) => async dispatch => {
  dispatch({ type: SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_LOADING });
  try {
    const body = getAllQuestionnaireRequirementsListSearchBody(searchRequiremenTypeId);
    const response = await axios.post(`/serviceprovider/requirement/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  };
}

export const SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING = '@questionnaireBuilder/SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING';
export const SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS = '@questionnaireBuilder/SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE = '@questionnaireBuilder/SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE';

export const searchQuestionnaireRequirementList = (QuestionnaireTemplateId, callback) => async dispatch => {
  dispatch({ type: SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING });
  try {
    const body = getQuestionnaireRequirementListSearchBody(QuestionnaireTemplateId);
    const response = await axios.post(`/serviceprovider/requirement/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
        payload: response.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  };
}

export const GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_LOADING = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_LOADING';
export const GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_SUCCESS = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_SUCCESS';
export const GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE';

export const getQuestionnaireTemplateQuestionList = (questionnaireTemplateId, requirementId, callback) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_LOADING });
  try {
    let body;
    body = getQuestionnaireTemplateQuestionListBody(questionnaireTemplateId, requirementId);

    const response = await axios.post(`/questionnairetemplate/search/questionnairetemplatequestion`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if(callback) {
        callback(response?.data?.Data?.Rows)
      }
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_LOADING = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_LOADING';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_SUCCESS = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_SUCCESS';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_FAILURE = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_FAILURE';

export const deleteQuestionnaireTemplateQuestionForRequirement = (id, status, questionId, requirementId, forcedState = false) => async (dispatch) => {
  dispatch({
    type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_LOADING,
  });
  let url = `/questionnairetemplate/${id}/requirement/${requirementId}/question/${questionId}`;
  if (forcedState) {
    url = `${url}?forceDelete=true`;
  }

  try {
    const response = await axios.delete(url);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setCustomErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      dispatch(getQuestionnaireTemplateQuestionList(id, requirementId));
      dispatch(getQuestionnaireTemplate(id));
    }
  } catch {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const POST_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING';
export const POST_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS';
export const POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE';

export const addQuestionnaireRequirement = (body, questionnaireTemplateId, status, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING,
  });
  const requestBody = addQuestionnaireRequirementSearchBody(body);
  try {
    const response = await axios.post(`/questionnairetemplate/${questionnaireTemplateId}/requirement`, requestBody);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      });
      dispatch(searchQuestionnaireRequirementList(questionnaireTemplateId));
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchQuestionnaireRequirementList(questionnaireTemplateId));
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: questionnaireTemplateId,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          }, () => {
            dispatch(getQuestionnaireTemplate(questionnaireTemplateId));
          }))
      }
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING = '@questionnaireBuilder/PUT_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING';
export const PUT_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS = '@questionnaireBuilder/PUT_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS';
export const PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE = '@questionnaireBuilder/PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE';

export const updateQuestionnaireRequirement = (body, focusedRequirement, status, forceUpdate = false, callback) => async (dispatch) => {
  dispatch({
    type: PUT_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING,
  });
  const requestBody = updateQuestionnaireRequirementListSearchBody(body);
  try {
    let response;
    let url = `/questionnairetemplate/${focusedRequirement?.QuestionnaireTemplateId}/requirement/${focusedRequirement?.QuestionnaireTemplateRequirementId}`;
    if (forceUpdate) {
      url = `${url}?forceUpdate=${forceUpdate}`;
    }
    response = await axios.put(url, requestBody);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setCustomErrors(response?.data?.Errors));
      dispatch({
        type: PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchQuestionnaireRequirementList(focusedRequirement?.QuestionnaireTemplateId));
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: focusedRequirement?.QuestionnaireTemplateId,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          }, () => {
            dispatch(getQuestionnaireTemplate(focusedRequirement?.QuestionnaireTemplateId));
          }))
      }
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_REQUIREMENT = '@questionnaireBuilder/SET_SELECTED_REQUIREMENT';
export const setSelectedRequirement = (value = false) => async dispatch => {
  dispatch({
    type: SET_SELECTED_REQUIREMENT,
    payload: value
  })
}

export const DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_LOADING = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_LOADING';
export const DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_SUCCESS = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_SUCCESS';
export const DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE';

export const deleteQuestionnaireTemplateRequirement = (requirementId, questionnaireTemplateId, status, questionnaireTemplateRequirementId,  forcedState = false, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_LOADING,
  });
  let url = `/questionnairetemplate/${questionnaireTemplateId}/requirement/${requirementId}/questionnaireTemplateRequirementId/${questionnaireTemplateRequirementId}`;
  if (forcedState) {
    url = `${url}?forceDelete=true`;
  }

  try {
    const response = await axios.delete(url);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setCustomErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchQuestionnaireRequirementList(questionnaireTemplateId));
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: questionnaireTemplateId,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          }, () => {
            dispatch(getQuestionnaireTemplate(questionnaireTemplateId));
          }))
      }
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE,
    });
  }
};

export const POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE';

export const createQuestionnaireTemplateDuplicate = (body, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_LOADING,
  });
  try {
    let response = await axios.post(`/questionnaireTemplate/action/duplicate`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback(response?.data?.Data?.QuestionnaireTemplate?.QuestionnaireTemplateId)
      }
    }
  } catch (err) {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ANSWER_TYPE = '@questionnaireBuilder/SET_ANSWER_TYPE';
export const setAnswerType = (value = PAGE_INCIDENT) => async dispatch => {
  dispatch({
    type: SET_ANSWER_TYPE,
    payload: value
  })
}

export const POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE';

export const createNewVersionQuestionnaireTemplate = (data, versionTypeId, callback) => async (dispatch) => {
  dispatch({
    type: POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_LOADING,
  });
  try {
    let body = getCreateNewVersionQuestionnaireBody(data, versionTypeId);
    let response = await axios.post(`/questionnaireTemplate/action/versionchange`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback(response?.data?.Data?.QuestionnaireTemplate?.QuestionnaireTemplateId)
      }
    }
  } catch (err) {
    dispatch({
      type: POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_QUESTION_AND_EVIDENCE_LIST = '@questionnaireBuilder/SET_QUESTION_AND_EVIDENCE_LIST';
export const setQuestionAndEvidenceList = (question = []) => async (dispatch) => {
  dispatch({
    type: SET_QUESTION_AND_EVIDENCE_LIST,
    payload: question,
  });
};

export const SET_EXPANDED_REQUIREMENT = '@questionnaireBuilder/SET_EXPANDED_REQUIREMENT';
export const setExpandedRequirement = (item = {}) => async (dispatch) => {
  dispatch({
    type: SET_EXPANDED_REQUIREMENT,
    payload: item,
  });
};

export const SET_FOCUSED_REQUIREMENT = '@questionnaireBuilder/SET_FOCUSED_REQUIREMENT';
export const setFocusedRequirement = (value = {}, callback) => async dispatch => {
  dispatch({
    type: SET_FOCUSED_REQUIREMENT,
    payload: value
  });
  if (callback) {
    callback();
  }
};

export const SEARCH_INADEQUACY_LIST_LOADING = '@@questionnaireBuilder/SEARCH_INADEQUACY_LIST_LOADING';
export const SEARCH_INADEQUACY_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_INADEQUACY_LIST_SUCCESS';
export const SEARCH_INADEQUACY_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_INADEQUACY_LIST_FAILURE';

export const getInadequacyList = (requirementId = null) => async (dispatch) => {
  dispatch({ type: SEARCH_INADEQUACY_LIST_LOADING });
  try {
    const body = getInadequacyListSearchBody(requirementId);
    const response = await axios.post(`/questionnairetemplate/requirement/inadequacyreason/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_INADEQUACY_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_INADEQUACY_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INADEQUACY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_INADEQUACY_REASONS_LOADING = '@@questionnaireBuilder/POST_INADEQUACY_REASONS_LOADING';
export const POST_INADEQUACY_REASONS_SUCCESS = '@@questionnaireBuilder/POST_INADEQUACY_REASONS_SUCCESS';
export const POST_INADEQUACY_REASONS_FAILURE = '@@questionnaireBuilder/POST_INADEQUACY_REASONS_FAILURE';

export const addInadequacyReasons = (questionnaireTemplateId, requirementId, formData, status, callback) => async (dispatch) => {
  dispatch({ type: POST_INADEQUACY_REASONS_LOADING });
  try {
    const body = addInadequacyReasonsBody(formData);
    const response = await axios.post(`/questionnairetemplate/${questionnaireTemplateId}/requirement/${requirementId}/inadequacyreason`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: POST_INADEQUACY_REASONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_INADEQUACY_REASONS_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(getInadequacyList(requirementId));
      if (callback) {
        callback();
      }
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: questionnaireTemplateId,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          }, () => {
            dispatch(getQuestionnaireTemplate(questionnaireTemplateId));
          }))
      }
    }
  } catch (err) {
    dispatch({
      type: POST_INADEQUACY_REASONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_INADEQUACY_REASONS_LOADING = '@@questionnaireBuilder/PUT_INADEQUACY_REASONS_LOADING';
export const PUT_INADEQUACY_REASONS_SUCCESS = '@@questionnaireBuilder/PUT_INADEQUACY_REASONS_SUCCESS';
export const PUT_INADEQUACY_REASONS_FAILURE = '@@questionnaireBuilder/PUT_INADEQUACY_REASONS_FAILURE';

export const updateInadequacyReasons = (selectedInadequacyReason, requirementId, formData, questionnaireTemplateId, status, callback) => async (dispatch) => {
  dispatch({ type: PUT_INADEQUACY_REASONS_LOADING });
  let body = updateInadequacyReasonsBody(formData);
  try {
    const response = await axios.put(`/questionnairetemplate/requirement/${selectedInadequacyReason?.RequirementInadequacyReasonId}/inadequacyreason/${selectedInadequacyReason?.InadequacyReasonId}`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: PUT_INADEQUACY_REASONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: PUT_INADEQUACY_REASONS_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(getInadequacyList(requirementId));
      if (callback) {
        callback();
      }
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: questionnaireTemplateId,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          }, () => {
            dispatch(getQuestionnaireTemplate(questionnaireTemplateId));
          }))
      }
    }
  } catch (err) {
    dispatch({
      type: PUT_INADEQUACY_REASONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRETEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_LOADING';
export const GET_QUESTIONNAIRETEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_SUCCESS';
export const GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE';

export const getQuestionnaireTemplateQuestion = (questionText, questionTypeId) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRETEMPLATE_QUESTION_LOADING });
  try {
    let body;
    body = getQuestionnaireTemplateQuestionBody(questionText, questionTypeId);

    const response = await axios.post(`/questionnairetemplate/question/search`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRETEMPLATE_QUESTION_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_QUESTION_FORCE_UPDATE = '@questionnaireBuilder/SET_ASSESSMENT_QUESTION_FORCE_UPDATE';
export const setAssessmentQuestionForceUpdate = (value) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENT_QUESTION_FORCE_UPDATE,
    payload: value,
  });
};

export const SET_SELECTED_INADEQUACY_REASON = '@questionnaireBuilder/SET_SELECTED_INADEQUACY_REASON';
export const setSelectedInadequacyReason = (value = {}, callback) => async dispatch => {
  dispatch({
    type: SET_SELECTED_INADEQUACY_REASON,
    payload: value
  });
  if (callback) {
    callback();
  }
};

export const SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING = '@@questionnaireBuilder/SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING';
export const SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS';
export const SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE';

export const getMasterIPIList = () => async (dispatch) => {
  dispatch({ type: SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING });
  try {
    const body = getMasterIPIListSearchBody();
    const response = await axios.post(`/questionnairetemplate/inadequacyreason/incrementalprogressindicator/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING = '@@questionnaireBuilder/SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING';
export const SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS';
export const SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE';

export const getIPIList = (questionnaireTemplateReqId = null, reqInadequacyReasonId = null, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING });
  try {
    const body = getIPIListSearchBody(questionnaireTemplateReqId, reqInadequacyReasonId);
    const response = await axios.post(`/questionnairetemplate/requirement/inadequacyreason/incrementalprogressindicator/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const REORDER_INCREMENTAL_PROGRESS_INDICATOR_LOADING = '@@questionnaireBuilder/REORDER_INCREMENTAL_PROGRESS_INDICATOR_LOADING';
export const REORDER_INCREMENTAL_PROGRESS_INDICATOR_SUCCESS = '@@questionnaireBuilder/REORDER_INCREMENTAL_PROGRESS_INDICATOR_SUCCESS';
export const REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE = '@@questionnaireBuilder/REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE';

export const reorderIPI = (formData, questionnaireTemplateRequirementId, requirementInadequacyReasonId, callback) => async (dispatch) => {
  dispatch({
    type: REORDER_INCREMENTAL_PROGRESS_INDICATOR_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    let response;
    response = await axios.post(`questionnairetemplate/requirement/inadequacyreason/incrementalprogressindicator/action/reorderIPI`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE,
      });
    } else {
      dispatch({
        type: REORDER_INCREMENTAL_PROGRESS_INDICATOR_SUCCESS,
        payload: response?.data,
      });
      dispatch(getIPIList(questionnaireTemplateRequirementId, requirementInadequacyReasonId));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING = '@@questionnaireBuilder/ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING';
export const ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS = '@@questionnaireBuilder/ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS';
export const ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE = '@@questionnaireBuilder/ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE';

export const addIPI = (formBody, forceUpdate = false, callback) => async (dispatch) => {
  dispatch({ type: ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING });
  let url = `/questionnairetemplate/inadequacyreason/${formBody?.IncrementalProgressIndicator?.InadequacyReasonId}/incrementalprogressindicator`;
  if (forceUpdate) {
    url = `${url}?forceUpdate=true`;
  }
  try {
    const response = await axios.post(url, formBody);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message === FORCE_UPDATE_PROGRESS_INDICATOR_CONFIRMATION);
      if (errors && errors?.length > 0) {
        dispatch(setProgressIndicatorForceUpdate(true));
      } else {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(getIPIList(formBody?.QuestionnaireTemplateRequirementId, formBody?.RequirementInadequacyReasonId));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PROGRESS_INDICATOR_FORCE_UPDATE = '@questionnaireBuilder/SET_PROGRESS_INDICATOR_FORCE_UPDATE';
export const setProgressIndicatorForceUpdate = (value) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_INDICATOR_FORCE_UPDATE,
    payload: value,
  });
};

export const SEARCH_ALL_INADEQUACY_REASONS_LIST_LOADING = '@@questionnaireBuilder/SEARCH_ALL_INADEQUACY_REASONS_LIST_LOADING';
export const SEARCH_ALL_INADEQUACY_REASONS_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_ALL_INADEQUACY_REASONS_LIST_SUCCESS';
export const SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE';

export const getAllReasonsList = () => async (dispatch) => {
  dispatch({ type: SEARCH_ALL_INADEQUACY_REASONS_LIST_LOADING });
  try {
    const body = getAllReasonsListSearchBody();
    const response = await axios.post(`/questionnairetemplate/requirement/inadequacyreason/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ALL_INADEQUACY_REASONS_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_INADEQUACY_REASONS_FOR_REQUIREMENT_LOADING = '@questionnaireBuilder/GET_INADEQUACY_REASONS_FOR_REQUIREMENT_LOADING';
export const GET_INADEQUACY_REASONS_FOR_REQUIREMENT_SUCCESS = '@questionnaireBuilder/GET_INADEQUACY_REASONS_FOR_REQUIREMENT_SUCCESS';
export const GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE = '@questionnaireBuilder/GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE';

export const getInadequacyReasonsForRequirement = (requirementId) => async (dispatch) => {
  dispatch({ type: GET_INADEQUACY_REASONS_FOR_REQUIREMENT_LOADING });
  try {
    let body;
    body = getInadequacyReasonsForRequirementBody(requirementId);

    const response = await axios.post(`/questionnairetemplate/requirement/inadequacyreason/search`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_INADEQUACY_REASONS_FOR_REQUIREMENT_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_INADEQUACY_REASONS_OPTION_ID_LOADING = '@questionnaireBuilder/GET_INADEQUACY_REASONS_OPTION_ID_LOADING';
export const GET_INADEQUACY_REASONS_OPTION_ID_SUCCESS = '@questionnaireBuilder/GET_INADEQUACY_REASONS_OPTION_ID_SUCCESS';
export const GET_INADEQUACY_REASONS_OPTION_ID_FAILURE = '@questionnaireBuilder/GET_INADEQUACY_REASONS_OPTION_ID_FAILURE';

export const getInadequacyReasonsOptionId = (requirementId, optionId) => async (dispatch) => {
  dispatch({ type: GET_INADEQUACY_REASONS_OPTION_ID_LOADING });
  try {
    let body;
    body = getInadequacyReasonsOptionIdBody(requirementId, optionId);

    const response = await axios.post(`/questionnairetemplate/requirement/inadequacyreason/search`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_INADEQUACY_REASONS_OPTION_ID_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_INADEQUACY_REASONS_OPTION_ID_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INADEQUACY_REASONS_OPTION_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_RESPONSE_CONFIGURATION_LOADING = '@questionnaireBuilder/UPDATE_RESPONSE_CONFIGURATION_LOADING';
export const UPDATE_RESPONSE_CONFIGURATION_SUCCESS = '@questionnaireBuilder/UPDATE_RESPONSE_CONFIGURATION_SUCCESS';
export const UPDATE_RESPONSE_CONFIGURATION_FAILURE = '@questionnaireBuilder/UPDATE_RESPONSE_CONFIGURATION_FAILURE';

export const updateResponseConfiguration = (formData) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESPONSE_CONFIGURATION_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`/questionnairetemplate/requirement/${formData?.RequirementId}/question/${formData?.QuestionId}/responseoption/${formData?.QuestionResponseOptionId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_RESPONSE_CONFIGURATION_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_RESPONSE_CONFIGURATION_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_RESPONSE_CONFIGURATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';
    
export const associateQuestionnaireTemplateQuestion = (id, status, formData, callback) => async (dispatch) => {
  dispatch({
    type: ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  });
  const body = JSON.stringify(formData);
    
  try {
    const response = await axios.post(`/questionnairetemplate/${id}/question`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
      });
    } else {
      dispatch({
        type: ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
        payload: response?.data,
      });
      if (status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {
        await dispatch(
          updateStatusOfQuestionnaire({
            questionnaireTemplateId: id,
            newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
          })
        );
      }
      dispatch(getQuestionnaireTemplateQuestionList(id, formData?.requirementId, (response) => {
        callback(response)
      }));
      dispatch(getQuestionnaireTemplate(id));
    }
  } catch {
    dispatch({
      type: ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const UPDATE_REQUIREMENT_AUDIT_REVIEW_LOADING = '@questionnaireBuilder/UPDATE_REQUIREMENT_AUDIT_REVIEW_LOADING';
export const UPDATE_REQUIREMENT_AUDIT_REVIEW_SUCCESS = '@questionnaireBuilder/UPDATE_REQUIREMENT_AUDIT_REVIEW_SUCCESS';
export const UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE = '@questionnaireBuilder/UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE';
    
export const updateRequirementAuditReview = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_REQUIREMENT_AUDIT_REVIEW_LOADING });
  try {
    let body;
    body = updateRequirementAuditReviewBody(data);
    const response = await axios.post(`/questionnairetemplate/requirement/action/updaterequiresauditreview`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_REQUIREMENT_AUDIT_REVIEW_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(searchQuestionnaireRequirementList(data?.QuestionnaireTemplateId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
