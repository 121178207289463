import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import { Loading } from './components';

const IncidentList = lazy(() => import(/* webpackChunkName: "Incident" */ './pages/Incident/IncidentList'));

const IncidentCampaign = lazy(() => import(/* webpackChunkName: "IncidentCampaign" */ './pages/IncidentCampaign/IncidentCampaign'));

const QuestionnaireList = lazy(() => import(/* webpackChunkName: "QuestionnaireList" */ './pages/QuestionnaireBuilder/QuestionnaireList'));

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ './pages/Dashboard/Dashboard'));

const Assessment = lazy(() => import(/* webpackChunkName: "Assessment" */ './pages/Assessment/Assessment'));

const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ './views/profile'));

const ExternalApi = lazy(() => import(/* webpackChunkName: "ExternalApi" */ './views/external-api'));

const Session = lazy(() => import(/* webpackChunkName: "Session" */ './pages/Session/Session'));

const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/Logout/Logout'));

const ViewQuestionnaire = lazy(() => import(/* webpackChunkName: "Incident" */ './pages/QuestionnaireBuilder/ViewQuestionnaire'));

const QuestionnaireBuilder = lazy(() => import(/* webpackChunkName: "QuestionnaireBuilder" */ './pages/QuestionnaireBuilder/QuestionnaireBuilder'));

const OutreachList = lazy(() => import(/* webpackChunkName: "Outreach" */ './pages/Outreach/OutreachList'));

const OutreachBuilder = lazy(() => import(/* webpackChunkName: "OutreachBuilder" */ './pages/Outreach/Outreach'));
const IncidentBuilder = lazy(() => import(/* webpackChunkName: "IncidentBuilder" */ './pages/IncidentBuilder/IncidentBuilder'));

const Vendors = lazy(() => import(/* webpackChunkName: "Vendor" */ './pages/Vendors/Vendors'));
const VendorProfile = lazy(() => import(/* webpackChunkName: "VendorProfile" */ './pages/VendorProfile'));

const Clients = lazy(() => import(/* webpackChunkName: "Client" */ './pages/Clients'));

const ClientProfile = lazy(() => import(/* webpackChunkName: "ClientProfile" */ './pages/ClientProfile'));

const CorlUsers = lazy(() => import(/* webpackChunkName: "Users" */ './pages/Users'));

const Unauthorized = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/Unauthorized/Unauthorized'));
const Error = lazy(() => import(/* webpackChunkName: "Error" */ './pages/Error/Error'));

const ClientsCompanyInfo = lazy(() =>
  import(/* webpackChunkName: "AddClientCompanyInformation" */ './pages/ClientProfile/CompanyInformation/AddClientInformation')
);

const VendorCompanyInfo = lazy(() =>
  import(/* webpackChunkName: "AddVendorCompanyInformation" */ './pages/VendorProfile/CompanyInformation/AddVendorInformation')
);

const AllUsers = lazy(() => import(/* webpackChunkName: "AllUsers" */ './pages/AllUsers'));
const AssessmentRequestReview = lazy(() => import(/* webpackChunkName: "AssessmentRequestReview" */ './pages/AssessmentRequest/AssessmentRequestReview'));

const AssessmentRequest = lazy(() =>
  import(/* webpackChunkName: "AssessmentRequest" */ './pages/AssessmentRequest/AssessmentRequestList/AssessmentRequestList')
);

const VerifyVendor = lazy(() => import(/*webpackChunkName: "VerifyVendor"*/ './pages/VerifyVendor/VerifyVendor'));

const ProductDetails = lazy(() => import(/*webpackChunkName: "ProductDetails"*/ './pages/VendorProfile/Products/ProductDetails/ProductDetails'));

const ReplaceVendor = lazy(() => import(/*webpackChunkName: "ReplaceVendor"*/ './pages/ReplaceVendor/ReplaceVendor'));

const ProductProfileWizard = lazy(() => import(/*webpackChunkName: "ProductProfileWizard"*/ './pages/VendorProfile/Products/ProductProfileWizard/ProductProfileWizard'));

const AWSEmailMetrics = lazy(() => import(/*webpackChunkName: "ClientAWSEmailMetrics"*/ './pages/AWSEmailMetrics'));

const AssessmentDetails = lazy(() => import(/*webpackChunkName: "AssessmentDetails"*/ './pages/Assessment/AssessmentDetails/AssessmentDetails'));

const ClientEmailTemplates = lazy(() => import(/* webpackChunkName: "ClientProfile" */ './pages/ClientEmailTemplates'));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <ProtectedRoute path="/" exact component={Clients} />
      <ProtectedRoute path="/assessment" exact component={Assessment} />
      <ProtectedRoute path="/incident-list" exact component={IncidentList} />
      <ProtectedRoute path="/incident-campaign" exact component={IncidentCampaign} />
      <ProtectedRoute path="/incident-builder" exact component={IncidentBuilder} />
      <ProtectedRoute path="/incident-builder/:id" exact component={IncidentBuilder} />
      <ProtectedRoute path="/questionnaire-list/:page" exact component={QuestionnaireList} />
      <ProtectedRoute path="/assessment-questionnaire-list/:page" exact component={QuestionnaireList} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/external-api" component={ExternalApi} />
      <ProtectedRoute path="/review-questions/:id/:page" component={ViewQuestionnaire} />
      <ProtectedRoute exact path="/questionnaire-template/:id/:page" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/questionnaire-template/:page" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/assessment-questionnaire-template/:id/:page" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/assessment-questionnaire-template/:page" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/outreach/:id" component={OutreachBuilder} />
      <ProtectedRoute exact path="/outreach" component={OutreachBuilder} />
      <ProtectedRoute path="/outreach-list" component={OutreachList} />
      <ProtectedRoute exact path="/clients" component={Clients} />
      <ProtectedRoute exact path="/client-profile/:id" component={ClientProfile} />
      <Route path="/session" component={Session} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute path="/vendor" exact component={Vendors} />
      <ProtectedRoute path="/vendor-profile/:id" exact component={VendorProfile} />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/error" component={Error} />
      <ProtectedRoute path="/users" exact component={CorlUsers} />
      <ProtectedRoute path="/addNewClient" exact component={ClientsCompanyInfo} />
      <ProtectedRoute path="/addNewVendor" exact component={VendorCompanyInfo} />
      <ProtectedRoute path="/allusers-list" exact component={AllUsers} />
      <ProtectedRoute path="/assessment-request/:id" exact component={AssessmentRequestReview} />
      <ProtectedRoute path="/assessment-list" component={AssessmentRequest} />
      <ProtectedRoute path="/verify-vendor/:id" exact component={VerifyVendor} />
      <ProtectedRoute path="/product-profile-details/:vendorOrgId/:productId" exact component={ProductDetails} />
      <ProtectedRoute path="/replace-vendor/:id" exact component={ReplaceVendor} />
      <ProtectedRoute path="/vendor-profile/:id/product-profile-wizard" exact component={ProductProfileWizard} />
      {/* Commented for future use <ProtectedRoute path="/email-metrics" exact component={AWSEmailMetrics} /> */}
      <ProtectedRoute path="/assessment-details/:id" exact component={AssessmentDetails} />
      <ProtectedRoute exact path="/client-email-templates/:id/template/:tab" component={ClientEmailTemplates} />
    </Suspense>
  );
}
