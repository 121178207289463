export const ASSESSMENT_CREATION = 1;
export const REMEDIATION_PLAN_ASSIGNED_TO_VENDOR = 2;
export const OUTREACH_ATTEMPT_1 = 4;
export const OUTREACH_ATTEMPT_2 = 5;
export const OUTREACH_ATTEMPT_3 = 6;

export const ASSESSMENT_CREATION_TITLE = 'Assessment Creation';
export const REMEDIATION_PLAN_ASSIGNED_TO_VENDOR_TITLE = 'Remediation Plan Assigned to Vendor';
export const SUBMITTED_ASSESSMENT_TITLE = 'Submitted Assessment';

export const EMAIL_TEMPLATE_TYPE_ENTITY_ID = 114;

export const editEmailTemplatesField = [
  {
    label: 'Subject',
    name: 'EmailSubject',
    tstype: 'text',
    variant: 'standard',
  },
  {
    label: 'Body',
    name: 'EmailBody',
    tstype: 'richTextEditor',
  },
  {
    label: 'Signature',
    name: 'EmailSignature',
    tstype: 'richTextEditor',
  },
];

export const PKID_ZERO = 0;

export const SUBJECT = 'subject';
export const BODY = 'body';
export const SIGNATURE = 'signature';

export const DYNAMIC_FIELDS = [
  {
    value: 'clientName',
    label: 'Client Name',
  },
  {
    value: 'vendorName',
    label: 'Vendor Name',
  },
  {
    value: 'productName',
    label: 'Product Name',
  },
  {
    value: 'vendorDueDate',
    label: 'Vendor Response Due Date',
  },
];

export const REGEX_LAST_OCCURRENCE = /@(?!.*@)/;

export const EMAIL_CUSTOMIZATION_ENABLED = 1;

export const UNSAVED_DETAILS_MESSAGE = 'Save changes before leaving';

export const KEY_TO_TRIGGER = '@';

export const STAY = 'Stay';
export const LEAVE = 'Leave';
