export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          // marginTop: "29px",
        },
        icon: {
          right: '24px',
        },
        select: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
}
