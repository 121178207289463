import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from '../constants/errorMessage';
import { getIncidentListSearchBody, getIncidentListSearchBodyOnName } from '../utils/incidentUtil';
import { isNil } from 'lodash';

export const SEARCH_INCIDENT_LIST_LOADING = '@incident/SEARCH_INCIDENT_LIST_LOADING';
export const SEARCH_INCIDENT_LIST_SUCCESS = '@incident/SEARCH_INCIDENT_LIST_SUCCESS';
export const SEARCH_INCIDENT_LIST_FAILURE = '@incident/SEARCH_INCIDENT_LIST_FAILURE';

export const getIncidentListData = (searchIncidentName) => async (dispatch) => {
  dispatch({ type: SEARCH_INCIDENT_LIST_LOADING });
  try {
    let body;
    if (!isNil(searchIncidentName)) {
      body = getIncidentListSearchBodyOnName(searchIncidentName);
    } else {
      body = getIncidentListSearchBody();
    }
    const response = await axios.post(`/incident/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_INCIDENT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_INCIDENT_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INCIDENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_INCIDENT_LOADING = '@incident/DELETE_INCIDENT_LOADING';
export const DELETE_INCIDENT_SUCCESS = '@incident/DELETE_INCIDENT_SUCCESS';
export const DELETE_INCIDENT_FAILURE = '@incident/DELETE_INCIDENT_FAILURE';

export const deleteIncident = (incidentId) => async (dispatch) => {
  dispatch({ type: DELETE_INCIDENT_LOADING });
  try {
    const response = await axios.delete(`/incident/${incidentId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_INCIDENT_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_INCIDENT_SUCCESS,
      });
      dispatch(getIncidentListData());
    }
  } catch (err) {
    dispatch({
      type: DELETE_INCIDENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
