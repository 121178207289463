import { isEmpty, isNil, isNull } from 'lodash';
import {
  DRAFT_STATUS,
  QA_APPROVED_STATUS,
  READY_FOR_QA_STATUS,
  REQUIREMENT_AUDIT_REVIEW_DISABLED,
  REQUIREMENT_AUDIT_REVIEW_ENABLED,
} from '../pages/IncidentBuilder/constants';
import {
  QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT,
  REQUIREMENT_TYPE_VALUE_FOR_CONTROL_BASED_TYPE,
  REQUIREMENT_TYPE_VALUE_FOR_CORL_CLEARED_TYPE,
  REQUIREMENT_TYPE_VALUE_FOR_SUPPLEMENTAL_TYPE,
} from 'pages/QuestionnaireBuilder/constants';

export const getIncidentListSearchBody = () => {
  return {
    intent: '',
    fields: ['IncidentName', 'IncidentType', 'Status'],
    criterion: [
      {
        field: 'IncidentStatusId',
        operator: 'in',
        value: `${DRAFT_STATUS}, ${READY_FOR_QA_STATUS}, ${QA_APPROVED_STATUS}`,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireListSearchBody = (QuestionnaireTemplateTypeId) => {

  let criterion = []

  if(!isNil(QuestionnaireTemplateTypeId)) {
    criterion.push({
      field: 'QuestionnaireTemplateTypeId',
      operator: 'in',
      value: QuestionnaireTemplateTypeId,
    })
  }

  return {
    intent: '',
    fields: [
      'Status',
      'QuestionnaireTemplateName',
      'QuestionnaireTemplateType',
      'ReferenceId',
      'ReferenceName',
      'QuestionCount',
      'LastUpdated',
      'QuestionnaireTemplateId',
      'LastEditedUserId',
      'LastEditedUserName',
      'QuestionnaireTemplateSubType',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireListSearchBodyOnName = (searchQuestionnaireName, QuestionnaireTemplateTypeId) => {

  const criterion = [];
  if (!isNil(searchQuestionnaireName)) {
    criterion.push({
      field: 'QuestionnaireTemplateName',
      operator: 'contains',
      value: searchQuestionnaireName,
    });
  }
  if (!isNil(QuestionnaireTemplateTypeId)) {
    criterion.push({
      field: 'QuestionnaireTemplateTypeId',
      operator: 'in',
      value: QuestionnaireTemplateTypeId,
    });
  }
  return {
    intent: '',
    fields: [
      'Status',
      'QuestionnaireTemplateName',
      'QuestionnaireTemplateType',
      'ReferenceId',
      'ReferenceName',
      'QuestionCount',
      'LastUpdated',
      'QuestionnaireTemplateId',
      'LastEditedUserId',
      'LastEditedUserName',
      'QuestionnaireTemplateSubType',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAllQuestionnaireRequirementsListSearchBody = (searchRequirementTypes = null) => {
  const criterion = [];
  const fields = [
    'RequirementId',
    'RequirementName',
    'RequirementNumber',
    'RequirementType',
    'RequirementGroup',
    'ControlNumber',
    'DefaultTier',
    'RequirementExpiration',
    'ExpirationInDays',
  ];
  
    fields.push('MaximumControlNumber');

  if (!isNull(searchRequirementTypes)) {
    criterion.push({
      field: 'RequirementTypeId',
      operator: 'in',
      value: searchRequirementTypes,
    });
  }

  return {
    intent: '',
    fields: fields,
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireRequirementListSearchBody = (QuestionnaireTemplateId) => {
  const criterion = [];
  if (!isNil(QuestionnaireTemplateId)) {
    criterion.push({
      field: 'QuestionnaireTemplateId',
      operator: '=',
      value: QuestionnaireTemplateId,
    });
  }
  return {
    intent: '',
    fields: [
      'RequirementId',
      'QuestionnaireTemplateId',
      'RequirementName',
      'RequirementNumber',
      'RequirementType',
      'RequirementGroup',
      'ControlNumber',
      'QuestionnaireTemplateRequirementId',
      'DefaultTier',
      'RequirementExpiration',
      'ExpirationInDays',
      'IsAuditorReviewRequired',
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const addQuestionnaireRequirementSearchBody = (data) => {
  return (
    data && {
      RequirementId: data?.RequirementId ?? 0,
      RequirementName: data?.RequirementName,
      RequirementTypeId: data?.RequirementTypeId,
      RequirementGroupId: data?.RequirementGroupId,
      ControlNumber: data?.ControlNumber,
      RequirementNumber: data?.RequirementNumber,
      DefaultTierId: data?.DefaultTierId,
      ExpirationId: data?.ExpirationId,
      ExpirationInDays: data?.ExpirationInDays,
    }
  );
};

export const updateQuestionnaireRequirementListSearchBody = (data) => {
  return (
    data && {
      RequirementId: data?.RequirementId ?? 0,
      RequirementName: data?.RequirementName,
      RequirementTypeId: data?.RequirementTypeId,
      RequirementGroupId: data?.RequirementGroupId,
      ControlNumber: data?.ControlNumber,
      RequirementNumber: data?.RequirementNumber,
      DefaultTierId: data?.DefaultTierId,
      ExpirationId: data?.ExpirationId,
      ExpirationInDays: data?.ExpirationInDays,
    }
  );
};

export const getQuestionnaireTemplateQuestionListBody = (questionnaireTemplateId, requirementId) => {
  const baseCriterion = [
    {
      field: 'QuestionnaireTemplateId',
      operator: '=',
      value: questionnaireTemplateId,
    },
    {
      field: 'ParentQuestionId',
      operator: '=',
      value: 0,
    },
  ];

  // Conditionally add the RequirementId criterion
  if (requirementId !== 0) {
    baseCriterion.push({
      field: 'RequirementId',
      operator: '=',
      value: requirementId,
    });
  }
  return {
    intent: '',
    fields: [
      'QuestionnaireTemplateId',
      'QuestionId',
      'QuestionType',
      'QuestionText',
      'QuestionResponseType',
      'DisplayOrder',
      'IsRequired',
      'ParentQuestionId',
      'RequirementId',
      'QuestionnaireTemplateQuestionId',
      'QuestionLogicRuleList',
      'QuestionMetaDataList',
      'QuestionResponseOptionList',
      'ChildQuestionList',
      'LastEditedUserName',
      'AllowCommentInResponse',
      'AllowFileUpload',
      'QuestionResponseOptionInadequacyReasonList',
      'QuestionPlainText',
    ],
    criterion: baseCriterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCreateNewVersionQuestionnaireBody = (data, versionType) => {
  return {
    QuestionnaireTemplateId: data?.QuestionnaireTemplateId,
    versionTypeId: versionType,
  };
};

export const getInadequacyListSearchBody = (requirementId) => {
  return {
    Intent: '',
    Fields: ['RequirementInadequacyReasonId', 'RequirementId', 'InadequacyReasonId', 'Name', 'Description', 'Recommendation'],
    Criterion: [
      {
        Field: 'RequirementId',
        Operator: '=',
        Value: requirementId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getMasterIPIListSearchBody = () => {
  return {
    intent: '',
    fields: ['Name', 'Description', 'DueDays', 'ExtensionDays', 'AllowComment', 'IsEvidenceRequired', 'IsAuditReviewRequired'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getIPIListSearchBody = (questionnaireTemplateReqId, reqInadequacyReasonId) => {
  let criterion = [];
  if (!isNil(questionnaireTemplateReqId)) {
    criterion.push({
      Field: 'QuestionnaireTemplateRequirementId',
      Operator: '=',
      Value: questionnaireTemplateReqId,
    });
  }
  if (!isNil(reqInadequacyReasonId)) {
    criterion.push({
      Field: 'RequirementInadequacyReasonId',
      Operator: '=',
      Value: reqInadequacyReasonId,
    });
  }
  return {
    intent: '',
    fields: [
      'QuestionnaireTemplateRequirementId',
      'RequirementInadequacyReasonId',
      'Name',
      'Description',
      'DueDays',
      'ExtensionDays',
      'AllowComment',
      'IsEvidenceRequired',
      'IsAuditReviewRequired',
      'InadequacyReasonIPIId',
      'InadequacyReasonId',
      'IncrementalProgressIndicatorId',
      'DisplayOrder',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireTemplateQuestionBody = (questionText, questionTypeId) => {
  const criterion = [
    {
      field: 'QuestionText',
      operator: '=',
      value: questionText,
    },
    {
      field: 'QuestionType',
      operator: '=',
      value: questionTypeId,
    },
  ];

  return {
    intent: '',
    fields: ['QuestionType', 'QuestionText', 'QuestionResponseType', 'AllowCommentInResponse', 'AllowFileUpload', 'OnlyQuestion', 'QuestionResponseOptionList'],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getInadequacyReasonsForRequirementBody = (requirementId) => {
  return {
    Intent: '',
    Fields: ['InadequacyReasonId', 'Description', 'RequirementInadequacyReasonId', 'RequirementId', 'Name'],
    Criterion: [
      {
        Field: 'RequirementId',
        Operator: '=',
        Value: requirementId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getInadequacyReasonsOptionIdBody = (requirementId, optionId) => {
  return {
    Intent: '',
    Fields: [
      'InadequacyReasonId',
      'Description',
      'Name',
      'Recommendation',
      'QuestionResponseOptionId',
      'ResponseOptionText',
      'ResponseOptionPlainText',
      'RequirementInadequacyReasonId',
      'RequirementId',
      'QuestionResponseOptionInadequacyReasonId',
    ],
    Criterion: [
      {
        Field: 'RequirementId',
        Operator: '=',
        Value: requirementId,
      },
      {
        Field: 'QuestionResponseOptionId',
        Operator: '=',
        Value: optionId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAllReasonsListSearchBody = () => {
  return {
    Intent: '',
    Fields: ['InadequacyReasonId', 'Name', 'Description', 'Recommendation'],
    Criterion: [],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const updateInadequacyReasonsBody = (body) => {
  return {
    InadequacyReasonId: body?.ReasonId ?? 0,
    Name: body?.Name,
    Description: body?.Description,
    Recommendation: body?.Recommendation,
  };
};

export const addInadequacyReasonsBody = (body) => {
  return {
    InadequacyReasonId: body?.InadequacyReasonId ?? 0,
    Name: body?.Name,
    Description: body?.Description,
    Recommendation: body?.Recommendation,
  };
};

export const getLookupQuestionListBody = (page, pageSize, searchValue) => {

let criterion = [
  {
    "field": "ParentQuestionId",
    "operator": "=",
    "value": 0
  },
  {
    "field": "IsFromCompanionImported",
    "operator": "=",
    "value": 0
  },
  {
    "field": "UniqueQuestion",
    "operator": "=",
    "value": 1
  },
];

  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'QuestionText',
      operator: 'contains',
      value: searchValue,
    });
  }

  return {
    intent: '',
    fields: [
      'QuestionnaireTemplateId',
      'QuestionResponseOptionList',
      'QuestionId',
      'QuestionType',
      'QuestionText',
      'QuestionResponseType',
      'DisplayOrder',
      'IsRequired',
      'ParentQuestionId',
      'RequirementId',
      'QuestionnaireTemplateQuestionId',
      'LastEditedUserName',
      'AllowCommentInResponse',
      'AllowFileUpload',
      'QuestionPlainText',
    ],
    criterion: criterion,
    sort: [],
    page: page,
    pageSize: pageSize,
  };
};

export const updateRequirementAuditReviewBody = (data) => {
  return {
    QuestionnaireTemplateRequirementId: data?.QuestionnaireTemplateRequirementId ?? 0,
    IsAuditorReviewRequired: data?.IsAuditorReviewRequired ? REQUIREMENT_AUDIT_REVIEW_DISABLED : REQUIREMENT_AUDIT_REVIEW_ENABLED,
  };
};
