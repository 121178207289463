import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { isNil } from 'lodash';
import { getQuestionnaireListSearchBody, getQuestionnaireListSearchBodyOnName } from '../utils/questionnaireBuilderUtil';
import { NO_DATA_FOUND } from '../constants/errorMessage';
import { PAGE_ASSESSMENT, PAGE_INCIDENT, QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT, QUESTIONNAIRE_TEMPLATE_TYPE_FOR_SUPPLEMENTAL_TYPE, QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT } from 'pages/QuestionnaireBuilder/constants';

export const GET_QUESTIONNAIRE_LIST_DATA_REQUEST = 'GET_QUESTIONNAIRE_LIST_DATA_REQUEST';
export const GET_QUESTIONNAIRE_LIST_DATA_SUCCESS = 'GET_QUESTIONNAIRE_LIST_DATA_SUCCESS';
export const GET_QUESTIONNAIRE_LIST_DATA_FAILURE = 'GET_QUESTIONNAIRE_LIST_DATA_FAILURE';

export const getQuestionnaireListData = (page,searchQuestionnaireName, callback) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_LIST_DATA_REQUEST });
  try {
    let body;
    let QuestionnaireTemplateTypeId;
    if(page === PAGE_INCIDENT){
      QuestionnaireTemplateTypeId = `${QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT}`;
    }else if(page === PAGE_ASSESSMENT){
      QuestionnaireTemplateTypeId = `${QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT},${QUESTIONNAIRE_TEMPLATE_TYPE_FOR_SUPPLEMENTAL_TYPE}`;
    }
    if (!isNil(searchQuestionnaireName)) {
      body = getQuestionnaireListSearchBodyOnName(searchQuestionnaireName,QuestionnaireTemplateTypeId);
    } else {
      body = getQuestionnaireListSearchBody(QuestionnaireTemplateTypeId);
    }
    const response = await axios.post(`/questionnairetemplate/search`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_LIST_DATA_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if(callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_QUESTIONNAIRE_LOADING = '@incident/DELETE_QUESTIONNAIRE_LOADING';
export const DELETE_QUESTIONNAIRE_SUCCESS = '@incident/DELETE_QUESTIONNAIRE_SUCCESS';
export const DELETE_QUESTIONNAIRE_FAILURE = '@incident/DELETE_QUESTIONNAIRE_FAILURE';

export const deleteQuestionnaire = (questionnaireId, page) => async (dispatch) => {
  dispatch({ type: DELETE_QUESTIONNAIRE_LOADING });
  try {
    const response = await axios.delete(`/questionnairetemplate/${questionnaireId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_QUESTIONNAIRE_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_QUESTIONNAIRE_SUCCESS,
      });
      dispatch(getQuestionnaireListData(page));
    }
  } catch (err) {
    dispatch({
      type: DELETE_QUESTIONNAIRE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
