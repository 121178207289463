import axios from 'axios';
import { isNil } from 'lodash';
import { getErrorObject } from 'utils/apiUtils';
import {
  getAssessmentOverviewBody,
  getEvidenceReviewBody,
  getRiskRatingControlSummaryBody,
  getRiskRatingOverviewBody,
  getRiskFindingSummaryBody,
  getAssessmentByIdBody,
  getEvidenceReviewAccordionBody,
} from 'utils/assessmentResultsUtils';
import { setErrors } from './errorHandler';

export const SET_EXPAND_ALL_SECTIONS_SUCCESS = '@assessmentResults/SET_EXPAND_ALL_SECTIONS_SUCCESS';

export const setExpandAllSections = (payload, callback) => async (dispatch) => {
  if (!isNil(payload)) {
    dispatch({
      type: SET_EXPAND_ALL_SECTIONS_SUCCESS,
      payload,
    });
  }
  if (callback) {
    callback();
  }
};

export const SET_LOADING_EXPANDED_SECTIONS = '@assessmentResults/SET_LOADING_EXPANDED_SECTIONS';

export const setLoadingExpandedSections = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_EXPANDED_SECTIONS,
    payload,
  });
};

export const CLEAR_ASSESSMENT_RESULTS = '@assessmentResults/CLEAR_ASSESSMENT_RESULTS';

export const clearAssessmentResults = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ASSESSMENT_RESULTS,
  });
};

export const SEARCH_RISK_RATING_OVERVIEW_LOADING = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_LOADING';
export const SEARCH_RISK_RATING_OVERVIEW_SUCCESS = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_SUCCESS';
export const SEARCH_RISK_RATING_OVERVIEW_FAILURE = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_FAILURE';

export const getRiskRatingOverviewData = (id = null) => async (dispatch) => {
  dispatch({ type: SEARCH_RISK_RATING_OVERVIEW_LOADING });
  try {
    const body = getRiskRatingOverviewBody(id);
    const response = await axios.post(`assessment/assessmentsummary/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_RISK_RATING_OVERVIEW_SUCCESS,
        payload: response?.data?.Data?.Rows[0] || {},
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_OVERVIEW_LOADING = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_LOADING';
export const SEARCH_ASSESSMENT_OVERVIEW_SUCCESS = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_SUCCESS';
export const SEARCH_ASSESSMENT_OVERVIEW_FAILURE = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_FAILURE';

export const getAssessmentOverviewData = (id = null) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_OVERVIEW_LOADING });
  try {
    const body = getAssessmentOverviewBody(id);
    const response = await axios.post(`assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE';

export const getRiskRatingControlSummaryData =
  (id = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING });
      try {
        const body = getRiskRatingControlSummaryBody(id);
        const response = await axios.post(`assessment/productvendor/recommendations/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_EVIDENCE_REVIEW_LOADING = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_LOADING';
export const SEARCH_EVIDENCE_REVIEW_SUCCESS = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_SUCCESS';
export const SEARCH_EVIDENCE_REVIEW_FAILURE = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_FAILURE';

export const getEvidenceReviewData = (assessmentId = null) => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_REVIEW_LOADING });
  try {
      const body = getEvidenceReviewBody(assessmentId);
    const response = await axios.post(`serviceprovider/assessmentquestionnairerequirement/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_EVIDENCE_REVIEW_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_REVIEW_SUCCESS,
        payload: response?.data?.Data?.Rows || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_REVIEW_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_EVIDENCE_REVIEW_ACCORDION_LOADING = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_ACCORDION_LOADING';
export const SEARCH_EVIDENCE_REVIEW_ACCORDION_SUCCESS = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_ACCORDION_SUCCESS';
export const SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE';

export const getEvidenceReviewAccordionData = (assessmentQuestionnaireRequirementId) => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_REVIEW_ACCORDION_LOADING });
  try {
    const body = getEvidenceReviewAccordionBody(assessmentQuestionnaireRequirementId);
    const response = await axios.post(`serviceprovider/assessmentquestionnairerequirement/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_REVIEW_ACCORDION_SUCCESS,
        payload: response?.data?.Data?.Rows[0] || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_REVIEW_ACCORDION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_RISK_FINDING_SUMMARY_LOADING = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_LOADING';
export const SEARCH_RISK_FINDING_SUMMARY_SUCCESS = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_SUCCESS';
export const SEARCH_RISK_FINDING_SUMMARY_FAILURE = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_FAILURE';

export const getRiskFindingSummaryData =
  (id = null, clientOrgId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_FINDING_SUMMARY_LOADING });
      try {
        const body = getRiskFindingSummaryBody(id, clientOrgId);
        const response = await axios.post(`assessment/productvendor/recommendations/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SAVE_ES_FEEDBACK_LOADING = '@assessmentResults/SAVE_ES_FEEDBACK_LOADING';
export const SAVE_ES_FEEDBACK_SUCCESS = '@assessmentResults/SAVE_ES_FEEDBACK_SUCCESS';
export const SAVE_ES_FEEDBACK_FAILURE = '@assessmentResults/SAVE_ES_FEEDBACK_FAILURE';

export const saveESFeedback = (feedback) => async (dispatch) => {
  dispatch({ type: SAVE_ES_FEEDBACK_LOADING });
  try {
    const response = await axios.post('/client/executivesummary/feedback', feedback);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SAVE_ES_FEEDBACK_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SAVE_ES_FEEDBACK_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ES_FEEDBACK_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_BY_ID_LOADING = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_LOADING';
export const SEARCH_ASSESSMENT_BY_ID_SUCCESS = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_SUCCESS';
export const SEARCH_ASSESSMENT_BY_ID_FAILURE = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_FAILURE';

export const getAssessmentById = (id) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_BY_ID_LOADING });
  try {
    const body = getAssessmentByIdBody(id);
    const response = await axios.post(`/legacy/assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};