import { isEmpty, isNil, omit } from 'lodash';

const getSortBody = (sortModel) => {
  let sort = [];
  sortModel?.map((item) => {
    let obj = {};
    obj = {
      ...item,
      order: item?.sort?.toUpperCase(),
    };
    const omittedData = omit(obj, 'sort');
    sort.push(omittedData);
  });

  return sort;
};

export const getAllPortalUserListSearchBody = (formData, page, pageSize, sortModel) => {
  const criterion = [];
  if (!isNil(formData?.AssociationType)) {
    criterion.push({
      field: 'AppId',
      operator: '=',
      value: formData?.AssociationType,
    });
  }
  if (!isNil(formData?.OrganizationType) && formData?.OrganizationType?.length > 0) {
    criterion.push({
      Field: 'OrgId',
      Operator: 'in',
      Value: formData?.OrganizationType.join(','),
    });
  }
  if (!isNil(formData?.SearchValue)) {
    criterion.push({
      field: 'UserEmailFirstName',
      operator: 'startswith',
      value: formData?.SearchValue,
    });
  }

  let sort = getSortBody(sortModel);

  return {
    intent: '',
    fields: ['OrgId', 'OrgName', 'UserId', 'UserEmail', 'FirstName', 'MiddleName', 'LastName', 'UserRoleList', 'CountOfUsers', 'AppId', 'UserRoleName'],
    criterion: criterion,
    sort: sort.length > 0 ? sort : [],
    page: page,
    pageSize: pageSize,
  };
};

export const getOrganisationListSearchBody = (appId, page, searchValue) => {
  const criterion = [];
  if (appId !== 0) {
    criterion.push({
      field: 'AppId',
      operator: '=',
      value: appId,
    });
  }
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'OrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['OrgName'],
    criterion: criterion,
    sort: [
      {
        field: 'OrgName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getUserDetailsSearchBody = (userId) => {
  return {
    intent: '',
    fields: [
      'UserEmail',
      'FirstName',
      'MiddleName',
      'LastName',
      'NamePrefix',
      'NameSuffix',
      'ProfilePictureUrl',
      'ExternalIdpId',
      'VendorOrgId',
      'VendorOrgName',
      'ClientOrgId',
      'ClientOrgName',
      'ServiceProviderOrgId',
      'ServiceProviderOrgName',
      'AllUserList',
      'UserRoleList',
      'Active',
    ],
    criterion: [
      {
        field: 'UserId',
        operator: '=',
        value: userId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
