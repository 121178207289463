import { isArray } from 'lodash';
import { useSelector } from 'react-redux';

const useHasClaims = (claims) => {
  const userClaimList = useSelector((state) => state?.session?.details?.FeatureActionClaimList);
  return hasClaims(claims, userClaimList);
};

export default useHasClaims;

export const hasClaims = (claims, userClaimList) => {
  if (isArray(userClaimList)) {
    for (const claim of claims) {
      if (userClaimList.filter((userClaim) => userClaim?.ActionKey.toUpperCase() === claim.toUpperCase()).length > 0) {
        return true;
      }
    }
  }

  return false;
};
