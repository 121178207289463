import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { NO, YES } from 'constants/users';
import { useEffect, useState } from 'react';
import { buttonRightMargin, buttonStyle, modalStyle } from '../../pages/Users/usersStyles';
import { isNil } from 'lodash';

const DeleteConfirmationPopup = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props?.open]);

  const handleClose = () => {
    setOpen(false);
    if (props.closePopup) {
      props.closePopup();
    }
  };

  const handleSelection = (option) => {
    setOpen(false);
    if (props.optionSelected) {
      !isNil(props.deleteIndex) ? props.optionSelected(option, props.deleteIndex) : props.optionSelected(option);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h4" mb={2}>
            {props?.questionTitle ? props?.questionTitle : 'Delete Confirmation' }
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            {props.question ? props.question : `Are you sure you want to delete ${props?.deleteValue || ''} ?`}
          </Typography>
          <Box sx={buttonStyle}>
            <Button color="info" sx={buttonRightMargin} onClick={() => handleSelection(NO)}>
              Cancel
            </Button>

            <Button color="info" variant="contained" onClick={() => handleSelection(YES)} autoFocus>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationPopup;
