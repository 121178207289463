export const IS_CORL_CUSTOMER_NO_STRING = 'No';
export const IS_CORL_CUSTOMER_NO = 0;
export const IS_CORL_CUSTOMER_YES = 1;
export const PORTAL_ASSESSMENT_ROUTING_DISABLED = 1;
export const PORTAL_ASSESSMENT_ROUTING_ENABLED = 2;
export const IS_CO_PILOT_DISABLED = 1;
export const IS_CO_PILOT_ENABLED = 2;
export const IS_COMPANION_ASSESSMENT_DISABLED = 1;
export const IS_COMPANION_ASSESSMENT_ENABLED = 2;
export const IS_CORLCLEARED_COPILOT_DISABLED=1;
export const IS_CORLCLEARED_COPILOT_ENABLED=2;
export const DATA_REUSE_ENABLED=2;