import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getEmailTemplatesSearchBody } from 'utils/emailTemplatesUtils';
import { setErrors } from './errorHandler';

export const GET_EMAIL_TEMPLATES_LOADING = '@emailTemplates/GET_EMAIL_TEMPLATES_LOADING';
export const GET_EMAIL_TEMPLATES_SUCCESS = '@emailTemplates/GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAILURE = '@emailTemplates/GET_EMAIL_TEMPLATES_FAILURE';

export const getEmailTemplate =
  (orgId = null, templateTypeId = null) =>
  async (dispatch) => {
    dispatch({
      type: GET_EMAIL_TEMPLATES_LOADING,
    });
    try {
      const body = getEmailTemplatesSearchBody(orgId, templateTypeId);
      const res = await axios.post(`/serviceprovider/emailtemplate/search`, body);

      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: GET_EMAIL_TEMPLATES_FAILURE,
        });
      } else {
        dispatch({
          type: GET_EMAIL_TEMPLATES_SUCCESS,
          payload: res?.data?.Data?.Rows,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EMAIL_TEMPLATES_FAILURE,
      });
    }
  };

export const GENERATE_TEST_EMAIL_LOADING = '@emailTemplates/GENERATE_TEST_EMAIL_LOADING';
export const GENERATE_TEST_EMAIL_SUCCESS = '@emailTemplates/GENERATE_TEST_EMAIL_SUCCESS';
export const GENERATE_TEST_EMAIL_FAILURE = '@emailTemplates/GENERATE_TEST_EMAIL_FAILURE';

export const generateTestEmail =
  (body = {}) =>
  async (dispatch) => {
    dispatch({
      type: GENERATE_TEST_EMAIL_LOADING,
    });

    try {
      const res = await axios.post(`/serviceprovider/emailtemplate/action/testemail`, body);

      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: GENERATE_TEST_EMAIL_FAILURE,
        });
      } else {
        dispatch({
          type: GENERATE_TEST_EMAIL_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: GENERATE_TEST_EMAIL_FAILURE,
      });
    }
  };

export const ADD_EMAIL_TEMPLATE_LOADING = '@emailTemplates/ADD_EMAIL_TEMPLATE_LOADING';
export const ADD_EMAIL_TEMPLATE_SUCCESS = '@emailTemplates/ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = '@emailTemplates/ADD_EMAIL_TEMPLATE_FAILURE';

export const addEmailTemplate =
  (body = {}, callback = null) =>
  async (dispatch) => {
    dispatch({
      type: ADD_EMAIL_TEMPLATE_LOADING,
    });

    try {
      const res = await axios.post(`/serviceprovider/emailtemplate`, body);

      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: ADD_EMAIL_TEMPLATE_FAILURE,
        });
      } else {
        dispatch({
          type: ADD_EMAIL_TEMPLATE_SUCCESS,
        });

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch({
        type: ADD_EMAIL_TEMPLATE_FAILURE,
      });
    }
  };

export const UPDATE_EMAIL_TEMPLATE_LOADING = '@emailTemplates/UPDATE_EMAIL_TEMPLATE_LOADING';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = '@emailTemplates/UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAILURE = '@emailTemplates/UPDATE_EMAIL_TEMPLATE_FAILURE';

export const updateEmailTemplate =
  (body = {}, callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE_LOADING,
    });

    try {
      const res = await axios.put(`/serviceprovider/emailtemplate/${body?.EmailConfigId}`, body);

      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: UPDATE_EMAIL_TEMPLATE_FAILURE,
        });
      } else {
        dispatch({
          type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
        });

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_EMAIL_TEMPLATE_FAILURE,
      });
    }
  };
