import {
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST,
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
  SET_QUESTIONNAIRE_TEMPLATE_DETAILS,
  SEARCH_QUESTIONNAIRE_TEMPLATES_REQUEST,
  SEARCH_QUESTIONNAIRE_TEMPLATES_SUCCESS,
  SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE,
  SET_QUESTIONNAIRE_TEMPLATES,
} from '../actions/getQuestionnaireTemplate';
import { isNil } from 'lodash';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { DATE_FORMAT } from 'constants/constants';

const initialState = {
  loading: false,
  questionnaireDetails: {},
  securityQuestionnairesList: [],
  // searchSecurityQuestionnairesList: [],
  pageNoSecurityType: 0,
  supplementalQuestionnairesList: [],
  // searchSupplementalQuestionnairesList: [],
  pageNoSupplementalType: 0,
  scopingQuestionnairesList: [],
  // searchScopingQuestionnairesList: [],
  pageNoScopingType: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireDetails: {
          ...payload?.Data,
          CreatedDate: !isNil(payload?.Data.InsertTimestamp) && convertToLocalDateTime(payload?.Data.InsertTimestamp, DATE_FORMAT),
          LastEdited: !isNil(payload?.Data.LastUpdated) && convertToLocalDateTime(payload?.Data.LastUpdated, DATE_FORMAT),
        },
      };
    }

    case SET_QUESTIONNAIRE_TEMPLATE_DETAILS: {
      return {
        ...state,
        questionnaireDetails: payload,
      };
    }

    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATES_FAILIURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_QUESTIONNAIRE_TEMPLATES: {
      return {
        ...state,
        ...payload,
      };
    }

    default:
      return state;
  }
}
