import {
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING,
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS,
} from 'actions/vendorIncidentEscalation';
import {
  BREACH_ESCALATION_CONTACT_TYPE_ID,
  BREACH_PRIMARY_CONTACT_TYPE_ID,
  VULNERABILITY_ESCALATION_CONTACT_TYPE_ID,
  VULNERABILITY_PRIMARY_CONTACT_TYPE_ID,
} from 'components/VendorProfile/constants';

const initialState = {
  loading: false,
  customerList: [],
  breachPrimaryContactList: [],
  breachEscalationContactList: [],
  vulnerabilityPrimaryContactList: [],
  vulnerabilityEscalationContactList: [],
  vendorContactList: [],
  formDataList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerProductContactList: payload?.Data?.Rows,
        breachPrimaryContactList: payload?.Data?.Rows?.filter((breachPrimary) => breachPrimary.ContactTypeId === BREACH_PRIMARY_CONTACT_TYPE_ID),
        breachEscalationContactList: payload?.Data?.Rows?.filter((breachEscalation) => breachEscalation.ContactTypeId === BREACH_ESCALATION_CONTACT_TYPE_ID),
        vulnerabilityPrimaryContactList: payload?.Data?.Rows?.filter(
          (vulnerabilityPrimary) => vulnerabilityPrimary.ContactTypeId === VULNERABILITY_PRIMARY_CONTACT_TYPE_ID
        ),
        vulnerabilityEscalationContactList: payload?.Data?.Rows?.filter(
          (vulnerabilityEscalation) => vulnerabilityEscalation.ContactTypeId === VULNERABILITY_ESCALATION_CONTACT_TYPE_ID
        ),
      };
    }

    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        customerProductContactList: [],
        breachPrimaryContactList: [],
        breachEscalationContactList: [],
        vulnerabilityPrimaryContactList: [],
        vulnerabilityEscalationContactList: [],
      };
    }

    default:
      return state;
  }
}
