import axios from 'axios';
import { getVendorIncidentContactBody, constructIncidentResponseContactFormData, getCustomerProductContactBody } from '../utils/vendorIncidentEscalationUtils';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING';
export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS';
export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE';

export const searchCustomerProductContactList = (vendorOrgId, customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({
    type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING,
  });

  const body = getCustomerProductContactBody(vendorOrgId, customerVendorId, productVendorId);

  try {
    const response = await axios.post(`/vendor/incidentresponsecontact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST = '@@vendorIncidentEscalation/CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST';

export const clearCustomerProductContactList = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST,
  });
};
