import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { isNil } from 'lodash';
import { searchCommentsBody } from 'utils/commentsUtils';
import { setErrors } from './errorHandler';

export const SET_COMMENTS_LOADING = '@comments/SET_COMMENTS_LOADING';
export const SET_COMMENTS_SUCCESS = '@comments/SET_COMMENTS_SUCCESS';
export const SET_COMMENTS_FAILURE = '@comments/SET_COMMENTS_FAILURE';

export const setComments = (payload) => async (dispatch) => {
  dispatch({
    type: SET_COMMENTS_LOADING,
  });
  try {
    dispatch({
      type: SET_COMMENTS_SUCCESS,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SET_COMMENTS_FAILURE,
    });
  }
};

export const CLEAR_COMMENTS = '@comments/CLEAR_COMMENTS';
export const clearComments = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COMMENTS,
  });
};

export const SEARCH_COMMENTS_LOADING = '@comments/SEARCH_COMMENTS_LOADING';
export const SEARCH_COMMENTS_SUCCESS = '@comments/SEARCH_COMMENTS_SUCCESS';
export const SEARCH_COMMENTS_FAILURE = '@comments/SEARCH_COMMENTS_FAILURE';

export const searchComments = (searchItem, id) => async (dispatch) => {
  dispatch({
    type: SEARCH_COMMENTS_LOADING,
  });
  try {
    let response;
    let body = searchCommentsBody();
    if (!isNil(id)) {
      response = await axios.post(`/${searchItem}/${id}/comment/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_COMMENTS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_COMMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(setComments(response?.data?.Data?.Rows));
    }
  } catch {
    dispatch({
      type: SEARCH_COMMENTS_FAILURE,
    });
  }
};
