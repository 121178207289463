import axios from 'axios';
import { isEmpty } from 'lodash';
import { getAllVendorsInformationSearchBody, getUnverifiedVendorInformationSearchBody, getUnverifiedVendorListBody, getVendorListBody, obtainCompanyInfoReqBody } from "utils/vendorInformationUtils";
import { getErrorObject } from '../utils/apiUtils';
import { getVendorInformationSearchBody, searchSpecificVendorInformationSearchBody } from '../utils/vendorInformationUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { VENDOR_TAB_OPTIONS } from 'pages/Vendors/constants';
import { DEFAULT_ROWS_PER_PAGE } from "constants/constants";

export const GET_COMPANY_INFO_LOADING = '@@vendorInformation/GET_COMPANY_INFO_LOADING';
export const GET_COMPANY_INFO_SUCCESS = '@@vendorInformation/GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = '@@vendorInformation/GET_COMPANY_INFO_FAILURE';

export const getCompanyInformation = (vendorOrgId) => async (dispatch) => {
  dispatch({
    type: GET_COMPANY_INFO_LOADING,
  });

  const body = JSON.stringify(obtainCompanyInfoReqBody(vendorOrgId));

  try {
    const res = await axios.post(`/vendor/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COMPANY_INFO_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COMPANY_INFO_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_VENDOR_TAB_VALUE = '@@SET_VENDOR_TAB_VALUE';

export const setVendorTabValue =
  (value = VENDOR_TAB_OPTIONS.ALL_VENDORS) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_TAB_VALUE,
      payload: value,
    });
  };

export const SEARCH_VENDOR_INFO_LOADING = '@@vendorInformation/SEARCH_VENDOR_INFO_LOADING';
export const SEARCH_VENDOR_INFO_SUCCESS = '@@vendorInformation/SEARCH_VENDOR_INFO_SUCCESS';
export const SEARCH_VENDOR_INFO_FAILURE = '@@vendorInformation/SEARCH_VENDOR_INFO_FAILURE';

export const searchVendorInformation = (searchValue) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_INFO_LOADING,
  });

  const body = getVendorInformationSearchBody(searchValue);

  try {
    const res = await axios.post(`/vendor/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_VENDOR_INFO_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_INFO_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ALL_VENDORS_INFO_LOADING = '@@vendorInformation/SEARCH_ALL_VENDORS_INFO_LOADING';
export const SEARCH_ALL_VENDORS_INFO_SUCCESS = '@@vendorInformation/SEARCH_ALL_VENDORS_INFO_SUCCESS';
export const SEARCH_ALL_VENDORS_INFO_FAILURE = '@@vendorInformation/SEARCH_ALL_VENDORS_INFO_FAILURE';

export const searchAllVendorsInformation =
  (formData = null) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_ALL_VENDORS_INFO_LOADING,
    });

    const body = getAllVendorsInformationSearchBody(formData);

    try {
      const res = await axios.post(`/vendor/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_ALL_VENDORS_INFO_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_ALL_VENDORS_INFO_SUCCESS,
          payload: res?.data?.Data?.Rows,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ALL_VENDORS_INFO_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_UNVERIFIED_VENDORS_INFO_LOADING = '@@vendorInformation/SEARCH_UNVERIFIED_VENDORS_INFO_LOADING';
export const SEARCH_UNVERIFIED_VENDORS_INFO_SUCCESS = '@@vendorInformation/SEARCH_UNVERIFIED_VENDORS_INFO_SUCCESS';
export const SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE = '@@vendorInformation/SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE';

export const searchUnverifiedVendorsInformation =
  (formData = null) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_UNVERIFIED_VENDORS_INFO_LOADING,
    });

    const body = getUnverifiedVendorInformationSearchBody(formData);

    try {
      const res = await axios.post(`/vendor/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_UNVERIFIED_VENDORS_INFO_SUCCESS,
          payload: res?.data?.Data?.Rows,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_UNVERIFIED_VENDORS_INFO_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const POST_CSV_UPLOAD_LOADING = '@@vendorInformation/POST_CSV_UPLOAD_LOADING';
export const POST_CSV_UPLOAD_SUCCESS = '@@vendorInformation/POST_CSV_UPLOAD_SUCCESS';
export const POST_CSV_UPLOAD_FAILURE = '@@vendorInformation/POST_CSV_UPLOAD_FAILURE';

export const uploadVendorUsers = (vendorId, files) => async (dispatch) => {
  dispatch({
    type: POST_CSV_UPLOAD_LOADING,
  });

  try {
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      formData.append('files[]', files[key]);
    });
    const res = await axios.post(`vendor/${vendorId}/user/action/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_CSV_UPLOAD_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_CSV_UPLOAD_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_CSV_UPLOAD_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SET_FILE_UPLOAD_INFO_LOADING = '@@vendorInformation/SET_FILE_UPLOAD_INFO_LOADING';
export const SET_FILE_UPLOAD_INFO_SUCCESS = '@@vendorInformation/SET_FILE_UPLOAD_INFO_SUCCESS';
export const SET_FILE_UPLOAD_INFO_FAILURE = '@@vendorInformation/SET_FILE_UPLOAD_INFO_FAILURE';

export const setFileUploadInfoSuccess = (status) => async (dispatch) => {
  dispatch({
    type: SET_FILE_UPLOAD_INFO_LOADING,
  });
  try {
    dispatch({
      type: SET_FILE_UPLOAD_INFO_SUCCESS,
      payload: status,
    });
  } catch (err) {
    dispatch({
      type: SET_FILE_UPLOAD_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_VENDOR_INFO_LOADING = '@@vendorInformation/ADD_VENDOR_INFO_LOADING';
export const ADD_VENDOR_INFO_SUCCESS = '@@vendorInformation/ADD_VENDOR_INFO_SUCCESS';
export const ADD_VENDOR_INFO_FAILURE = '@@vendorInformation/ADD_VENDOR_INFO_FAILURE';

export const addVendorInfo = (formData) => async (dispatch) => {
  dispatch({
    type: ADD_VENDOR_INFO_LOADING,
  });

  const body = {
    VendorOrgName: formData?.VendorOrgName,
    DomainName: formData?.DomainName,
    RegisteredBusinessAddressLine1: formData?.RegisteredBusinessAddressLine1,
    RegisteredBusinessAddressLine2: formData?.RegisteredBusinessAddressLine2,
    RegisteredBusinessAddressCity: formData?.RegisteredBusinessAddressCity,
    RegisteredBusinessAddressState: formData?.RegisteredBusinessAddressState,
    RegisteredBusinessAddressCountry: formData?.RegisteredBusinessAddressCountry,
    RegisteredBusinessAddressZip: formData?.RegisteredBusinessAddressZip,
    PhoneCode: formData?.PhoneCode,
    MainPhone: formData?.MainPhone,
    PhoneExtension: formData?.PhoneExtension,
    NumberOfEmployeesRangeId: formData?.NumberOfEmployeesRangeId,
    YearFounded: formData?.YearFounded,
    LogoFileLocation: '',
    AddVendorToDynamics: true,
  };
  try {
    const response = await axios.post(`/vendor`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_VENDOR_INFO_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_VENDOR_INFO_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    dispatch({
      type: ADD_VENDOR_INFO_FAILURE,
    });
  }
};
export const SET_VENDOR_PAGE_NO =
  "@@vendorInformation/SET_VENDOR_PAGE_NO";

export const setVendorPageNo =
  (page = 0) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_PAGE_NO,
      payload: page,
    });
  };

export const SET_VENDOR_ROW_PER_PAGE =
  "@@vendorInformation/SET_VENDOR_ROW_PER_PAGE";

export const setVendorRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_ROW_PER_PAGE,
      payload: page,
    });
    if (callback) {
      callback(page);
    }
  };
  export const SEARCH_VENDOR_LIST_LOADING =
  "@@vendorInformation/SEARCH_VENDOR_LIST_LOADING";
export const SEARCH_VENDOR_LIST_SUCCESS =
  "@@vendorInformation/SEARCH_VENDOR_LIST_SUCCESS";
export const SEARCH_VENDOR_LIST_FAILURE =
  "@@vendorInformation/SEARCH_VENDOR_LIST_FAILURE";

export const getVendorListData =
  (searchData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_VENDOR_LIST_LOADING });
    try {
      
      const body = getVendorListBody(
        searchData,
        page,
        rowPerPage,
        sortModel
      );
      const response = await axios.post(`/vendor/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_VENDOR_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_VENDOR_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

  export const SEARCH_UNVERIFIED_VENDOR_LIST_LOADING =
  "@@vendorInformation/SEARCH_UNVERIFIED_VENDOR_LIST_LOADING";
export const SEARCH_UNVERIFIED_VENDOR_LIST_SUCCESS =
  "@@vendorInformation/SEARCH_UNVERIFIED_VENDOR_LIST_SUCCESS";
export const SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE =
  "@@vendorInformation/SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE";

export const getUnverifiedVendorListData =
  (searchData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_UNVERIFIED_VENDOR_LIST_LOADING });
    try {
      
      const body = getUnverifiedVendorListBody(
        searchData,
        page,
        rowPerPage,
        sortModel
      );
      const response = await axios.post(`/vendor/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_UNVERIFIED_VENDOR_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_UNVERIFIED_VENDOR_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };