import { APP_CONFIG_KEYS } from 'constants/constants';
import { GET_CONFIG_VALUE_FAILURE, GET_CONFIG_VALUE_REQUEST, GET_CONFIG_VALUE_SUCCESS } from '../actions/config';

const initialState = {
  loading: false,
  error: '',
  [APP_CONFIG_KEYS.ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX]: '',
  [APP_CONFIG_KEYS.ASSESSMENTS_OVERVIEW_RESULT_SUFFIX]: '',
  [APP_CONFIG_KEYS.ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD]: '',
  [APP_CONFIG_KEYS.CORL_EMAIL_VSRM]: '',
  [APP_CONFIG_KEYS.ASSESSMENT_REQUEST_MAX_FILE_SIZE]: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_STARTED_GUIDE]: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS]: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS]: '',
  [APP_CONFIG_KEYS.CUSTOMER_VENDOR_LIST_MAX_ROWS]: ''
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIG_VALUE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CONFIG_VALUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case GET_CONFIG_VALUE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
