import { isArray, isEmpty, isNil, isString, omit } from 'lodash';
import { IMPORTED_ASSESSMENT_REQUEST_TYPE } from 'pages/AssessmentRequest/AssessmentRequestReview/constants';
import { QA_APPROVED_QUESTIONNAIRE_TEMPLATE } from 'pages/QuestionnaireBuilder/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key]) && !isEmpty(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'startswith',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

const getAssessmentSortBody = (sortModel) => {
  let sort = [];
  sortModel?.map((item) => {
    let obj = {};
    if (item?.field === 'LastStatusActivity') {
      obj = {
        field: 'LastUpdated',
        order: item?.sort?.toUpperCase(),
      };
    } else {
      obj = {
        ...item,
        order: item?.sort?.toUpperCase(),
      };
    }
    const omittedData = omit(obj, 'sort');
    sort.push(omittedData);
  });

  return sort;
};

export const getAssessmentQueueSearchBody = (searchData = {}) => {
  let criterion = [
    {
      Field: 'AssessmentRequestTypeId',
      Operator: '<>',
      Value: IMPORTED_ASSESSMENT_REQUEST_TYPE,
    },
  ];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'AssessmentRequestId',
      'AssessmentRequestType',
      'AssessmentStatus',
      'ClientOrgId',
      'ClientOrgName',
      'VendorOrgId',
      'VendorOrgName',
      'ProductName',
      'ProductType',
      'ProductCategory',
      'InsertTimestamp',
      'IsVerified',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'InsertTimestamp',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentListBody = (searchData = {}, page, pageSize, sortModel = []) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  let sort = getAssessmentSortBody(sortModel);

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'AssessmentStatus',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'DynVendorAccountId',
      'AssessmentDisplayStatus',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'LastUpdated',
      'ClientName',
      'AssessmentTypeId',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
    ],
    criterion: criterion,
    sort:
      sort.length > 0
        ? sort
        : [
          {
            field: 'AssessmentStartDate',
            order: 'DESC',
          },
        ],
    page: page,
    pageSize: pageSize,
  };
};

export const getVendorListDataSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'VendorOrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['VendorOrgId', 'VendorOrgName'],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getClientListDataSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'ClientOrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['ClientOrgId', 'ClientOrgName'],
    criterion: criterion,
    sort: [
      {
        field: 'ClientOrgName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getAssesmmentRequestProductListSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'ProductName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['ProductVendorId', 'ProductName', 'ProductNameForFilter'],
    Criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getAssessmentConfigurationBody = () => {
  return {
    intent: '',
    fields: ['MasterStepName', 'AssessmentDisplayStatus', 'Status', 'AssessmentDisplayStatusDropDown'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentListCountBody = (searchData = {}, sortModel) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ClientId',
      'ClientName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'AssessmentTypeId',
      'CountOfAssessment',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentDisplayStatus'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCustomerVendorProductListSearchBody = (vendorOrgId, searchText = '', productVendorId = 0) => {
  const criterion = [
    {
      Field: 'VendorOrgId',
      Operator: '=',
      Value: vendorOrgId,
    },
  ];
  if (searchText) {
    criterion.push({
      Field: 'ProductName',
      Operator: '=',
      Value: searchText,
    });
  }
  if (productVendorId) {
    criterion.push({
      Field: 'ProductVendorId',
      Operator: '=',
      Value: productVendorId,
    });
  }
  return {
    Intent: '',
    Fields: [
      'ProductName',
      'ProductType',
      'ProductGroup',
      'ProductVendorRole',
      'Description',
      'CustomerVendorId',
      'ProductVendorId',
      'CustomerOrgId',
      'CustomerVendorProductId',
      'FullTextSearch',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const convertToValueLable = (list = []) => {
  if (!isNil(list)) {
    return list?.map((item) => ({
      label: `${item?.QuestionnaireTemplateName} (v${item?.VersionNumber})`,
      value: item?.QuestionnaireTemplateId,
    }));
  }

  return [];
};

export const getQuestionnaireTemplateSearchBody = (questionnaireTemplateTypeId = null, searchText = null, pageNo = 0, pageSize = 10) => {
  let intent = 'search';
  const fields = [
    'QuestionnaireTemplateName', 
    'QuestionnaireTemplateTypeName', 
    'VersionNumber', 
    'ReferenceId', 
    'ReferenceName', 
    'Status'
  ];

  const criterion = [
    {
      Field: 'QuestionnaireTemplateTypeId',
      Operator: 'in',
      Value: questionnaireTemplateTypeId, // Scoping = 1, Vendor Security Risk Assessment = 4, Supplemental = 5
    },
    {
      Field: 'Status',
      Operator: '=',
      Value: QA_APPROVED_QUESTIONNAIRE_TEMPLATE,
    },
  ];

  if (!isNil(searchText)) {
    criterion.push({
      Field: 'QuestionnaireTemplateName',
      Operator: 'contains',
      Value: searchText?.trim(),
    });
  }

  return {
    intent: intent,
    fields: fields,
    criterion: criterion,
    sort: [],
    page: pageNo,
    pageSize: pageSize,
  };
};