import { isNil } from 'lodash';

export const searchVendorCustomerReqBody = (id, searchValue = '') => {
  const criterion = [];
  if (searchValue) {
    criterion.push({
      field: 'CustomerOrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['CustomerVendorId', 'CustomerOrgName'],
    criterion: [
      ...criterion,
      {
        field: 'VendorOrgId',
        operator: '=',
        value: id,
      },
    ],
    sort: [
      {
        field: 'CustomerOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductGroupReqBody = (id) => {
  return {
    intent: '',
    fields: ['ProductGroupName'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: '=',
        value: id,
      },
    ],
    sort: [
      {
        field: 'ProductGroupName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductListReqBody = (vendorOrgId, productGroupId = null) => {
  let criterion = [
    {
      field: 'VendorOrgId',
      operator: '=',
      value: vendorOrgId,
    },
  ];
  if (!isNil(productGroupId)) {
    criterion.push({
      field: 'ProductGroupId',
      operator: '=',
      value: productGroupId,
    });
  }
  return {
    intent: '',
    fields: ['ProductName', 'ProductType', 'ProductVendorRole'],
    criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchCustomerProductsReqBody = (vendorOrgId, customerVendorId) => {
  return {
    intent: '',
    fields: ['CustomerVendorProductId', 'ProductName'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
      {
        field: 'CustomerVendorId',
        operator: '=',
        value: customerVendorId,
      },
    ],
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getCustomerSearchBody = (searchText) => {
  return {
    Intent: '',
    Fields: ['CustomerOrgName', 'RegisteredBusinessAddressCity', 'RegisteredBusinessAddressState', 'RegisteredBusinessAddressZip'],
    Criterion: [
      {
        Field: 'CustomerOrgName',
        Operator: 'startswith',
        Value: searchText,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};
