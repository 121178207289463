export const FILE_SIZE_LIMIT = 10485760;
export const FILE_UPLOAD_SUCCESS_MESSAGE = 'File Uploaded Successfully.';

export const VENDOR_TAB_OPTIONS = {
  ALL_VENDORS: 'ALL VENDORS',
  UNVERIFIED: 'UNVERIFIED',
};

export const VENDOR_TAB_LIST = [
  {
    label: VENDOR_TAB_OPTIONS.ALL_VENDORS,
    value: VENDOR_TAB_OPTIONS.ALL_VENDORS,
  },
  {
    label: VENDOR_TAB_OPTIONS.UNVERIFIED,
    value: VENDOR_TAB_OPTIONS.UNVERIFIED,
  },
];

export const NO_VENDORS_FOUND_MESSAGE = 'No Vendors Found';

export const CUSTOMER = 'Customer';
export const NOT_CUSTOMER = 'Not Customer';
