import {
  DELETE_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
  INADEQUACY_REASONS_ADDED_SUCCESSFULLY,
  INADEQUACY_REASONS_UPDATED_SUCCESSFULLY,
  IPIS_REORDERED_SUCCESSFULLY_MESSAGE,
  IPI_ADDED_SUCCESSFULLY,
  IPI_UPDATED_SUCCESSFULLY,
  QUESTIONS_REORDERED_SUCCESSFULLY_MESSAGE,
  QUESTION_ASSOCIATED_SUCCESSFULLY_MESSAGE,
  QUESTION_DELETED_SUCCESSFULLY_MESSAGE,
  QUESTION_SAVED_SUCCESSFULLY_MESSAGE,
  QUESTION_UPDATED_SUCCESSFULLY_MESSAGE,
  SAVE_SUCCESSFULLY_MESSAGE,
  STATUS_UPDATED_SUCCESSFULLY_MESSAGE,
  UPDATED_AUDIT_REVIEW_SUCCESSFULLY_MESSAGE,
} from 'components/Common/AlertMessages';
import { cloneDeep, isArray, isEmpty, uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import {
  SET_QUESTION_LIST,
  POST_QUESTIONNAIRE_TEMPLATE_LOADING,
  POST_QUESTIONNAIRE_TEMPLATE_FAILURE,
  POST_QUESTIONNAIRE_TEMPLATE_SUCCESS,
  POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
  POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
  GET_QUESTIONNAIRE_TEMPLATE_LOADING,
  GET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
  GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
  SET_SELECTED_QUESTION,
  PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
  PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
  RESET_QUESTIONNAIRE_TEMPLATE,
  PUT_QUESTIONNAIRE_TEMPLATE_LOADING,
  PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS,
  PUT_QUESTIONNAIRE_TEMPLATE_FAILURE,
  HIDE_ALERT,
  DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
  ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS,
  SEARCH_INCIDENT_LIST_LOADING,
  SEARCH_INCIDENT_LIST_SUCCESS,
  SEARCH_INCIDENT_LIST_FAILURE,
  GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING,
  GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS,
  GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
  POST_COMMENT_QUESTIONNAIRE_LOADING,
  POST_COMMENT_QUESTIONNAIRE_SUCCESS,
  POST_COMMENT_QUESTIONNAIRE_FAILURE,
  POST_QUESTIONNAIRE_STATUS_LOADING,
  POST_QUESTIONNAIRE_STATUS_SUCCESS,
  POST_QUESTIONNAIRE_STATUS_FAILURE,
  QUESTION_PANEL_DISABLE_STATE,
  REORDER_QUESTIONS_FAILURE,
  REORDER_QUESTIONS_LOADING,
  REORDER_QUESTIONS_SUCCESS,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_LOADING,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_SUCCESS,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE,
  DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_SUCCESS,
  SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING,
  SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
  SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
  SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_LOADING,
  SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_SUCCESS,
  SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE,
  POST_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING,
  POST_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
  POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
  PUT_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING,
  PUT_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS,
  PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE,
  SET_SELECTED_REQUIREMENT,
  POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_LOADING,
  POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_SUCCESS,
  POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE,
  SET_ANSWER_TYPE,
  POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_LOADING,
  POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_SUCCESS,
  POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE,
  SET_QUESTION_AND_EVIDENCE_LIST,
  DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_LOADING,
  DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_SUCCESS,
  DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE,
  SET_EXPANDED_REQUIREMENT,
  SET_FOCUSED_REQUIREMENT,
  SEARCH_INADEQUACY_LIST_LOADING,
  SEARCH_INADEQUACY_LIST_SUCCESS,
  SEARCH_INADEQUACY_LIST_FAILURE,
  POST_INADEQUACY_REASONS_LOADING,
  POST_INADEQUACY_REASONS_SUCCESS,
  POST_INADEQUACY_REASONS_FAILURE,
  PUT_INADEQUACY_REASONS_LOADING,
  PUT_INADEQUACY_REASONS_SUCCESS,
  PUT_INADEQUACY_REASONS_FAILURE,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_LOADING,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_SUCCESS,
  GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE,
  SET_ASSESSMENT_QUESTION_FORCE_UPDATE,
  SET_SELECTED_INADEQUACY_REASON,
  SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING,
  SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
  SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
  REORDER_INCREMENTAL_PROGRESS_INDICATOR_LOADING,
  REORDER_INCREMENTAL_PROGRESS_INDICATOR_SUCCESS,
  REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE,
  ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING,
  ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
  GET_INADEQUACY_REASONS_FOR_REQUIREMENT_LOADING,
  GET_INADEQUACY_REASONS_FOR_REQUIREMENT_SUCCESS,
  GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE,
  GET_INADEQUACY_REASONS_OPTION_ID_LOADING,
  GET_INADEQUACY_REASONS_OPTION_ID_SUCCESS,
  GET_INADEQUACY_REASONS_OPTION_ID_FAILURE,
  UPDATE_RESPONSE_CONFIGURATION_LOADING,
  UPDATE_RESPONSE_CONFIGURATION_SUCCESS,
  UPDATE_RESPONSE_CONFIGURATION_FAILURE,
  ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
  SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING,
  SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS,
  SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE,
  SET_PROGRESS_INDICATOR_FORCE_UPDATE,
  SEARCH_ALL_INADEQUACY_REASONS_LIST_LOADING,
  SEARCH_ALL_INADEQUACY_REASONS_LIST_SUCCESS,
  SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE,
  ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
  ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
  ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
  UPDATE_REQUIREMENT_AUDIT_REVIEW_LOADING,
  UPDATE_REQUIREMENT_AUDIT_REVIEW_SUCCESS,
  UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE,
} from '../actions/questionnairBuilder';

import { ANSWER_TYPES, CLIENT_APPROVED_STATUS, CLIENT_REJECTED_STATUS, DEFAULT_LOOKUP_QUESTION_PAGE_NO, DEFAULT_LOOKUP_QUESTION_ROWS_PER_PAGE, PAGE_ASSESSMENT } from '../pages/QuestionnaireBuilder/constants';
import { faFiles } from '@fortawesome/pro-solid-svg-icons';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { UPDATE } from 'pages/Assessment/AssessmentsSidesheet/constants';
import { UPDATED_SUCCESSFULLY, YES_VALUE } from 'constants/constants';
import { LOOKUP_SEARCH_LIMIT_ERROR_MESSAGE, LOOKUP_SEARCH_VALUE_ERROR_MESSAGE } from 'constants/errorMessage';

const initialState = {
  loading: false,
  templateQuestionList: [],
  incidentList: [],
  questionnaireStatusList: [],
  questionnaireTemplate: {},
  selectedQuestion: {},
  showQuestionnaireSavedAlert: false,
  showQuestionSavedAlert: false,
  showQuestionUpdatedAlert: false,
  showQuestionDeletedAlert: false,
  showQuestionRequiredAlert: false,
  isQuestionnaireCommentSaved: false,
  isQuestionnaireStatusSaved: false,
  questionPanelDisableState: true,
  questionnaireRequirementList: [],
  questionnaireTemplateQuestionList: [],
  updatedQuestionnaireRequirementList: [],
  unsavedQuestionnaireRequirementsDetails: false,
  requirementValueLabelList: [],
  allRequirementsValueLabelList: [],
  answerTypeOptions: [],
  expandedRequirement: null,
  focusedRequirement: {},
  inadequacyList: [],
  selectedInadequacyReason: {},
  incrementalProgressIndicatorList: [],
  incrementalProgressIndicatorMasterList: [],
  progressIndicatorForceUpdate: false,
  allReasonsList: [],
  inadequacyReasonsList: [],
  inadequacyReasonsOptions: [],
  questionList: [],
  lookupQuestionPageNo: DEFAULT_LOOKUP_QUESTION_PAGE_NO,
  lookupQuestionRowsPerPage: DEFAULT_LOOKUP_QUESTION_ROWS_PER_PAGE,
};

const convertIncidentList = (list) => {
  return list.map((incident) => ({
    value: incident.IncidentId,
    label: incident.IncidentName,
  }));
};

export default function (state = initialState, action) {
  const { type, payload, lookupSearchValue } = action;
  switch (type) {
    case SET_QUESTION_LIST: {
      const templateQuestionList = cloneDeep(state.templateQuestionList);
      templateQuestionList.push({
        questionNumber: templateQuestionList.length + 1,
        ...payload,
      });
      return {
        ...state,
        templateQuestionList,
      };
    }

    case SET_SELECTED_QUESTION: {
      let questionResponseList = payload?.QuestionResponseOptionList?.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      return {
        ...state,
        selectedQuestion: !isEmpty(payload) ? { ...payload, QuestionResponseOptionList: questionResponseList } : {},
        selectedQuestionLogicRuleList: !isEmpty(payload) ? payload.QuestionLogicRuleList : [],
      };
    }

    case POST_QUESTIONNAIRE_TEMPLATE_LOADING:
    case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING:
    case GET_QUESTIONNAIRE_TEMPLATE_LOADING:
    case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING:
    case PUT_QUESTIONNAIRE_TEMPLATE_LOADING:
    case SEARCH_INCIDENT_LIST_LOADING:
    case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING:
    case GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_LOADING:
    case REORDER_QUESTIONS_LOADING:
    case SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING:
    case SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_LOADING:
    case POST_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING:
    case PUT_QUESTIONNAIRE_REQUIREMENT_LIST_LOADING:
    case POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_LOADING:
    case POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_LOADING:
    case DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_LOADING:
    case ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING: 
    case UPDATE_REQUIREMENT_AUDIT_REVIEW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_QUESTIONNAIRE_TEMPLATE_SUCCESS:
    case PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        showQuestionnaireSavedAlert: true,
      };
    }

    case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
      toast.success(QUESTION_SAVED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        showQuestionSavedAlert: true,
        questionPanelDisableState: true,
      };
    }

    case SEARCH_INCIDENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentList: convertIncidentList(payload?.Data?.Rows),
      };
    }

    case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireStatusList: payload?.Data?.filter((item) => !(item.Label === CLIENT_APPROVED_STATUS || item.Label === CLIENT_REJECTED_STATUS) && item),
      };
    }

    case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
      toast.success(QUESTION_UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        showQuestionUpdatedAlert: true,
      };
    }

    case GET_QUESTIONNAIRE_TEMPLATE_SUCCESS: {
      const initialQuestionList = payload.Data?.QuestionList ?? [];
      let selectedQuestionValue = state?.selectedQuestion;
      let templateQuestionList = initialQuestionList.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      if (!isEmpty(selectedQuestionValue)) {
        let index = templateQuestionList.findIndex((item) => item?.QuestionnaireTemplateQuestionId === selectedQuestionValue?.QuestionnaireTemplateQuestionId);
        selectedQuestionValue = templateQuestionList[index];
        selectedQuestionValue = {
          ...selectedQuestionValue,
          QuestionResponseOptionList: selectedQuestionValue?.QuestionResponseOptionList?.sort((a, b) => a.DisplayOrder - b.DisplayOrder),
          AllowCommentInResponse: index >= 0 ? selectedQuestionValue?.AllowCommentInResponse : YES_VALUE
        };
      }
      return {
        ...state,
        loading: false,
        questionnaireTemplate: payload?.Data,
        selectedQuestion: selectedQuestionValue,
        templateQuestionList: templateQuestionList.map((it, i) => {
          return {
            ...it,
            questionNumber: i + 1,
          };
        }),
      };
    }

    case POST_QUESTIONNAIRE_TEMPLATE_FAILURE:
    case GET_QUESTIONNAIRE_TEMPLATE_FAILURE:
    case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE:
    case PUT_QUESTIONNAIRE_TEMPLATE_FAILURE:
    case REORDER_QUESTIONS_FAILURE:
    case POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_FAILURE:
    case POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_INCIDENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incidentList: [],
      };
    }
    case GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireTemplateQuestionList: [],
      };
    }

    case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireStatusList: payload,
      };
    }

    case DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case RESET_QUESTIONNAIRE_TEMPLATE: {
      return {
        ...state,
        questionnaireTemplate: {},
      };
    }

    case DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FOR_REQUIREMENT_SUCCESS:
    case DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
      toast.success(QUESTION_DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        showQuestionDeletedAlert: true,
      };
    }

    case POST_COMMENT_QUESTIONNAIRE_LOADING: {
      return {
        ...state,
        isQuestionnaireCommentSaved: false,
      };
    }

    case POST_QUESTIONNAIRE_STATUS_LOADING: {
      return {
        ...state,
        isQuestionnaireStatusSaved: false,
      };
    }

    case POST_COMMENT_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        isQuestionnaireCommentSaved: true,
      };
    }

    case POST_QUESTIONNAIRE_STATUS_SUCCESS: {
      return {
        ...state,
        isQuestionnaireStatusSaved: true,
      };
    }

    case POST_COMMENT_QUESTIONNAIRE_FAILURE: {
      return {
        ...state,
        isQuestionnaireCommentSaved: false,
      };
    }

    case POST_QUESTIONNAIRE_STATUS_FAILURE: {
      return {
        ...state,
        isQuestionnaireStatusSaved: false,
      };
    }

    case ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS: {
      toast.success(STATUS_UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        showQuestionRequiredAlert: true,
      };
    }

    case DELETE_QUESTIONNAIRE_TEMPLATE_REQUIREMENT_SUCCESS: {
      toast.success(DELETE_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case HIDE_ALERT: {
      return {
        ...state,
        showQuestionnaireSavedAlert: false,
        showQuestionSavedAlert: false,
        showQuestionUpdatedAlert: false,
        showQuestionDeletedAlert: false,
        showQuestionRequiredAlert: false,
      };
    }

    case QUESTION_PANEL_DISABLE_STATE: {
      return {
        ...state,
        questionPanelDisableState: payload,
      };
    }

    case REORDER_QUESTIONS_SUCCESS: {
      toast.success(QUESTIONS_REORDERED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireRequirementList: [],
        requirementValueLabelList: [],
        questionPanelDisableState: true
      };
    }

    case SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        allRequirementsValueLabelList: []
      }
    }

    case GET_QUESTIONNAIRETEMPLATE_QUESTION_LIST_SUCCESS: {
      const initialQuestionList = payload ? payload : [];
      let questionnaireTemplateQuestionList = initialQuestionList.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

      // Helper function to generate child question numbers like "1.1", "1.2", "1.3", etc.
      const generateChildQuestionNumber = (parentNumber, childIndex) => {
        return `${parentNumber}.${childIndex + 1}`;
      };

      questionnaireTemplateQuestionList = questionnaireTemplateQuestionList?.map((it, i) => {
        const questionNumber = i + 1;


        it.QuestionResponseOptionList?.map((response, responseIndex) => {
          if (it.QuestionLogicRuleList.length > 0) {
            it.QuestionLogicRuleList?.map((item, index) => {
              if (item?.QuestionResponseOptionId === response?.QuestionResponseOptionId) {
                it.QuestionResponseOptionList[responseIndex] = {
                  ...response,
                  QuestionLogicRule: {
                    QuestionId: item?.QuestionId ? item?.QuestionId : 0,
                    QuestionLogicRuleId: item?.QuestionLogicRuleId ? item?.QuestionLogicRuleId : 0,
                    QuestionResponseOptionId: item?.QuestionResponseOptionId ? item?.QuestionResponseOptionId : 0,
                    RuleOrder: item?.RuleOrder ? item?.RuleOrder : 0,
                    LogicRule: item?.LogicRule ? item?.LogicRule : "NAVIGATE",
                    LogicAction: item?.LogicAction ? item?.LogicAction : "",
                    TempLogicAction: item?.LogicAction ? item?.LogicAction?.split(',') : [],
                  }
                }
              }
            })

          } else {
            it.QuestionResponseOptionList[responseIndex] = {
              ...response,
              QuestionLogicRule: {
                QuestionId: response.QuestionId,
                QuestionLogicRuleId: 0,
                QuestionResponseOptionId: response.QuestionResponseOptionId,
                RuleOrder: 0,
                LogicRule: "NAVIGATE",
                LogicAction: "",
                TempLogicAction: [],
              }
            }
          }

        })

        // Check if the question has child questions
        if (it.ChildQuestionList && it.ChildQuestionList?.length > 0) {
          const childQuestionsWithNumber = it.ChildQuestionList?.map((childQuestion, childIndex) => ({
            ...childQuestion,
            questionNumber: generateChildQuestionNumber(questionNumber, childIndex),
          }));

          return {
            ...it,
            questionNumber,
            ChildQuestionList: childQuestionsWithNumber,
          };
        }

        return {
          ...it,
          questionNumber,
        };
      });

      const questionList = cloneDeep(state?.templateQuestionList);
      const selectedQuestion = questionList?.find(it => it.QuestionId === state?.selectedQuestion?.QuestionId);      

      return {
        ...state,
        loading: false,
        questionnaireTemplateQuestionList,
        selectedQuestion:  !isEmpty(selectedQuestion) ? selectedQuestion : {},
      };
    }


    case SEARCH_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireRequirementList: payload.map((item, index) => {
          return {
            ...item,
            id: index + 1,
            RequirementNumber: item?.RequirementNumber,
          }
        }),
        requirementValueLabelList: payload.map((it) => {
          return {
            label: it?.RequirementName,
            value: it?.RequirementId,
          };
        }),
      };
    }

    case SEARCH_ALL_QUESTIONNAIRE_REQUIREMENTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        allRequirementsValueLabelList: payload?.Data?.Rows?.map((it) => {
          return {
            ...it,
            label: it?.RequirementName,
            value: it?.RequirementId,
          };
        }),
      };
    }

    case POST_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false
      };
    }

    case POST_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false
      };
    }

    case PUT_QUESTIONNAIRE_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case PUT_QUESTIONNAIRE_REQUIREMENT_LIST_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false
      };
    }

    case SET_SELECTED_REQUIREMENT: {
      return {
        ...state,
        selectedRequirementId: payload
      }
    }

    case POST_QUESTIONNAIRE_TEMPLATE_NEW_VERSION_SUCCESS:
    case POST_QUESTIONNAIRE_TEMPLATE_DUPLICATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case SET_ANSWER_TYPE: {
      let answerType = cloneDeep(ANSWER_TYPES);
      if (payload === PAGE_ASSESSMENT) {
        answerType.push({
          value: 5,
          label: 'Evidence/ File Upload',
          icon: faFiles,
        });
      }
      return {
        ...state,
        answerTypeOptions: answerType
      }
    }

    case SET_QUESTION_AND_EVIDENCE_LIST: {
      return {
        ...state,
        questionnaireTemplateQuestionList: payload
      }
    }

    case SET_EXPANDED_REQUIREMENT: {
      return {
        ...state,
        expandedRequirement: payload,
        inadequacyList: []
      }
    }

    case SET_FOCUSED_REQUIREMENT: {
      return {
        ...state,
        focusedRequirement: payload
      }
    }

    case SEARCH_INADEQUACY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SEARCH_INADEQUACY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        inadequacyList: payload
      }
    }

    case SEARCH_INADEQUACY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        inadequacyList: []
      }
    }

    case POST_INADEQUACY_REASONS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case POST_INADEQUACY_REASONS_SUCCESS: {
      toast.success(INADEQUACY_REASONS_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      }
    }

    case POST_INADEQUACY_REASONS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      }
    }

    case PUT_INADEQUACY_REASONS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case PUT_INADEQUACY_REASONS_SUCCESS: {
      toast.success(INADEQUACY_REASONS_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      }
    }

    case PUT_INADEQUACY_REASONS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      }
    }

    case GET_QUESTIONNAIRETEMPLATE_QUESTION_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_QUESTIONNAIRETEMPLATE_QUESTION_SUCCESS: {
      let questionResponseList = payload?.QuestionResponseOptionList?.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      return {
        ...state,
        loading: false,
        selectedQuestion: { ...payload, QuestionResponseOptionList: questionResponseList, AllowCommentInResponse: YES_VALUE },
        selectedQuestionLogicRuleList: payload.QuestionLogicRuleList
      };
    }

    case GET_QUESTIONNAIRETEMPLATE_QUESTION_FAILURE: {
      if (isArray(payload)) {
        return {
          ...state,
          loading: false,
        };
      } else
        return {
          ...state,
          loading: false,
          selectedQuestion: {},
          selectedQuestionLogicRuleList: [],
        };
    }

    case SET_ASSESSMENT_QUESTION_FORCE_UPDATE: {
      return {
        ...state,
        assessmentQuestionForceUpdate: payload
      }
    }

    case SET_SELECTED_INADEQUACY_REASON: {
      return {
        ...state,
        selectedInadequacyReason: payload
      }
    }

    case SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incrementalProgressIndicatorList: payload,
      }
    }

    case SEARCH_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incrementalProgressIndicatorList: [],
      }
    }

    case SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incrementalProgressIndicatorMasterList: payload?.map((it) => {
          return {
            ...it,
            label: it?.Name,
            value: it?.IncrementalProgressIndicatorId,
          };
        }),
      }
    }

    case SEARCH_MASTER_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        incrementalProgressIndicatorMasterList: []
      }
    }

    case REORDER_INCREMENTAL_PROGRESS_INDICATOR_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case REORDER_INCREMENTAL_PROGRESS_INDICATOR_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
      }
    }

    case REORDER_INCREMENTAL_PROGRESS_INDICATOR_SUCCESS: {
      toast.success(IPIS_REORDERED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
      }
    }

    case ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case ADD_INCREMENTAL_PROGRESS_INDICATOR_LIST_SUCCESS: {
      toast.success(IPI_ADDED_SUCCESSFULLY)
      return {
        ...state,
        loading: false,
      }
    }

    case SET_PROGRESS_INDICATOR_FORCE_UPDATE: {
      return {
        ...state,
        progressIndicatorForceUpdate: payload
      }
    }

    case SEARCH_ALL_INADEQUACY_REASONS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SEARCH_ALL_INADEQUACY_REASONS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        allReasonsList: payload?.map((it) => {
          return {
            ...it,
            label: it?.Name,
            value: it?.InadequacyReasonId,
          };
        }),
      }
    }

    case SEARCH_ALL_INADEQUACY_REASONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        allReasonsList: [],
      }
    }

    case GET_INADEQUACY_REASONS_FOR_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_INADEQUACY_REASONS_FOR_REQUIREMENT_SUCCESS: {
      const getOptionsList = (list, label, value) => {
        const optionsList = list?.length > 0 && list?.map((it) => {
          return {
            label: it[label],
            value: it[value],
            id: it.RequirementInadequacyReasonId
          };
        });
        return optionsList;
      };

      return {
        ...state,
        loading: false,
        inadequacyReasonsList: getOptionsList(payload, "Name", "InadequacyReasonId"),
      }
    }

    case GET_INADEQUACY_REASONS_FOR_REQUIREMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        inadequacyReasonsList: []
      }
    }
    case GET_INADEQUACY_REASONS_OPTION_ID_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_INADEQUACY_REASONS_OPTION_ID_SUCCESS: {
      const options = payload.map((item) => {
        return {
          Name: item.Name,
          InadequacyReasonId: item.InadequacyReasonId,
          RequirementInadequacyReasonId: item.RequirementInadequacyReasonId,
          QuestionResponseOptionId: item?.QuestionResponseOptionId,
          QuestionResponseOptionInadequacyReasonId: item.QuestionResponseOptionInadequacyReasonId,
        }
      })
      return {
        ...state,
        loading: false,
        inadequacyReasonsOptions: !isEmpty(payload) ? payload[0] : []
      }
    }

    case GET_INADEQUACY_REASONS_OPTION_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        inadequacyReasonsOptions: []
      }
    }

    case UPDATE_RESPONSE_CONFIGURATION_LOADING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_RESPONSE_CONFIGURATION_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY)
      return {
        ...state,
        loading: false
      }
    }

    case UPDATE_RESPONSE_CONFIGURATION_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
      toast.success(QUESTION_ASSOCIATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        showQuestionSavedAlert: true,
        questionPanelDisableState: true,
      };
    }

    case ASSOCIATE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_REQUIREMENT_AUDIT_REVIEW_SUCCESS: {
      toast.success(UPDATED_AUDIT_REVIEW_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_REQUIREMENT_AUDIT_REVIEW_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
