import {
  ADD_EMAIL_TEMPLATE_FAILURE,
  ADD_EMAIL_TEMPLATE_LOADING,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  GENERATE_TEST_EMAIL_FAILURE,
  GENERATE_TEST_EMAIL_LOADING,
  GENERATE_TEST_EMAIL_SUCCESS,
  GET_EMAIL_TEMPLATES_FAILURE,
  GET_EMAIL_TEMPLATES_LOADING,
  GET_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE_LOADING,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
} from 'actions/emailTemplates';
import { EMAIL_TEMPLATE_ADDED_SUCCESSFULLY, EMAIL_TEMPLATE_UPDATED, ERROR_MESSAGE, TEST_EMAIL_GENERATE_MESSAGE } from 'components/Common/AlertMessages';
import { find } from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  emailTemplate: [],
  emailBody: {},
  emailSignature: {},
  emailSubjectLine: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAIL_TEMPLATES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        emailTemplate: payload,
        emailBody: find(payload, { Key: 'Body' }) || {},
        emailSignature: find(payload, { Key: 'Signature' }) || {},
        emailSubjectLine: find(payload, { Key: 'SubjectLine' }) || {},
      };
    }
    case GET_EMAIL_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        emailTemplate: [],
      };
    }

    case GENERATE_TEST_EMAIL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GENERATE_TEST_EMAIL_SUCCESS: {
      toast.success(TEST_EMAIL_GENERATE_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case GENERATE_TEST_EMAIL_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_EMAIL_TEMPLATE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_EMAIL_TEMPLATE_SUCCESS: {
      toast.success(EMAIL_TEMPLATE_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_EMAIL_TEMPLATE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_EMAIL_TEMPLATE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      toast.success(EMAIL_TEMPLATE_UPDATED);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
