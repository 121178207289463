import { isEmpty } from 'lodash';

export const getContactDetailsSearchBody = (searchText) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'IsTeamContact', 'FullName', 'Active'],
    criterion: [
      {
        field: 'Email',
        operator: 'startswith',
        value: searchText,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorContractListSearchBody = (vendorOrgId = 0) => {
  const criterion = [
    {
      field: 'VendorOrgId',
      operator: '=',
      value: vendorOrgId,
    },
  ];
  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'EffectiveDate',
      'ServiceProviderContactId',
      'ContactFirstName',
      'ContactLastName',
      'ContactEmail',
      'IsCORLCustomer',
      'Portal2AssessmentRouting',
      'IsCoPilot',
      'IsCorlClearedCopilot',
      'IsDataReuse',
      'IsCompanionAssessment',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorByVendorOrgIdSearchBody = (vendorOrgId) => {
  return {
    intent: '',
    fields: ['VendorOrgId', 'VendorOrgName', 'DynVendorAccountId'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
    ],
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorListSearchBody = (searchText = '') => {
  const criterion = [
    {
      field: 'VendorOrgId',
      operator: '<>',
      value: '0',
    },
  ];
  if (!isEmpty(searchText.trim())) {
    criterion.push({
      field: 'VendorOrgName',
      operator: 'startswith',
      value: searchText.trim(),
    });
  }
  return {
    intent: '',
    fields: ['VendorOrgId', 'VendorOrgName', 'DynVendorAccountId'],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
