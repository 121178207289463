import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import userReducer from './userProfile';
import client from './client';
import session from './session';
import navbar from './navbar';
import questionnaireTemplate from './questionnaireTemplate';
import getQuestionnaireTemplate from './getQuestionnaireTemplate';
import questionnaireBuilder from './questionnaireBuilder';
import errorHandler from './errorHandler';
import outreachBuilder from './outreachBuilder';
import incidentBuilder from './incidentBuilder';
import vendorInformation from './vendorInformation';
import incident from './incident';
import lookup from './lookup';
import corlUsers from './corlUsers';
import vendorUsers from './vendorUsers';
import { vendorProfileContact } from './vendorProfileContacts';
import comments from './comments';
import userRoles from './userRoles';
import user from './user';
import allUsers from './allUsers';
import config from './config';
import assessmentRequests from './assessmentRequests';
import assessments from './assessments';
import verifyVendor from './verifyVendor';
import vendorContract from './vendorContract';
import { vendorProductsPage } from './vendorProduct';
import vendorCustomers from './vendorCustomers';
import customErrorHandler from './customErrorHandler';
import customersAndProducts from './customersAndProducts';
import vendorIncidentEscalation from './vendorIncidentEscalation';
import vendorIncidentPreparedness from './vendorIncidentPreparedness';
import evidenceManagement from './evidenceManagement';
import replaceVendor from './replaceVendor';
import assessmentResults from './assessmentResults';
import emailTemplates from './emailTemplates';
//import {LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, SET_SESSION_DETAILS, login, logout, setSessionDetails as session} from '../actions/session';
//import * as user from '../actions/user';
//import {USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, getUsers} from '../actions/user';

// const rootReducer = combineReducers({
//     form,
//     LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, SET_SESSION_DETAILS, login, logout, setSessionDetails,
//     USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, getUsers
// });

const rootReducer = combineReducers({
  form,
  userReducer,
  session,
  client,
  navbar,
  questionnaireTemplate,
  getQuestionnaireTemplate,
  questionnaireBuilder,
  outreachBuilder,
  errorHandler,
  incident,
  incidentBuilder,
  vendorInformation,
  lookup,
  corlUsers,
  vendorUsers,
  vendorProfileContact,
  comments,
  userRoles,
  user,
  allUsers,
  config,
  assessmentRequests,
  assessments,
  verifyVendor,
  vendorContract,
  vendorProductsPage,
  vendorCustomers,
  customErrorHandler,
  customersAndProducts,
  vendorIncidentEscalation,
  vendorIncidentPreparedness,
  evidenceManagement,
  replaceVendor,
  assessmentRequests,
  assessmentResults,
  emailTemplates,
});
export default rootReducer;
