import bannerImage from '../../assets/images/corl-pattern.jpg';

export const breadcrumbLabel = () => {
  return {
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.05em',
    color: 'primary.main',
    textTransform: 'uppercase',
    '&:hover': {
      color: 'primary.main',
    },
  };
};

export const buttonsGrid = (theme) => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'end',
    '& .isDisabled': {
      backgroundColor: `${theme.palette.primary.contrastText}`,
    },
  };
};

export const bannerContainerWrap = () => {
  return {
    height: '125px',
    display: 'flex',
    padding: '0px 80px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundImage: `url(${bannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
};

export const breadCrumbs = () => {
  return {
    marginBottom: '17px',
  };
};

export const bannerTitleStyles = (theme) => {
  return {
    color: theme.palette.common.white,
    overflowWrap: 'break-word',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };
};
