import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getAllPortalUserListSearchBody } from 'utils/allUsersUtils';
import { getUserDomainSearchBody } from 'utils/userUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const getUsers = () => async (dispatch) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const res = await axios.get('/users');
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: res.data?.data,
    });
  } catch (err) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: getErrorObject(err),
    });
  }
};

export const GET_USER_ASSOCIATION_LOADING = '@@user/GET_USER_ASSOCIATION_LOADING';
export const GET_USER_ASSOCIATION_FAILURE = '@@user/GET_USER_ASSOCIATION_FAILURE';
export const GET_USER_ASSOCIATION_SUCCESS = '@@user/GET_USER_ASSOCIATION_SUCCESS';

export const getUserAssociation = (id) => async (dispatch) => {
  dispatch({
    type: GET_USER_ASSOCIATION_LOADING,
  });
  try {
    let res = await axios.get(`/user/${id}/association/clientvendor`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_USER_ASSOCIATION_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_USER_ASSOCIATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_ASSOCIATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_USER_ASSOCIATION_LOADING = '@@user/POST_USER_ASSOCIATION_LOADING';
export const POST_USER_ASSOCIATION_FAILURE = '@@user/POST_USER_ASSOCIATION_FAILURE';
export const POST_USER_ASSOCIATION_SUCCESS = '@@user/POST_USER_ASSOCIATION_SUCCESS';

export const addUserAssociation = (reqBody, callback) => async (dispatch) => {
  dispatch({
    type: POST_USER_ASSOCIATION_LOADING,
  });
  let body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/user/${reqBody?.UserId}/action/association/clientvendor`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_USER_ASSOCIATION_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_USER_ASSOCIATION_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getUserAssociation(reqBody?.UserId));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_USER_ASSOCIATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const USER_DOMAIN_LIST_LOADING = '@user/USER_DOMAIN_LIST_LOADING';
export const USER_DOMAIN_LIST_SUCCESS = '@user/USER_DOMAIN_LIST_SUCCESS';
export const USER_DOMAIN_LIST_FAILURE = '@user/USER_DOMAIN_LIST_FAILURE';

export const getUserDomainConfigList = (orgId) => async (dispatch) => {
  dispatch({ type: USER_DOMAIN_LIST_LOADING });
  try {
    const body = getUserDomainSearchBody(orgId);
    const res = await axios.post('/serviceprovider/userdomain/search', body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: USER_DOMAIN_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: USER_DOMAIN_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_DOMAIN_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CREATE_USER_DOMAIN_ID_LOADING = '@user/CREATE_USER_DOMAIN_ID_LOADING';
export const CREATE_USER_DOMAIN_ID_FAILURE = '@user/CREATE_USER_DOMAIN_ID_FAILURE';
export const CREATE_USER_DOMAIN_ID_SUCCESS = '@user/CREATE_USER_DOMAIN_ID_SUCCESS';

export const createUserDomainIdRule = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_USER_DOMAIN_ID_LOADING,
  });
  let body = JSON.stringify(data);
  try {
    let res = await axios.post(`/serviceprovider/userdomain`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: CREATE_USER_DOMAIN_ID_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: CREATE_USER_DOMAIN_ID_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getUserDomainConfigList(data?.OrgId));
    }
  } catch (err) {
    dispatch({
      type: CREATE_USER_DOMAIN_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SAVE_USER_DOMAIN_ID_LOADING = '@user/SAVE_USER_DOMAIN_ID_LOADING';
export const SAVE_USER_DOMAIN_ID_FAILURE = '@user/SAVE_USER_DOMAIN_ID_FAILURE';
export const SAVE_USER_DOMAIN_ID_SUCCESS = '@user/SAVE_USER_DOMAIN_ID_SUCCESS';

export const saveUserDomainIdRule = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_USER_DOMAIN_ID_LOADING,
  });
  let body = JSON.stringify(data);
  try {
    let res = await axios.put(`/serviceprovider/userdomain/${data?.UserDomainConfigurationId}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SAVE_USER_DOMAIN_ID_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: SAVE_USER_DOMAIN_ID_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getUserDomainConfigList(data?.OrgId));
    }
  } catch (err) {
    dispatch({
      type: SAVE_USER_DOMAIN_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_USER_DOMAIN_ID_LOADING = '@user/DELETE_USER_DOMAIN_ID_LOADING';
export const DELETE_USER_DOMAIN_ID_SUCCESS = '@user/DELETE_USER_DOMAIN_ID_SUCCESS';
export const DELETE_USER_DOMAIN_ID_FAILURE = '@user/DELETE_USER_DOMAIN_ID_FAILURE';

export const deleteUserDomainIdRule = (id, orgId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_DOMAIN_ID_LOADING });
  try {
    const response = await axios.delete(`/serviceprovider/userdomain/${id}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_USER_DOMAIN_ID_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_USER_DOMAIN_ID_SUCCESS,
      });
      dispatch(getUserDomainConfigList(orgId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_USER_DOMAIN_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
