import { MAX_PAGE_SIZE } from '@mui/x-data-grid';
import { PAGE_SIZE_TEN, PAGE_ZERO } from 'constants/constants';
import { isNil, uniqBy } from 'lodash';
import {
  QA_APPROVED_QUESTIONNAIRE_TEMPLATE,
  SCOPING_QUESTIONNAIRE_TYPE,
  SECURITY_QUESTIONNAIRE_TYPE,
  SUPPLEMENTAL_QUESTIONNAIRE_TYPE,
} from 'pages/QuestionnaireBuilder/constants';

export const getQuestionnaireTemplateListSearchBodyOnType = (questionnaireTemplateTypeId = null, searchText, pageNo = PAGE_ZERO, pageSize = PAGE_SIZE_TEN) => {
  let intent = 'search';
  let page = pageNo + 1;
  const fields = ['QuestionnaireTemplateName', 'QuestionnaireTemplateTypeName', 'VersionNumber', 'ReferenceId', 'ReferenceName', 'Status'];
  const criterion = [
    {
      Field: 'QuestionnaireTemplateTypeId',
      Operator: '=',
      Value: questionnaireTemplateTypeId, // Scoping = 1, Vendor Security Risk Assessment = 4, Supplemental = 5
    },
    {
      Field: 'Status',
      Operator: '=',
      Value: QA_APPROVED_QUESTIONNAIRE_TEMPLATE,
    },
  ];

  if (!isNil(searchText)) {
    criterion.push({
      Field: 'QuestionnaireTemplateName',
      Operator: 'contains',
      Value: searchText?.trim(),
    });
  }

  return {
    intent: intent,
    fields: fields,
    criterion: criterion,
    sort: [],
    page: isNil(searchText) ? page : 1,
    pageSize: isNil(searchText) ? pageSize : 10,
  };
};

export const convertToValueLable = (list = []) => {
  if (!isNil(list)) {
    return list?.map((item) => ({
      label: item?.QuestionnaireTemplateName,
      value: item?.QuestionnaireTemplateId,
    }));
  }

  return [];
};

export const getCurrentPageNo = (questionnaireType, state) => {
  let page;

  if (questionnaireType === SECURITY_QUESTIONNAIRE_TYPE) {
    page = state?.pageNoSecurityType;
  } else if (questionnaireType === SUPPLEMENTAL_QUESTIONNAIRE_TYPE) {
    page = state?.pageNoSupplementalType;
  } else if (questionnaireType === SCOPING_QUESTIONNAIRE_TYPE) {
    page = state?.pageNoScopingType;
  }

  return page;
};

export const getCurrentQuestionnaireTemplateList = (questionnaireType, state, page, response, searchValue = null) => {
  let payload;

  if (questionnaireType === SECURITY_QUESTIONNAIRE_TYPE) {
    let list = state?.securityQuestionnairesList;
    payload = {
      securityQuestionnairesList: uniqBy([...list, ...response], 'value'),
      pageNoSecurityType: isNil(searchValue) ? page + 1 : page,
    };
  } else if (questionnaireType === SUPPLEMENTAL_QUESTIONNAIRE_TYPE) {
    let list = state?.supplementalQuestionnairesList;
    payload = {
      supplementalQuestionnairesList: uniqBy([...list, ...response], 'value'),
      pageNoSecurityType: isNil(searchValue) ? page + 1 : page,
    };
  } else if (questionnaireType === SCOPING_QUESTIONNAIRE_TYPE) {
    let list = state?.scopingQuestionnairesList;
    payload = {
      scopingQuestionnairesList: uniqBy([...list, ...response], 'value'),
      pageNoScopingType: isNil(searchValue) ? page + 1 : page,
    };
  }

  return payload;
};

export const getQuestionnaireTemplatesUtils = (data = null, state) => {
  const sercurityQuestionnaireTemplateList = state?.securityQuestionnairesList;
  const supplementalQuestionnaireTemplateList = state?.supplementalQuestionnairesList;
  const scopingQQuestionnaireTemplateList = state?.scopingQuestionnairesList;
  const securityList = convertToValueLable(data?.SecurityQuestionnaireTemplateList);
  const supplementalList = convertToValueLable(data?.ScopingQuestionnaireTemplateList);
  const scopingList = convertToValueLable(data?.SupplementalQuestionnaireTemplateList);

  const payload = {
    securityQuestionnairesList: uniqBy([...sercurityQuestionnaireTemplateList, ...securityList], 'value'),
    supplementalQuestionnairesList: uniqBy([...supplementalQuestionnaireTemplateList, ...supplementalList], 'value'),
    scopingQuestionnairesList: uniqBy([...scopingQQuestionnaireTemplateList, ...scopingList], 'value'),
  };
  return payload;
}
